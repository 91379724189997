export const defsIds = {
  aprLineGradient: 'apr-line-gradient',
  apyAprAreaShadow: 'apy-apr-area-shadow',
  rewardsLineShadow: 'rewards-line-shadow',
  compoundDropShadow: 'compound-drop-shadow',
  withdrawalDropShadow: 'withdrawal-drop-shadow',
  tooltipCursorGradient: 'tooltip-cursor-gradient',
  redelegationDropShadow: 'redelegation-drop-shadow',
  crossChainRewardsShadow: 'cross-chain-rewards-shadow',
};
