import styled, { css } from 'styled-components';

import { Flex, Popup } from 'libs/commons/components';
import { gradientBorder } from 'libs/commons/styles/mixins';

import { SolanaStakeStatus } from 'store/api/solana/delegations/types';

export const Status = styled(Flex)<{ status: SolanaStakeStatus }>`
  display: flex;
  width: fit-content;
  min-width: 128px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 40px;

  ${({ theme, status }) => css`
    background: ${theme.config.solanaStakeStatus[status].background};
    border: 2px solid ${theme.config.solanaStakeStatus[status].borderColor};
  `}
`;

export const ActivationTimePopup = styled(Popup)`
  &-content {
    padding: 8px;
    border-radius: 12px !important;

    ${gradientBorder};

    &::before {
      padding: 2px;
    }

    ${({ theme }) => css`
      background: ${theme.config.tableRow.background.SOLANA.hover} !important;

      &::before {
        background: ${theme.config.chainGradient.SOLANA};
      }
    `}
  }
`;
