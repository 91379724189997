import { Flex } from '../styles';
import { EllipsisText } from '../ellipsis-text';

import * as Styles from './styles';
import { TabsProps } from './types';

export const Tabs = <TabValue extends string = string>({
  tabs,
  layoutId,
  chainType,
  selectedTab,
  setSelectedTab,
  ...props
}: TabsProps<TabValue>) => (
  <Styles.TabsList {...props}>
    {tabs.map(({ value, label, leftSlot, ...rest }) => {
      const isSelected = value === selectedTab;
      return (
        <Styles.Tab
          key={value}
          onClick={() => setSelectedTab?.(value)}
          {...rest}
        >
          <Flex gap={8}>
            {leftSlot}{' '}
            {typeof label === 'string' ? (
              <EllipsisText>{label}</EllipsisText>
            ) : (
              label
            )}
          </Flex>

          {isSelected && (
            <Styles.Underline layoutId={layoutId} chainType={chainType} />
          )}
        </Styles.Tab>
      );
    })}
  </Styles.TabsList>
);

export type { TabsProps };
