import styled from 'styled-components';

import { inputCss, InputContainer } from 'libs/commons/components/input/styles';

export const Textarea = styled.textarea<{ hasLeftIcon: boolean }>`
  ${inputCss};
  height: 112px;
  padding: 16px;
  resize: none;
`;

export const Container = styled(InputContainer)`
  height: auto;
`;
