import { SvgGradientDefinitions as BaseSvgGradientDefinitions } from 'libs/commons/components';
import { svgGradientDefinitionId } from 'libs/commons/components/svg-gradient-definitions/config';

export const SvgGradientDefinitions = () => (
  <BaseSvgGradientDefinitions>
    <linearGradient
      x1="3.39456"
      y1="3.95625"
      x2="17.2629"
      y2="20.8394"
      gradientUnits="userSpaceOnUse"
      id={svgGradientDefinitionId.OSMOSIS}
    >
      <stop stopColor="#4D4DAE" />
      <stop offset="1" stopColor="#BE499B" />
    </linearGradient>

    <linearGradient
      x1="3.39456"
      y1="3.95625"
      x2="17.2629"
      y2="20.8394"
      gradientUnits="userSpaceOnUse"
      id={svgGradientDefinitionId.LAVA}
    >
      <stop stopColor="#D7001F" />
      <stop offset="1" stopColor="#FFD600" />
    </linearGradient>

    <linearGradient
      x1="3.39456"
      y1="3.95625"
      x2="17.2629"
      y2="20.8394"
      gradientUnits="userSpaceOnUse"
      id={svgGradientDefinitionId.SOLANA}
    >
      <stop stopColor="#00FFA3" />
      <stop offset="1" stopColor="#DC1FFF" />
    </linearGradient>
  </BaseSvgGradientDefinitions>
);
