import { formatDate } from 'libs/commons/utils/date';
import { Flex } from 'libs/commons/components/styles';
import { addPercentageLabel } from 'libs/commons/utils/helpers';
import { convertAndLabelCurrency } from 'libs/commons/utils/currencies';

import { Icon } from 'components/icons';

import { IncomeChartDataItem } from '../types';

import * as Styles from './styles';
import { TooltipRow } from './row';
import { CustomTooltipProps } from './types';
import { Redelegations } from './redelegations';
import { RewardsBreakdown } from './rewards-breakdown';

export const CustomTooltip = <TData extends Record<string, any>>({
  active,
  payload,
  chainType,
  tooltipRewardValueFormatter,
  renderAdditionalTooltipContent,
}: CustomTooltipProps<TData>) => {
  if (payload?.length && active && payload[0].payload.reward) {
    const currentPayload = payload[0].payload;
    const {
      apy,
      apr,
      time,
      reward,
      compound,
      withdrawal,
      redelegations,
      rewardsBreakdown,
      ...rest
    } = currentPayload as IncomeChartDataItem;

    return (
      <Styles.Tooltip chainType={chainType}>
        <h4>{formatDate(time, 'DD/MM/YYYY')}</h4>

        <Flex column gap={8}>
          <Styles.TooltipRowDivider />

          <TooltipRow
            value={
              tooltipRewardValueFormatter
                ? tooltipRewardValueFormatter(reward)
                : reward
            }
            title={
              <>
                <Styles.RewardRowTitleIcon />
                <div>Overall Rewards</div>
              </>
            }
          />

          {compound && +compound.value > 0 ? (
            <>
              <Styles.TooltipRowDivider />

              <TooltipRow
                value={convertAndLabelCurrency(compound)}
                title={
                  <>
                    <Icon.BalanceHistoryChartCompound />
                    <div>Compounding Rewards</div>
                  </>
                }
              />
            </>
          ) : null}

          {withdrawal && +withdrawal > 0 ? (
            <>
              <Styles.TooltipRowDivider />

              <TooltipRow
                title={
                  <>
                    <Styles.WithdrawalTitleIcon />
                    <div>Withdrawal</div>
                  </>
                }
                value={
                  tooltipRewardValueFormatter
                    ? tooltipRewardValueFormatter(withdrawal)
                    : withdrawal
                }
              />
            </>
          ) : null}

          {redelegations && redelegations.length > 0 && (
            <Redelegations data={redelegations} />
          )}

          {rewardsBreakdown && rewardsBreakdown.length > 0 && (
            <RewardsBreakdown data={rewardsBreakdown} />
          )}

          {apy && (
            <>
              <Styles.TooltipRowDivider />

              <TooltipRow
                value={addPercentageLabel(apy)}
                title={
                  <>
                    <svg width="10" height="2" fill="none" viewBox="0 0 10 2">
                      <path
                        d="M1 1H9"
                        strokeWidth="2"
                        stroke="#F5F569"
                        strokeLinecap="round"
                        strokeDasharray="1 2"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div>Actual APY</div>
                  </>
                }
              />
            </>
          )}

          {apr && (
            <>
              <Styles.TooltipRowDivider />

              <TooltipRow
                value={addPercentageLabel(apr)}
                title={
                  <>
                    <svg width="10" height="2" fill="none" viewBox="0 0 10 2">
                      <path
                        d="M1 1H9"
                        strokeWidth="2"
                        stroke="#008F37"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div>Actual APR</div>
                  </>
                }
              />
            </>
          )}

          {renderAdditionalTooltipContent?.(rest as unknown as TData)}
        </Flex>
      </Styles.Tooltip>
    );
  }

  return null;
};
