import styled from 'styled-components';

import { caption } from 'libs/commons/styles';
import { Flex, EllipsisText } from 'libs/commons/components';

export const Currency = styled(Flex)`
  gap: 8px;
  font-weight: 600;
`;

export const Price = styled(EllipsisText)`
  > p {
    ${caption};
  }
`;
