import { useTheme } from 'styled-components';

import { defsIds } from './config';

export const RewardsChartDefs = () => {
  const theme = useTheme();

  return (
    <>
      <linearGradient
        y1="0%"
        x2="52%"
        x1="52%"
        y2="100%"
        gradientUnits="userSpaceOnUse"
        id={defsIds.tooltipCursorGradient}
      >
        <stop stopOpacity="0" stopColor={theme.colors.defaultWhite} />
        <stop offset="0.291891" stopColor={theme.colors.defaultWhite} />
        <stop offset="0.721997" stopColor={theme.colors.defaultWhite} />
        <stop
          offset="1"
          stopOpacity="0"
          stopColor={theme.colors.defaultWhite}
        />
      </linearGradient>

      <linearGradient
        x1="0"
        y1="0"
        x2="0"
        y2="1"
        id={defsIds.rewardsLineShadow}
      >
        <stop offset="5%" stopOpacity={0.1} stopColor={theme.colors.yellow} />
        <stop offset="90%" stopOpacity={0} stopColor={theme.colors.yellow} />
      </linearGradient>

      <linearGradient
        x1="0"
        y1="0"
        x2="0"
        y2="100%"
        gradientUnits="userSpaceOnUse"
        id={defsIds.compoundDropShadow}
      >
        <stop stopColor={theme.config.chart.bar.compound} />
        <stop
          offset="1"
          stopOpacity="0"
          stopColor={theme.config.chart.bar.compound}
        />
      </linearGradient>

      <linearGradient
        x1="0"
        y1="0"
        x2="0"
        y2="100%"
        gradientUnits="userSpaceOnUse"
        id={defsIds.redelegationDropShadow}
      >
        <stop stopColor={theme.colors.defaultWhite} />
        <stop
          offset="1"
          stopOpacity="0"
          stopColor={theme.colors.defaultWhite}
        />
      </linearGradient>

      <linearGradient
        x1="0"
        y1="0"
        x2="0"
        y2="100%"
        gradientUnits="userSpaceOnUse"
        id={defsIds.withdrawalDropShadow}
      >
        <stop stopColor={theme.colors.accentGradient.yellow} />
        <stop
          offset="1"
          stopOpacity="0"
          stopColor={theme.colors.accentGradient.yellow}
        />
      </linearGradient>
    </>
  );
};

export const CrossChainRewardsChartDefs = () => {
  const theme = useTheme();

  return (
    <>
      <linearGradient
        x1="0"
        y1="0"
        x2="0"
        y2="1"
        id={defsIds.crossChainRewardsShadow}
      >
        <stop
          offset="5%"
          stopOpacity={0.3}
          stopColor={theme.colors.defaultWhite}
        />
        <stop
          offset="95%"
          stopOpacity={0}
          stopColor={theme.colors.defaultWhite}
        />
      </linearGradient>
    </>
  );
};

export const APRAPYChartDefs = () => {
  const theme = useTheme();

  return (
    <>
      <linearGradient
        x1="70.3989%"
        y1="18.5252%"
        x2="6.91226%"
        y2="79.2667%"
        id={defsIds.aprLineGradient}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={theme.colors.accentGradient.yellow} />
        <stop offset="0.525144" stopColor={theme.colors.accentGradient.green} />
        <stop offset="1" stopColor={theme.colors.accentGradient.blue} />
      </linearGradient>

      <linearGradient x1="0" y1="0" x2="0" y2="1" id={defsIds.apyAprAreaShadow}>
        <stop offset="5%" stopOpacity={0.2} stopColor={theme.colors.yellow} />
      </linearGradient>
    </>
  );
};
