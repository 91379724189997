import { useCallback } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import {
  Signer,
  Transaction,
  SignatureStatus,
  RpcResponseAndContext,
  TransactionInstruction,
} from '@solana/web3.js';

import { TransactionStatus } from 'types';
import { retryPromiseWithDelay } from 'utils/query';
import { SolanaTransactionError } from 'utils/errors';

export interface UseSolanaTxParams {
  onStatusChange?: (status: TransactionStatus) => void;
}

export const solanaValidatorsDelegationBatchSize = 2;

export const useSolanaTx = (params: UseSolanaTxParams | void) => {
  const { connection } = useConnection();
  const { sendTransaction } = useWallet();

  const { onStatusChange } = params || {};

  return useCallback(
    async ({
      signers,
      messages,
    }: {
      signers?: Signer[];
      messages: (Transaction | TransactionInstruction)[];
    }) => {
      onStatusChange?.('connecting-to-chain');

      const transaction = new Transaction();

      transaction.add(...messages);

      const signature = await sendTransaction(transaction, connection, {
        signers,
      });

      const retriesLimit = 10;
      const delayTime = 2000;

      onStatusChange?.('signing');

      const result = await retryPromiseWithDelay<
        RpcResponseAndContext<SignatureStatus | null>
      >(
        async (retries) => {
          const status = await connection.getSignatureStatus(signature);

          if (!status.value) {
            throw new SolanaTransactionError(
              'Transaction status not available yet',
              status,
              retries
            );
          }

          const { err, confirmationStatus } = status.value;

          if (err) {
            throw new Error(
              `Transaction failed with error: ${JSON.stringify(err)}`
            );
          }

          if (confirmationStatus) {
            return status;
          }

          throw new Error('Transaction not confirmed yet');
        },
        retriesLimit,
        delayTime
      );

      onStatusChange?.('success');

      return result;
    },
    [connection, onStatusChange, sendTransaction]
  );
};
