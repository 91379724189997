import { cosmosApiQuery } from 'libs/cosmos-core/store/query';

import { URL } from '../../api/constants';

const tagTypes = ['user-grants'];

interface FeeUserGrant {
  granteeWalletAddress: string;
  expirationTimestampInMilli: number;
}

export interface StakingGrantee {
  allowValidators: string[];
  authorizationType: string;
  granteeWalletAddress: string;
  expirationTimestampInMilli: number;
}

interface TransferGrantee {
  granteeWalletAddress: string;
  expirationTimestampInMilli: number;
}

export interface GrantData {
  walletAddress: string;
  feeUserGrants: FeeUserGrant[];
  stakingGrantees: StakingGrantee[];
  transferGrantees: TransferGrantee[];
}

export const userOptimizationGrantsApiConfig = {
  tagTypes: tagTypes,
  baseQuery: cosmosApiQuery,
  reducerPath: 'userOptimizationGrantsApi',
  endpoints: {
    getUserOptimizationGrants: {
      providesTags: tagTypes,
      query: (userUUID: string) => ({
        method: 'get',
        url: URL.USER_GRANTS.replace('{userUUID}', userUUID),
      }),
    },
  },
};
