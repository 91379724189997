import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'libs/cosmos-core/store/query';

import { URL } from 'api/constants';

import { ChainCurrencyData } from './types';

export const chainsDataApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'chainsDataApi',
  endpoints: (build) => ({
    getCurrencyData: build.query<ChainCurrencyData, string>({
      query: (currency) => ({
        method: 'get',
        url: URL.CHAIN_CURRENCY_DATA.replace('{currency}', currency),
      }),
    }),
  }),
});

export const { useGetCurrencyDataQuery } = chainsDataApi;

export type { ChainCurrencyData };
