import { ReactNode, PropsWithChildren } from 'react';

import { Icon } from '../icons';
import { ChainType } from '../../types';
import { GradientText } from '../styles';
import { testIdAttribute } from '../../config/constants';

import * as Styles from './styles';

export interface LoaderProps {
  title?: ReactNode;
  isLoading?: boolean;
  chainType?: ChainType;
  isFullScreen?: boolean;
  loaderSlot?: ReactNode;
  absolutePosition?: boolean;
}

export const Loader = ({
  title,
  children,
  chainType,
  isLoading = false,
  isFullScreen = true,
  absolutePosition = false,
  loaderSlot = <Icon.Loader width={48} height={48} />,
}: PropsWithChildren<LoaderProps>) => {
  const testProps = {
    [testIdAttribute]: 'loader',
  };

  return (
    <>
      {children}
      {isLoading && (
        <Styles.Container
          {...testProps}
          $isFullScreen={isFullScreen}
          $absolutePosition={absolutePosition}
        >
          {title && (
            <GradientText chainType={chainType}>
              <h2>{title}</h2>
            </GradientText>
          )}

          {loaderSlot}
        </Styles.Container>
      )}
    </>
  );
};
