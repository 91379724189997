import styled from 'styled-components';

import { Flex, Semibold } from '../styles';

export const Container = styled(Flex)`
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
`;

export const Background = styled.div`
  width: 389px;
  height: 49px;
  position: absolute;
  filter: blur(38px);
  border-radius: 389px;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.2);
`;

export const BackgroundImage = styled.img`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const Title = styled(Semibold)`
  z-index: 1;
  color: ${({ theme }) => theme.config.emptyPlaceholder.title};
`;
