import { useToggle } from 'usehooks-ts';
import { AnimatePresence } from 'framer-motion';

import { Button } from '../../button';
import { TableRowProps } from '../types';
import { EllipsisText } from '../../ellipsis-text';
import { Flex, TranslucentCaption } from '../../styles';

import * as Styles from './styles';

export const Mobile = ({
  values,
  headers,
  onClick,
  closable = true,
  ...props
}: TableRowProps['mobileProps'] & TableRowProps) => {
  const [opened, toggle] = useToggle(!closable);

  const [firstValue, secondValue, ...restValues] = values;
  const [firstHeader, secondHeader, ...restHeaders] = headers || [];

  return (
    <Styles.TableRowContainer onClick={onClick} {...props}>
      <Flex gap={8} equalizeChildren alignItems="center">
        <Flex column minWidth={0}>
          {typeof firstHeader === 'string' && firstHeader ? (
            <TranslucentCaption>{firstHeader}</TranslucentCaption>
          ) : (
            firstHeader
          )}
          {typeof firstValue === 'string' ? (
            <EllipsisText>{firstValue}</EllipsisText>
          ) : (
            firstValue
          )}
        </Flex>

        <Flex minWidth={0}>
          <Flex column flex={1} minWidth={0}>
            {typeof secondHeader === 'string' && secondHeader ? (
              <TranslucentCaption>{secondHeader}</TranslucentCaption>
            ) : (
              secondHeader
            )}
            {typeof secondValue === 'string' ? (
              <EllipsisText>{secondValue}</EllipsisText>
            ) : (
              secondValue
            )}
          </Flex>

          {closable && (
            <Button
              styleType="text"
              icon={<Styles.ToggleCardIcon $opened={opened} />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggle();
              }}
            />
          )}
        </Flex>
      </Flex>

      <AnimatePresence initial={false}>
        {opened && (
          <Styles.ValuesContainer
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
          >
            {restValues.map((value, i) => (
              <Flex column key={i} flex={1}>
                {typeof restHeaders[i] === 'string' ? (
                  <TranslucentCaption>{restHeaders[i]}</TranslucentCaption>
                ) : (
                  restHeaders[i]
                )}

                {value}
              </Flex>
            ))}
          </Styles.ValuesContainer>
        )}
      </AnimatePresence>
    </Styles.TableRowContainer>
  );
};
