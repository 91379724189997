import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { URL } from '../api/constants';

interface SignatureMessageResponse {
  messageToSign: string;
  walletAddress: string;
}

interface SignatureVerifyResponse {
  accessToken: string;
  refreshToken: string;
  walletAddress: string;
}

export const generateWalletSignInTokens = async (params: {
  walletAddress: string;
  publicKey: string | null;
  onMessageSign: (messageToSign: string) => Promise<string | undefined>;
}) => {
  const { publicKey, walletAddress, onMessageSign } = params;

  const generatedMessageResponse = await axios.post<SignatureMessageResponse>(
    URL.GENERATE_SIGNATURE_MESSAGE,
    {
      walletAddress,
    }
  );

  const { messageToSign } = generatedMessageResponse.data;

  if (walletAddress) {
    const signature = await onMessageSign(messageToSign);

    if (signature) {
      const verifyResult = await axios.post<SignatureVerifyResponse>(
        URL.VERIFY_CHALLENGE,
        {
          publicKey,
          signature,
          walletAddress,
        }
      );

      const { accessToken, refreshToken } = verifyResult.data;
      saveWalletSignInTokens({ accessToken, refreshToken });

      const decoded = jwtDecode(accessToken);

      return decoded.sub;
    }
  }
};

export const walletSignInAccessTokenKey = 'walletSignInAccessToken';
const walletSignInRefreshTokenKey = 'walletSignInRefreshToken';

export const saveWalletSignInTokens = (tokens: {
  accessToken: string;
  refreshToken?: string;
}) => {
  localStorage.setItem(walletSignInAccessTokenKey, tokens.accessToken);

  if (tokens.refreshToken) {
    localStorage.setItem(walletSignInRefreshTokenKey, tokens.refreshToken);
  }
};

export const clearWalletSignInTokens = () => {
  localStorage.removeItem(walletSignInAccessTokenKey);
  localStorage.removeItem(walletSignInRefreshTokenKey);
};

export const getWalletSignInAccessToken = () =>
  localStorage.getItem(walletSignInAccessTokenKey);

export const getWalletSignInRefreshToken = () =>
  localStorage.getItem(walletSignInRefreshTokenKey);

export const buildRefreshWalletSignTokenHandler =
  ({ onError }: { onError: () => void }) =>
  async () => {
    const walletRefreshToken = getWalletSignInRefreshToken();

    if (walletRefreshToken) {
      try {
        const response = await axios.post<{ newAccessToken: string }>(
          URL.REFRESH_TOKEN,
          {
            refreshToken: walletRefreshToken,
          }
        );

        const { newAccessToken } = response.data;

        saveWalletSignInTokens({
          accessToken: newAccessToken,
        });

        return newAccessToken;
      } catch (error) {
        console.error(error);
        onError();
        return null;
      }
    }

    return null;
  };

export const buildWalletSignUnauthorizedErrorHandler =
  ({ refreshToken }: { refreshToken: () => Promise<string | null> }) =>
  async (error: any) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      const newAccessToken = await refreshToken();
      if (newAccessToken) {
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      }
    }

    return axios(error.config);
  };
