import styled, { css } from 'styled-components';

import { ButtonStyleType } from 'libs/commons/components/button';
import { Button, Popup as PopupComponent } from 'libs/commons/components';

import { InfoIcon } from 'components/styles';

export const Popup = styled(PopupComponent)`
  &-content {
    ${({ theme }) => css`
      background: ${theme.colors.black};
      border: ${theme.config.input.border};
    `}
  }
`;

export const NotRecommendedStatusButton = styled(Button)`
  padding: 4px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  box-shadow: none;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const NotRecommendedLabel = styled.span<{ styleType: ButtonStyleType }>`
  color: ${({ theme, styleType }) =>
    styleType === 'blue'
      ? theme.colors.blue.primary.main
      : theme.colors.red.additional.main};
`;

export const Icon = styled(InfoIcon)<{ styleType: ButtonStyleType }>`
  ${({ styleType }) =>
    styleType === 'secondaryRed' &&
    css`
      path {
        fill: ${({ theme }) => theme.colors.red.additional.main};
      }
    `}
`;
