import { useMemo } from 'react';

import { Flex } from 'libs/commons/components';
import { formatUSD } from 'libs/commons/utils/currencies';
import { AmountResponse, AmountResponseWithUsd } from 'libs/commons/types';
import { ChainTypeIcon, CurrencyToChainType } from 'libs/commons/types/data';

import { useConvertedCurrencyInfo } from 'hooks';
import { isMainCurrency } from 'utils/currencies';
import { Icon, OtherIconsCounter } from 'components';

import { TooltipRow } from '../row';
import { TooltipRowDivider } from '../styles';

import * as Styles from './styles';

const RewardBreakdownRow = ({ data }: { data: AmountResponseWithUsd }) => {
  const { usdValue, currencyInfo } = useConvertedCurrencyInfo(data.amount);

  return (
    <TooltipRow
      value={usdValue}
      title={
        <Styles.RewardsBreakdownIconWrapper>
          {currencyInfo}
        </Styles.RewardsBreakdownIconWrapper>
      }
    />
  );
};

const OtherRewardIcon = ({ data }: { data: AmountResponse }) => {
  return (
    <Styles.OtherRewardIconWrapper>
      {isMainCurrency(data.currency) ? (
        ChainTypeIcon[CurrencyToChainType[data.currency]]
      ) : (
        <Icon.Validator />
      )}
    </Styles.OtherRewardIconWrapper>
  );
};

const OtherRewardRows = ({ data }: { data: AmountResponseWithUsd[] }) => {
  const total = data.reduce((acc, item) => acc + +item.amountInUsd.value, 0);

  return (
    <TooltipRow
      value={formatUSD(total)}
      title={
        <OtherIconsCounter
          data={data}
          renderIcon={(item) => <OtherRewardIcon data={item.amount} />}
        />
      }
    />
  );
};

const rowsLimit = 3;

export const RewardsBreakdown = ({
  data,
}: {
  data: AmountResponseWithUsd[];
}) => {
  const sortedByAmount = useMemo(
    () =>
      data
        .filter((item) => item.amountInUsd)
        .sort((a, b) => +b.amountInUsd.value - +a.amountInUsd.value),
    [data]
  );

  return (
    <>
      <TooltipRowDivider />

      <Flex column gap={4}>
        <TooltipRow
          title={
            <>
              <Styles.RewardsBreakdownTitleIcon />
              <div>Rewards breakdown</div>
            </>
          }
        />

        <Styles.RewardsBreakdownItems>
          {sortedByAmount.slice(0, rowsLimit).map((item, index) => (
            <RewardBreakdownRow key={index} data={item} />
          ))}

          {sortedByAmount.length > rowsLimit && (
            <OtherRewardRows data={sortedByAmount.slice(rowsLimit)} />
          )}
        </Styles.RewardsBreakdownItems>
      </Flex>
    </>
  );
};
