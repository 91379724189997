import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  Bar,
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  YAxisProps,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';

import { isMobile } from 'libs/commons/hooks';
import { formatUSD } from 'libs/commons/utils/currencies';
import { addPercentageLabel } from 'libs/commons/utils/helpers';
import { Icon, Caption, RetryRequest } from 'libs/commons/components';

import * as Styles from './styles';
import { defsIds } from './defs/config';
import { IncomeChartProps } from './types';
import { CustomTooltip } from './custom-tooltip';
import { getDefUrl, formatData } from './helpers';
import { TooltipRow } from './custom-tooltip/row';
import { TooltipRowDivider } from './custom-tooltip/styles';
import { EventsIconsScatterShape } from './events-icons-scatter-shape';
import {
  APRAPYChartDefs,
  RewardsChartDefs,
  CrossChainRewardsChartDefs,
} from './defs';

const getXAxisTickFormatter =
  (selectedRange: IncomeChartProps['selectedRange']) => (value: number) =>
    dayjs(value).format(
      selectedRange === 'LAST_THREE_MONTH' || selectedRange === 'LAST_YEAR'
        ? 'DD/MM/YY'
        : 'DD/MM'
    );

const commonYAxisProps: YAxisProps = {
  tickSize: 0,
  tickMargin: 15,
  axisLine: false,
};

const tooltipCursorProps = {
  strokeWidth: 4,
  strokeOpacity: 0.55,
  stroke: getDefUrl(defsIds.tooltipCursorGradient),
};

const chartsSyncId = 'income-chart';

export const IncomeChart = <TData extends Record<string, any>>({
  data,
  isError,
  refetch,
  onHover,
  children,
  chainType,
  isFetching,
  headingSlot,
  selectedRange,
  rewardYAxisProps,
  tooltipRewardValueFormatter,
  renderAdditionalTooltipContent,
}: IncomeChartProps<TData>) => {
  const theme = useTheme();
  const mobile = isMobile();

  const hasStakingInfo = Boolean(
    data && data.length && data.some((item) => +item.reward)
  );

  const dataHasCrossChainRewards = Boolean(
    data && data.length && data.some((item) => item.crossChainRewardsInUsd)
  );

  const tick = {
    fill: theme.config.chart.tickLabel,
    ...(theme.isDarkTheme && { opacity: '0.5' }),
  };

  const yAxisProps = {
    ...commonYAxisProps,
    tick,
  };

  const placeholderMessage = useMemo(() => {
    if (isError)
      return <RetryRequest onRetry={refetch} error="Error fetching data" />;
    if (isFetching) return <Icon.Loader />;
    if (!data || !data.length) return 'You have no data yet';
    if (!hasStakingInfo) return 'No enough staking information available';
    if (data.length < 2) return 'Waiting for data to be gathered';
  }, [data, hasStakingInfo, isFetching, isError, refetch]);

  const resultData = useMemo(() => {
    return formatData(data, hasStakingInfo, theme, selectedRange);
  }, [data, hasStakingInfo, selectedRange, theme]);

  const xAxisTickFormatter = getXAxisTickFormatter(selectedRange);

  return (
    <Styles.ChartContainer chainType={chainType}>
      <Styles.ChartInner>
        {headingSlot}
        <Styles.RewardsChartContainer
          onMouseEnter={onHover}
          dataHasCrossChainRewards={dataHasCrossChainRewards}
        >
          <ResponsiveContainer height="100%">
            <ComposedChart
              barSize={8}
              data={resultData}
              {...{
                overflow: 'visible',
              }}
              syncId={chartsSyncId}
            >
              <defs>
                <RewardsChartDefs />
              </defs>
              <CartesianGrid
                vertical={false}
                strokeOpacity={0.1}
                stroke={theme.config.chart.grid}
              />
              {!mobile && (
                <YAxis interval={0} {...yAxisProps} {...rewardYAxisProps} />
              )}
              {selectedRange === 'LAST_THIRTY_DAYS'
                ? [
                    <Bar key="bar" dataKey="rewardBarValue" />,

                    <Scatter
                      key="scatter"
                      isAnimationActive={false}
                      shape={EventsIconsScatterShape}
                      dataKey={(data) =>
                        data.rewardBarValue
                          ? data.rewardBarValue[0] > data.rewardBarValue[1]
                            ? data.rewardBarValue[0]
                            : data.rewardBarValue[1]
                          : 0
                      }
                    />,
                  ]
                : [
                    <Line
                      key="line"
                      dot={false}
                      type="linear"
                      dataKey="reward"
                      isAnimationActive={false}
                      stroke={theme.colors.yellow}
                    />,

                    <Area
                      key="area"
                      type="linear"
                      strokeWidth={0}
                      fillOpacity={1}
                      dataKey="rewardAreaData"
                      isAnimationActive={false}
                      fill={getDefUrl(defsIds.rewardsLineShadow)}
                    />,

                    <Scatter
                      key="scatter"
                      dataKey="reward"
                      isAnimationActive={false}
                      shape={EventsIconsScatterShape}
                    />,
                  ]}

              <XAxis hide scale="point" />

              {hasStakingInfo && (
                <Tooltip
                  cursor={tooltipCursorProps}
                  content={
                    <CustomTooltip
                      chainType={chainType}
                      tooltipRewardValueFormatter={tooltipRewardValueFormatter}
                      renderAdditionalTooltipContent={
                        renderAdditionalTooltipContent
                      }
                    />
                  }
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Styles.RewardsChartContainer>

        {dataHasCrossChainRewards && (
          <Styles.CrossChainRewardsChartContainer>
            <Caption>CROSS CHAIN REWARDS</Caption>
            <Styles.CrossChainRewardsChartInner>
              <ResponsiveContainer height="100%">
                <ComposedChart data={resultData} syncId={chartsSyncId}>
                  <defs>
                    <CrossChainRewardsChartDefs />
                  </defs>

                  <CartesianGrid
                    vertical={false}
                    strokeOpacity={0.1}
                    stroke={theme.config.chart.grid}
                  />

                  <YAxis
                    {...yAxisProps}
                    type="number"
                    tickCount={4}
                    dataKey="crossChainRewardsInUsd"
                    tickFormatter={(value) => formatUSD(value)}
                  />

                  <Line
                    dot={false}
                    type="linear"
                    isAnimationActive={false}
                    dataKey="crossChainRewardsInUsd"
                    stroke={theme.colors.defaultWhite}
                  />

                  <Area
                    type="linear"
                    strokeWidth={0}
                    fillOpacity={1}
                    isAnimationActive={false}
                    dataKey="crossChainRewardsInUsd"
                    fill={getDefUrl(defsIds.crossChainRewardsShadow)}
                  />

                  {hasStakingInfo && (
                    <Tooltip
                      content={() => <div></div>}
                      cursor={{
                        strokeWidth: 4,
                        strokeOpacity: 0.55,
                        stroke: getDefUrl(defsIds.tooltipCursorGradient),
                      }}
                    />
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </Styles.CrossChainRewardsChartInner>
          </Styles.CrossChainRewardsChartContainer>
        )}

        <Styles.APRChartContainer>
          <Caption>APY / APR</Caption>
          <Styles.APRChartInner>
            <ResponsiveContainer>
              <ComposedChart data={resultData} syncId={chartsSyncId}>
                <defs>
                  <APRAPYChartDefs />
                </defs>

                <CartesianGrid
                  vertical={false}
                  strokeOpacity={0.1}
                  stroke={theme.config.chart.grid}
                />

                <YAxis
                  {...yAxisProps}
                  interval={0}
                  dataKey={(item) => [item.scaledAPR, item.scaledAPY]}
                  tickFormatter={(value) =>
                    addPercentageLabel(Math.round(value), undefined, false)
                  }
                  domain={[
                    (dataMin: number) => Math.max(Math.floor(dataMin - 3), 0),
                    (dataMax: number) => {
                      const buffer = dataMax <= 3 ? 1 : 3;
                      return Math.ceil(dataMax + buffer);
                    },
                  ]}
                />

                <Line
                  dot={false}
                  dataKey="scaledAPY"
                  strokeDasharray="5"
                  isAnimationActive={false}
                  stroke={theme.colors.yellow}
                />

                <Area
                  strokeWidth={0}
                  isAnimationActive={false}
                  fill={getDefUrl(defsIds.apyAprAreaShadow)}
                  dataKey={(item) => [item.scaledAPR, item.scaledAPY]}
                />

                <Line
                  dot={false}
                  connectNulls
                  strokeWidth={2}
                  dataKey="scaledAPR"
                  isAnimationActive={false}
                  stroke={getDefUrl(defsIds.aprLineGradient)}
                />

                {hasStakingInfo && (
                  <Tooltip
                    content={() => <div></div>}
                    cursor={tooltipCursorProps}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </Styles.APRChartInner>
        </Styles.APRChartContainer>
      </Styles.ChartInner>

      <ResponsiveContainer height="5%">
        <Styles.DateLineChart data={resultData} syncId="incomeChart">
          {!mobile && <YAxis />}

          <XAxis
            height={10}
            tick={tick}
            tickSize={0}
            dataKey="time"
            axisLine={false}
            tickFormatter={xAxisTickFormatter}
          />
        </Styles.DateLineChart>
      </ResponsiveContainer>

      {placeholderMessage && (
        <Styles.EmptyPlaceholder>{placeholderMessage}</Styles.EmptyPlaceholder>
      )}

      {children}
    </Styles.ChartContainer>
  );
};

IncomeChart.TooltipRow = TooltipRow;
IncomeChart.TooltipRowDivider = TooltipRowDivider;
