import { PropsWithChildren } from 'react';

import { svgGradientDefinitionId } from './config';

export const SvgGradientDefinitions = ({ children }: PropsWithChildren) => (
  <svg style={{ visibility: 'hidden', position: 'absolute' }}>
    <defs>
      <linearGradient
        y1="10.212"
        x1="19.2296"
        x2="9.13464"
        y2="20.0469"
        gradientUnits="userSpaceOnUse"
        id={svgGradientDefinitionId.DEFAULT}
      >
        <stop stopColor="#ECCD2B" />
        <stop offset="0.525144" stopColor="#039750" />
        <stop offset="1" stopColor="#187CC6" />
      </linearGradient>

      {children}
    </defs>
  </svg>
);
