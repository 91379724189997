import styled, { css } from 'styled-components';

import { getThemeChainBasedColor } from '../../utils/helpers';

import { TableRowProps } from './types';

export const BaseStyledTableRow = styled.div<
  Pick<TableRowProps, 'disabled' | 'chainType'>
>`
  border-radius: 28px;

  ${({ theme, onClick, disabled, chainType }) => {
    const bgColors = getThemeChainBasedColor({
      chainType,
      colors: theme.config.tableRow.background,
    });

    return css`
      border: ${theme.config.input.border};

      ${disabled &&
      css`
        opacity: 0.3;
        pointer-events: none;
      `}

      ${!disabled &&
      onClick &&
      css`
        cursor: pointer;

        &:hover {
          background: ${bgColors.hover};
        }

        &:active {
          background: ${bgColors.active};
        }
      `}

      background: ${bgColors.main};
      box-shadow: ${theme.config.tableRow.boxShadow};
      backdrop-filter: ${theme.config.tableRow.backdropFilter};
      -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
    `;
  }}
`;
