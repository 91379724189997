import { createApi } from '@reduxjs/toolkit/query/react';

import { notificationsApiQuery } from 'libs/commons/store/query';
import { getSortParams, addParamsToUrl } from 'libs/commons/utils/query';
import { SortRequest, NotificationsListResponse } from 'libs/commons/types';

import { URL } from 'api/constants';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: notificationsApiQuery,
  endpoints: (build) => ({
    list: build.query<
      NotificationsListResponse,
      { userUUID: string } & Partial<SortRequest>
    >({
      query: ({ sort, userUUID }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.NOTIFICATIONS.replace('{userUUID}', userUUID),
          getSortParams(sort)
        ),
      }),
    }),
  }),
});

export const { useListQuery } = notificationsApi;
