import { createApi } from '@reduxjs/toolkit/query/react';

import { lavaRestakeSpecsApiConfig } from 'libs/cosmos-core/store/api';
import {
  LavaProviderData,
  CommonValidatorsRequestParams,
} from 'libs/cosmos-core/types';

export const lavaRestakeSpecsApi = createApi({
  baseQuery: lavaRestakeSpecsApiConfig.baseQuery,
  reducerPath: lavaRestakeSpecsApiConfig.reducerPath,
  endpoints: (build) => ({
    getLavaRestakeProviders: build.query<
      LavaProviderData[],
      CommonValidatorsRequestParams | void
    >(lavaRestakeSpecsApiConfig.endpoints.getLavaRestakeProviders),
  }),
});

export const { getLavaRestakeProviders } = lavaRestakeSpecsApi.endpoints;

export const { useGetLavaRestakeProvidersQuery } = lavaRestakeSpecsApi;
