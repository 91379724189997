import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/commons/utils/query';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';

import {
  SolanaStakeAccountBalanceHistoryRequest,
  SolanaStakeAccountBalanceHistoryResponse,
} from './types';

export const solanaStakeAccountBalanceHistoryApi = createApi({
  baseQuery: solanaApiQuery,
  reducerPath: 'solanaStakeAccountBalanceHistoryApi',
  endpoints: (build) => ({
    getSolanaStakeAccountBalanceHistory: build.query<
      SolanaStakeAccountBalanceHistoryResponse[],
      SolanaStakeAccountBalanceHistoryRequest
    >({
      query: ({ balanceRange, stakeAccountAddress }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.SOLANA_STAKE_ACCOUNT_BALANCE_HISTORY.replace(
            '{stakeAccountAddress}',
            stakeAccountAddress
          ),
          { balanceRange }
        ),
      }),
    }),
  }),
});

export const { useGetSolanaStakeAccountBalanceHistoryQuery } =
  solanaStakeAccountBalanceHistoryApi;
