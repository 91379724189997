import { createApi } from '@reduxjs/toolkit/query/react';

import { ethereumApiQuery } from 'libs/commons/store/query';
import { getSortParams, addParamsToUrl } from 'libs/commons/utils/query';

import { URL } from 'api/constants';

import { IncomeHistoryResponse } from '../ethereum-validators';

import {
  Asset,
  AssetStatus,
  AssetsResponse,
  IncomesResponse,
  EthAssetsRequest,
  BalanceHistoryRequest,
  LastIncomeHistoryRequest,
  EthMultiValidatorsRequest,
  AggregatedBalanceHistoryResponse,
  EthMultiValidatorsSummariseRequest,
  EthMultiValidatorsSummariseResponse,
} from './types';

const handleWalletAddressesParam = (addresses: string[]) => ({
  walletAddresses: addresses.join(','),
});

const insertWalletAddresses = (
  url: string,
  addresses: EthMultiValidatorsRequest
) => addParamsToUrl(url, handleWalletAddressesParam(addresses));

export const ethereumMultiValidatorsApi = createApi({
  baseQuery: ethereumApiQuery,
  reducerPath: 'ethereumMultiValidatorsApi',
  endpoints: (build) => ({
    getAsset: build.query<AssetsResponse, EthAssetsRequest>({
      query: ({ walletAddresses }) => ({
        method: 'get',
        url: insertWalletAddresses(
          URL.ETHEREUM_MULTI_VALIDATORS_ASSETS,
          walletAddresses
        ),
      }),
    }),
    getIncome: build.query<IncomesResponse, EthMultiValidatorsRequest>({
      query: (walletAddress) => ({
        method: 'get',
        url: insertWalletAddresses(
          URL.ETHEREUM_MULTI_VALIDATORS_INCOMES,
          walletAddress
        ),
      }),
    }),
    getLastIncomeHistory: build.query<
      IncomeHistoryResponse[],
      LastIncomeHistoryRequest
    >({
      query: ({ limit, walletAddresses }) => ({
        method: 'get',
        url: addParamsToUrl(URL.ETHEREUM_MULTI_VALIDATORS_INCOME_HISTORY_LAST, {
          limit,
          ...handleWalletAddressesParam(walletAddresses),
        }),
      }),
    }),
    getEthMultiValidatorsSummarise: build.query<
      EthMultiValidatorsSummariseResponse,
      EthMultiValidatorsSummariseRequest
    >({
      query: ({ sort, walletAddresses }) => ({
        method: 'get',
        url: addParamsToUrl(URL.ETHEREUM_MULTI_VALIDATORS_SUMMARISE, {
          ...getSortParams(sort),
          ...handleWalletAddressesParam(walletAddresses),
        }),
      }),
    }),
    getAggregatedBalanceHistory: build.query<
      AggregatedBalanceHistoryResponse,
      BalanceHistoryRequest
    >({
      query: ({ balanceRange, walletAddresses }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.ETHEREUM_MULTI_VALIDATORS_BALANCE_HISTORY_AGGREGATION,
          {
            balanceRange,
            ...handleWalletAddressesParam(walletAddresses),
          }
        ),
      }),
    }),
  }),
});

export const {
  useGetAssetQuery,
  useGetIncomeQuery,
  useGetLastIncomeHistoryQuery,
  useGetAggregatedBalanceHistoryQuery,
  useGetEthMultiValidatorsSummariseQuery,
} = ethereumMultiValidatorsApi;

export type { Asset, AssetStatus };
