import { ReactNode } from 'react';

import { CosmosChainTypeIcon } from 'libs/core/types/data';

import { Icon } from '../components/icons';
import { ChainType, CurrencyLabel, AmountResponse } from '../types';

export const CurrencyToChainType: Record<
  AmountResponse['currency'],
  ChainType
> = {
  ukii: 'KII',
  ulava: 'LAVA',
  uscrt: 'SECRET',
  wei: 'ETHEREUM',
  uatom: 'COSMOS',
  gwei: 'ETHEREUM',
  uosmo: 'OSMOSIS',
  lamport: 'SOLANA',
};

export const ChainTypeToCurrencyLabel: Record<ChainType, CurrencyLabel> = {
  KII: 'KII',
  LAVA: 'LAVA',
  SOLANA: 'SOL',
  SECRET: 'SCRT',
  COSMOS: 'ATOM',
  ETHEREUM: 'ETH',
  OSMOSIS: 'OSMO',
};

export const ChainTypeIcon: Record<'UNKNOWN' | ChainType, ReactNode> = {
  ...CosmosChainTypeIcon,
  SOLANA: <Icon.Solana />,
  ETHEREUM: <Icon.Ethereum />,
  UNKNOWN: <Icon.Close width={24} height={24} />,
};

export const ChainTypeTitle: Record<ChainType, string> = {
  KII: 'KII',
  LAVA: 'Lava',
  SECRET: 'Secret',
  COSMOS: 'Cosmos',
  SOLANA: 'Solana',
  OSMOSIS: 'Osmosis',
  ETHEREUM: 'Ethereum',
};
