import { cosmosApiQuery } from '../../query';
import { URL } from '../../../api/constants';

import { LavaRewardsCalculateRequest } from './types';

export const lavaRewardsCalculatorApiConfig = {
  baseQuery: cosmosApiQuery,
  reducerPath: 'lavaRewardsCalculatorApi',
  endpoints: {
    calculate: {
      query: (data: LavaRewardsCalculateRequest) => ({
        data,
        method: 'post',
        url: URL.LAVA_REWARDS_CALCULATE,
      }),
    },
  },
};
