import { FieldMetaProps } from 'formik';

import { getErrorMessage } from 'libs/core/utils/error';

import { InputProps } from '../components';

import { showError } from './helpers';

export const controlError = <T = any>(
  meta: FieldMetaProps<T>,
  name: string,
  label?: InputProps['label']
) => {
  let error = meta && meta.touched && meta.error;
  if (!error) return undefined;
  return error.replace(name, typeof label === 'string' ? label : 'Value');
};

export const handleError = (
  error: any,
  defaultErrorMessage: string = 'Error'
) => {
  showError(getErrorMessage(error, defaultErrorMessage));
};

export * from '../../core/utils/error';
