import { CSSProperties } from 'react';
import {
  GroupBase,
  FormatOptionLabelMeta,
  components as reactSelectComponents,
} from 'react-select';

import { Icon } from '../icons';
import { ChainType } from '../../types';
import { InputLabel, InputError } from '../input';
import { Flex, ThemeDependentIcon } from '../styles';

import * as Styles from './styles';
import { isValidSlot } from './helpers';
import { OptionSlot, SelectProps, SelectOption } from './types';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    width?: string;
    error?: string;
    readonly: boolean;
    chainType?: ChainType;
    dropdownStyle?: CSSProperties;
    styleType: 'primary' | 'secondary';
  }
}

const SelectContainer: (typeof reactSelectComponents)['SelectContainer'] = ({
  children,
  ...props
}) => {
  return (
    <reactSelectComponents.SelectContainer {...props}>
      {children}

      {props.selectProps.error && !props.selectProps.menuIsOpen && (
        <InputError>{props.selectProps.error}</InputError>
      )}
    </reactSelectComponents.SelectContainer>
  );
};

const DropdownIndicator: (typeof reactSelectComponents)['DropdownIndicator'] = (
  props
) => {
  return (
    <reactSelectComponents.DropdownIndicator {...props}>
      <ThemeDependentIcon>
        <Styles.DropdownIndicatorArrow
          $open={props.selectProps.menuIsOpen}
          style={props.selectProps.dropdownStyle}
        />
      </ThemeDependentIcon>
    </reactSelectComponents.DropdownIndicator>
  );
};

const SlotContainer = ({
  slot,
  meta,
}: {
  slot?: OptionSlot;
  meta: FormatOptionLabelMeta<SelectOption>;
}) => {
  if (isValidSlot(slot)) {
    return <Styles.SlotContainer>{slot}</Styles.SlotContainer>;
  }

  if (slot?.context === meta.context) {
    return <Styles.SlotContainer>{slot.element}</Styles.SlotContainer>;
  }

  return null;
};

export const Select = <
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  value,
  error,
  styles,
  className,
  components,
  controlStyles,
  readonly = false,
  isClearable = true,
  isSearchable = true,
  styleType = 'primary',
  ...props
}: SelectProps<Option, IsMulti, Group>) => {
  const baseStyles = Styles.useStyles<Option, IsMulti, Group>(styles);

  return (
    <div>
      {label && <InputLabel $hasValue={Boolean(value)}>{label}</InputLabel>}

      <Styles.Select
        value={value}
        error={error}
        styles={baseStyles}
        readonly={readonly}
        styleType={styleType}
        className={className}
        classNamePrefix="select"
        isClearable={readonly ? false : isClearable}
        isSearchable={readonly ? false : isSearchable}
        components={{
          SelectContainer,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ...components,
        }}
        formatOptionLabel={(data, meta) =>
          data.leftSlot || data.rightSlot ? (
            <Flex gap={8} alignItems="center">
              {data.leftSlot && (
                <SlotContainer meta={meta} slot={data.leftSlot} />
              )}

              <Styles.OptionLabel>{data.label}</Styles.OptionLabel>

              {data.rightSlot && (
                <SlotContainer meta={meta} slot={data.rightSlot} />
              )}

              {data.isLoading && <Icon.Loader width={24} height={24} />}
            </Flex>
          ) : (
            <Styles.OptionLabel>{data.label}</Styles.OptionLabel>
          )
        }
        {...props}
      />
    </div>
  );
};

export type { SelectProps, SelectOption };
