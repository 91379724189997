import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/commons/utils/query';
import { cosmosApiQuery } from 'libs/cosmos-core/store/query';

import { URL } from 'api/constants';

import {
  CosmosIncomeHistory,
  GetCosmosValidatorsIncomeHistoryRequest,
  GetCosmosValidatorsIncomeHistoryResponse,
} from './types';

export const cosmosValidatorsHistoryApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosValidatorsHistoryApi',
  endpoints: (build) => ({
    getCosmosValidatorsIncomeHistory: build.query<
      GetCosmosValidatorsIncomeHistoryResponse,
      GetCosmosValidatorsIncomeHistoryRequest
    >({
      query: ({ walletAddress, numberOfLastBlockHeights }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.COSMOS_VALIDATORS_INCOME_HISTORY.replace(
            '{walletAddress}',
            walletAddress
          ),
          { numberOfLastBlockHeights }
        ),
      }),
    }),
  }),
});

export const { useGetCosmosValidatorsIncomeHistoryQuery } =
  cosmosValidatorsHistoryApi;

export type {
  CosmosIncomeHistory,
  GetCosmosValidatorsIncomeHistoryRequest,
  GetCosmosValidatorsIncomeHistoryResponse,
};
