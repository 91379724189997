import { Flex } from 'libs/commons/components';

import { InfoIcon } from 'components';

import * as Styles from './styles';
import { ValidatorNotRecommendedPopupProps } from './types';

export const ValidatorNotRecommendedPopup = ({
  reason,
  status,
  className,
  styleType = 'secondaryRed',
}: ValidatorNotRecommendedPopupProps) => {
  if (status !== 'NOT_RECOMMENDED') return null;

  return (
    <Styles.Popup
      on="hover"
      maxWidth="250px"
      trigger={
        <div className={className}>
          <Styles.NotRecommendedStatusButton
            icon={<InfoIcon />}
            styleType={styleType}
          />
        </div>
      }
    >
      <Flex column gap={8}>
        <Flex gap={8} alignItems="center">
          <Styles.Icon styleType={styleType} />

          <Styles.NotRecommendedLabel styleType={styleType}>
            Not recommended
          </Styles.NotRecommendedLabel>
        </Flex>

        {reason}
      </Flex>
    </Styles.Popup>
  );
};
