import { useState, FormEvent, ChangeEvent } from 'react';

import { Icon } from '../icons';
import { Flex } from '../styles';
import { usePagination } from '../../hooks';
import { DOTS } from '../../hooks/use-pagination';

import * as Styles from './styles';
import { PaginationProps } from './types';

export const Pagination = ({
  isError,
  size = 20,
  totalCount,
  isFetching,
  currentPage,
  onPageChange,
  siblingCount = 1,
  ...props
}: PaginationProps) => {
  const [goToPageValue, setGoToPageValue] = useState('');
  const totalPageCount = Math.ceil(totalCount / size);

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);

  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    totalPageCount,
  });

  if (currentPage === 0 || !paginationRange) {
    return null;
  }

  const clearGoToPageValue = (): void => setGoToPageValue('');

  const onGoToPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    const filteredValue = value.replace(/\D/g, '');
    const pageNumber = +filteredValue;

    if (filteredValue !== '' && (pageNumber < 1 || pageNumber > totalPageCount))
      return;

    setGoToPageValue(pageNumber === 0 ? '' : String(pageNumber));
  };

  const onGoToPageSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (goToPageValue) {
      onPageChange(+goToPageValue);
    }
  };

  const interactionDisabled = isError || isFetching;

  return (
    <Styles.Container $interactionDisabled={interactionDisabled} {...props}>
      <Flex gap={8}>
        <Styles.PaginationButton
          styleType="text"
          onClick={onPrevious}
          icon={<Styles.PrevArrow />}
          disabled={currentPage === 1}
        />

        <Flex as="ul" gap={8}>
          {paginationRange.map((pageNumber, index) => {
            const selected = pageNumber === currentPage;

            if (pageNumber === DOTS) {
              return (
                <Styles.DotsPaginationItem key={index}>
                  {DOTS}
                </Styles.DotsPaginationItem>
              );
            }

            return (
              <li key={index} onClick={() => onPageChange(pageNumber)}>
                <Styles.PaginationButton
                  text={String(pageNumber)}
                  styleType={selected ? 'secondary' : 'text'}
                />
              </li>
            );
          })}
        </Flex>

        <Styles.PaginationButton
          onClick={onNext}
          styleType="text"
          icon={<Icon.Chevron />}
          disabled={currentPage === totalPageCount}
        />
      </Flex>

      <Flex gap={8} as="form" alignItems="center" onSubmit={onGoToPageSubmit}>
        <label htmlFor="go-to-page">Go to</label>
        <Styles.GoToPageInput
          name="go-to-page"
          value={goToPageValue}
          onChange={onGoToPageChange}
          onBlur={clearGoToPageValue}
          inputContainerStyle={{
            height: 'inherit',
            borderRadius: '8px',
          }}
        />
      </Flex>
    </Styles.Container>
  );
};
