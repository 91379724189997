import styled, { css } from 'styled-components';

import { gradientBorder } from 'libs/commons/styles/mixins';

const commonContainerStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 28px;
`;

export const OptimizationEnabledContainer = styled.div`
  ${commonContainerStyles};
  align-self: flex-start;

  ${gradientBorder};
  box-shadow:
    4px 0px 12px 0px rgba(245, 245, 105, 0.3),
    -4px 0px 12px 0px rgba(20, 155, 78, 0.3),
    0px 12px 24px 0px rgba(0, 0, 0, 0.08);

  ${({ theme }) => css`
    background: ${theme.config.button.primaryDark.background?.main};
  `}
`;

export const OptimizationDisabledContainer = styled.div`
  ${commonContainerStyles};

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey.primary.hover};
    background: ${theme.colors.white.secondary.main};

    b {
      color: ${theme.colors.grey.primary.hover};
    }

    svg {
      path {
        fill: ${theme.colors.grey.primary.hover};
      }
    }
  `};
`;
