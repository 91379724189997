import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  BaseAmountResponse,
  PaginationResponse,
  AmountResponseInUsd,
  CosmosCurrencyLabel,
  CosmosAmountResponse,
  CosmosChainTypeOptions,
} from 'libs/core/types';

export const ChainTypeOptions = [
  'ETHEREUM',
  'SOLANA',
  ...CosmosChainTypeOptions,
] as const;
export type ChainType = (typeof ChainTypeOptions)[number];

export interface BaseQueryProps {
  isError?: boolean;
  refetch?: () => void;
  isFetching?: boolean;
}

export const EthereumChainTypeCurrencyOptions = ['wei', 'gwei'] as const;
export type EthereumChainCurrency =
  (typeof EthereumChainTypeCurrencyOptions)[number];

export type AmountResponse =
  | CosmosAmountResponse
  | BaseAmountResponse<'wei' | 'gwei' | 'lamport'>;

export interface AmountResponseWithUsd {
  amount: AmountResponse;
  amountInUsd: AmountResponseInUsd;
}

export interface CanvasBgDotParameters {
  x: number;
  y: number;
  size: number;
}

export interface Entity {
  id: number;
}

export type CurrencyLabel = 'ETH' | 'SOL' | CosmosCurrencyLabel;

export interface QueryReturnProps<T = unknown>
  extends Required<BaseQueryProps> {
  data?: T;
  error?: Error | SerializedError | FetchBaseQueryError;
}

export const BalanceRangeOptions = [
  'LAST_THIRTY_DAYS',
  'LAST_THREE_MONTH',
  'LAST_YEAR',
] as const;
export type BalanceRange = (typeof BalanceRangeOptions)[number];

export type Currency = 'ETH' | 'BTC' | 'USD';

export interface BalanceHistoryCompound {
  validatorAddress: string;
  compoundAmount: AmountResponse;
}

export type IncomeHistoryStatus = 'SUCCESS' | 'PENALIZED';

type NotificationType = 'INFO' | 'ACTION_REQUIRED';

type NotificationSeverity = 'LOW' | 'HIGH' | 'MEDIUM';

export interface NotificationData extends Entity {
  title: string;
  message: string;
  userUUID: string;
  email: string | null;
  type: NotificationType;
  severity: NotificationSeverity;
  noticedTimestampInMilli: number;
}

export const NotificationSeverityLabel: Record<NotificationSeverity, string> = {
  LOW: 'Low',
  HIGH: 'High',
  MEDIUM: 'Medium',
};

export interface NotificationsListResponse extends PaginationResponse {
  data: NotificationData[];
}

export * from '../../core/types';
