import { ReactNode } from 'react';

import { Flex } from 'libs/commons/components';

import { OverlappingIconList } from 'components/overlapping-icon-list';

export const OtherIconsCounter = <T extends unknown>({
  data,
  renderIcon,
}: {
  data: T[];
  renderIcon: (item: T) => ReactNode;
}) => {
  return (
    <Flex gap={4} alignItems="center">
      <OverlappingIconList renderIcon={renderIcon} data={data.slice(0, 3)} />

      <span>Other {data.length}+</span>
    </Flex>
  );
};
