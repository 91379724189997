import styled, { css } from 'styled-components';

import { gradientBorder } from 'libs/commons/styles/mixins';

export const Card = styled.button<{ $selected?: boolean }>`
  gap: 8px;
  width: 160px;
  display: flex;
  padding: 16px;
  position: relative;
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  transition: box-shadow 0.2s ease-in-out;

  ${({ theme, $selected }) => css`
    box-shadow: ${theme.config.boxShadow};
    background: ${theme.config.walletCard.default.background.main};

    &:disabled {
      opacity: 0.3;
      cursor: initial;
    }

    ${!$selected &&
    css`
      &:hover:not(:disabled) {
        box-shadow: ${theme.config.walletCard.default.boxShadow.hover};
        background: ${theme.config.walletCard.default.background.hover};
      }

      &:active:not(:disabled) {
        box-shadow: ${theme.config.walletCard.default.boxShadow.active};
        background: ${theme.config.walletCard.default.background.active};
      }
    `}
  `}

  ${gradientBorder};
`;

export const SelectedOverlay = styled.span`
  inset: 1px;
  width: 99%;
  height: 99%;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: inherit;
  justify-content: center;

  ${({ theme }) => css`
    background: ${theme.config.walletCard.selected.background.main};

    ${!theme.isDarkTheme &&
    css`
      opacity: 0.7;
    `}

    &:hover {
      background: ${theme.config.walletCard.selected.background.hover};

      ${!theme.isDarkTheme &&
      css`
        opacity: 0.5;
      `}
    }

    &:active {
      background: ${theme.config.walletCard.selected.background.active};
    }
  `}

  svg {
    width: 40px;
    height: 40px;
  }
`;
