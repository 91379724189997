import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/core/utils/query';
import { cosmosApiQuery } from 'libs/cosmos-core/store/query';
import { replaceAllWithMap } from 'libs/commons/utils/string';
import { cosmosValidatorsApiConfig } from 'libs/cosmos-core/store/api';
import {
  CosmosValidatorData,
  GetCosmosValidatorsResponse,
} from 'libs/cosmos-core/types';

import { URL } from 'api/constants';

import {
  GetCosmosValidatorsRequest,
  GetCosmosBalanceHistoryRequest,
  GetCosmosBalanceHistoryResponse,
} from './types';

export const cosmosValidatorsApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosValidatorsApi',
  tagTypes: cosmosValidatorsApiConfig.tagTypes,
  endpoints: (build) => ({
    getCosmosValidators: build.query<
      GetCosmosValidatorsResponse,
      GetCosmosValidatorsRequest
    >(cosmosValidatorsApiConfig.endpoints.getCosmosValidators),

    getCosmosBalanceHistory: build.query<
      GetCosmosBalanceHistoryResponse,
      GetCosmosBalanceHistoryRequest
    >({
      query: ({ balanceRange, walletAddress, validatorAddress }) => {
        const urlWithAddresses = replaceAllWithMap(
          URL.COSMOS_VALIDATORS_BALANCE_HISTORY,
          {
            '{walletAddress}': walletAddress,
            '{validatorAddress}': validatorAddress,
          }
        );

        return {
          method: 'get',
          url: addParamsToUrl(urlWithAddresses, {
            balanceRange,
          }),
        };
      },
    }),
  }),
});

export const { useGetCosmosValidatorsQuery, useGetCosmosBalanceHistoryQuery } =
  cosmosValidatorsApi;

export const { getCosmosValidators } = cosmosValidatorsApi.endpoints;

export type {
  CosmosValidatorData,
  GetCosmosValidatorsRequest,
  GetCosmosValidatorsResponse,
  GetCosmosBalanceHistoryRequest,
  GetCosmosBalanceHistoryResponse,
};
