import { useMemo } from 'react';
import { css, useTheme } from 'styled-components';
import styled, { DefaultTheme } from 'styled-components';
import ReactSelect, {
  GroupBase,
  OptionProps,
  StylesConfig,
  ControlProps,
  CSSObjectWithLabel,
} from 'react-select';

import { Icon } from '../icons';
import { ChainType } from '../../types';
import { EllipsisText } from '../ellipsis-text';
import { getThemeChainBasedColor } from '../../utils/helpers';
import selectBg from '../../assets/images/dropdown-background.svg';

import { SelectOption } from './types';

export const DropdownIndicatorArrow = styled(Icon.SelectArrow)<{
  $open: boolean;
}>`
  transition: transform 300ms;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;

const controlBaseStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseStyles: CSSObjectWithLabel,
  state: ControlProps<Option, IsMulti, Group>
): CSSObjectWithLabel =>
  ({
    ...baseStyles,
    minHeight: 0,
    borderWidth: 0,
    borderRadius: 0,
    transition: 'none',
    position: 'relative',

    ...(state.isDisabled && {
      opacity: '0.6',
    }),
  }) as CSSObjectWithLabel;

const optionBaseStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseStyles: CSSObjectWithLabel,
  state: OptionProps<Option, IsMulti, Group>,
  theme: DefaultTheme
): CSSObjectWithLabel =>
  ({
    ...baseStyles,
    padding: 16,
    color: theme.config.text.primary.main,

    ...(state.selectProps.styleType === 'secondary' && {
      position: 'relative',

      ':after': {
        left: 0,
        height: 1,
        bottom: 0,
        content: '""',
        width: '100%',
        position: 'absolute',
        background: theme.config.divider,
      },
    }),

    ...(state.isDisabled
      ? {
          opacity: 0.6,
        }
      : {
          ':active': {
            background: 'transparent',
          },
          ':hover': {
            background: theme.config.select.hover,
          },
        }),

    ...(state.isSelected
      ? {
          background: theme.config.select.selected,
          ':active': {
            background: theme.config.select.selected,
          },
        }
      : {
          background: 'transparent',
        }),
  }) as CSSObjectWithLabel;

export const selectGradientBorderStyles = <
  T extends {
    isFocused: boolean;
    selectProps: { error?: string; chainType?: ChainType };
  },
>(
  theme: DefaultTheme,
  state: T
): CSSObjectWithLabel => ({
  '::before': {
    inset: 0,
    padding: 1,
    zIndex: -1,
    content: '""',
    position: 'absolute',
    borderRadius: 'inherit',
    maskComposite: 'exclude',
    WebkitMaskComposite: 'xor',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    background: state.selectProps.error
      ? theme.colors.red.primary.main
      : state.isFocused
        ? getThemeChainBasedColor({
            colors: theme.config.chainGradient,
            chainType: state.selectProps.chainType,
          })
        : 'none',
  },
});

export const useStyles = <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  styles?: StylesConfig<Option, IsMulti, Group>
) => {
  const theme = useTheme();

  return useMemo<StylesConfig<Option, IsMulti, Group>>(
    () => ({
      ...styles,
      dropdownIndicator: (baseStyles) =>
        ({
          ...baseStyles,
          padding: 0,
        }) as CSSObjectWithLabel,
      placeholder: (baseStyles) =>
        ({
          ...baseStyles,
          color: theme.colors.grey.primary.main,
        }) as CSSObjectWithLabel,
      clearIndicator: () => ({
        width: 24,
        height: 24,
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
      }),
      multiValueLabel: (baseStyles) =>
        ({
          ...baseStyles,
          padding: 0,
          color: theme.config.text.primary.main,
        }) as CSSObjectWithLabel,
      input: (baseStyles) =>
        ({
          ...baseStyles,
          margin: 0,
          padding: 0,
          color: theme.config.text.primary.main,
        }) as CSSObjectWithLabel,
      loadingIndicator: (baseStyles) =>
        ({
          ...baseStyles,
          '> span': {
            width: '4px',
            height: '4px',
            marginLeft: '4px',
          },
        }) as CSSObjectWithLabel,
      singleValue: (baseStyles, state) =>
        ({
          ...baseStyles,
          color: theme.config.text.primary.main,
          ...styles?.singleValue?.(baseStyles, state),
          margin: 0,
        }) as CSSObjectWithLabel,
      multiValue: (baseStyles, state) =>
        ({
          ...baseStyles,
          borderRadius: '8px',
          backgroundColor: theme.config.text.secondary.hover,
          ...styles?.multiValue?.(baseStyles, state),
        }) as CSSObjectWithLabel,
      option: (baseStyles, state) => {
        if (styles?.option) {
          return styles.option(
            optionBaseStyles(baseStyles, state, theme),
            state
          );
        }

        return optionBaseStyles(baseStyles, state, theme);
      },
      valueContainer: (baseStyles, state) =>
        ({
          ...baseStyles,
          padding: 0,
          ...(state.selectProps.width && {
            width: state.selectProps.width,
          }),
          ...(state.selectProps.width && {
            width: state.selectProps.width,
          }),
        }) as CSSObjectWithLabel,
      menu: (baseStyles, state) => {
        if (state.selectProps.styleType === 'primary') {
          return {
            ...baseStyles,
            margin: 0,
            borderRadius: 0,
            overflow: 'auto',
            borderBottomLeftRadius: 32,
            borderBottomRightRadius: 32,
            backdropFilter: 'blur(40px)',
            boxShadow: theme.config.input.boxShadow.hover,
            background: theme.config.input.background.active,
            ...styles?.menu?.(baseStyles, state),
          };
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...baseStyles,
            marginTop: 24,
            overflow: 'auto',
            borderRadius: 28,
            background: theme.colors.black,
            ...styles?.menu?.(baseStyles, state),
          };
        }

        return {};
      },
      menuList: (baseStyles, state) => {
        const chainType = state.selectProps.chainType;

        if (state.selectProps.styleType === 'primary') {
          return {
            ...baseStyles,
            padding: 0,
            position: 'static',
            ...styles?.menuList?.(baseStyles, state),
          } as CSSObjectWithLabel;
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...baseStyles,

            padding: 0,
            borderRadius: 28,
            position: 'static',
            backgroundSize: 'contain',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${selectBg})`,

            '::before': {
              inset: 0,
              padding: 2,
              zIndex: -1,
              content: '""',
              borderRadius: 28,
              position: 'absolute',
              WebkitMaskComposite: 'xor',
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              background: getThemeChainBasedColor({
                chainType,
                colors: theme.config.chainGradient,
              }),
            },
            ...styles?.menuList?.(baseStyles, state),
          } as CSSObjectWithLabel;
        }

        return baseStyles;
      },
      control: (baseStyles, state) => {
        if (state.selectProps.styleType === 'primary') {
          return {
            ...controlBaseStyles<Option, IsMulti, Group>(baseStyles, state),
            padding: 16,
            boxShadow: theme.config.input.boxShadow.main,
            backdropFilter: theme.config.input.backdropFilter,

            ...(state.isFocused
              ? {
                  background: theme.config.input.background.active,
                }
              : {
                  background: theme.config.input.background.main,

                  ':hover': {
                    boxShadow: theme.config.input.boxShadow.hover,
                    background: theme.config.input.background.hover,
                  },
                }),

            ...(state.menuIsOpen
              ? {
                  borderTopLeftRadius: 28,
                  borderTopRightRadius: 28,
                }
              : {
                  borderRadius: 28,
                }),

            ...(state.isDisabled && {
              opacity: '0.6',
            }),

            ...selectGradientBorderStyles(theme, state),
            ...styles?.control?.(baseStyles, state),
          };
        }

        if (state.selectProps.styleType === 'secondary') {
          return {
            ...controlBaseStyles<Option, IsMulti, Group>(baseStyles, state),
            boxShadow: 'none',
            background: 'none',
            ...styles?.control?.(baseStyles, state),
          };
        }

        return {};
      },
    }),
    [theme, styles]
  );
};

export const SlotContainer = styled.div`
  line-height: 10px;
`;

export const OptionLabel = styled(EllipsisText)`
  flex: 1;
`;

export const Select = styled(ReactSelect)`
  .select__control::before {
    mask-composite: exclude;
  }

  .select__menu-list::before {
    mask-composite: exclude;
  }
` as typeof ReactSelect;
