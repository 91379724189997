import { ReactNode } from 'react';
import { AminoTypes } from '@cosmjs/stargate';
import { Registry } from '@cosmjs/proto-signing';
import {
  cosmosProtoRegistry,
  lavanetProtoRegistry,
  cosmosAminoConverters,
  lavanetAminoConverters,
} from 'cosmos-js-telescope';

import { Icon } from '../components/icon';

import { CosmosChainType, CosmosWalletProvider } from '.';

interface WalletProviderConfigBaseProps {
  title: string;
  icon: ReactNode;
}

export const CosmosWalletProviderConfig: Record<
  CosmosWalletProvider,
  WalletProviderConfigBaseProps
> = {
  leap: {
    title: 'Leap',
    icon: <Icon.Leap />,
  },
  keplr: {
    title: 'Keplr',
    icon: <Icon.Keplr />,
  },
  cosmostation: {
    title: 'Cosmostation',
    icon: <Icon.Cosmostation />,
  },
};

export const converters = {
  ...cosmosAminoConverters,
  ...lavanetAminoConverters,
};

export const registry = new Registry([
  ...cosmosProtoRegistry,
  ...lavanetProtoRegistry,
]);

export const aminoTypes = new AminoTypes({
  ...converters,
});

export const CosmosChainRpcUrls: Record<CosmosChainType, string> = {
  KII: '/api-kii-rpc',
  LAVA: '/api-lava-rpc',
  COSMOS: '/api-cosmos-rpc',
  SECRET: '/api-secret-rpc',
  OSMOSIS: '/api-osmosis-rpc',
};
