import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Routes } from 'config';
import { selectAuth } from 'store/slices/auth';
import { useAppDispatch, useAppSelector } from 'store';
import { connectCosmosChains } from 'store/slices/connected-cosmos-chains';

export const useCosmosWalletProvidersKeyStoreChangeEvent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { authUser, isLoggedIn } = useAppSelector(selectAuth);

  const navigateToDashboard = useCallback(() => {
    if (location.pathname !== Routes.Dashboard.Home && isLoggedIn) {
      navigate(Routes.Dashboard.Home);
    }
  }, [navigate, location.pathname, isLoggedIn]);

  const handleKeplrChange = useCallback(() => {
    navigateToDashboard();
    dispatch(connectCosmosChains('keplr'));
  }, [dispatch, navigateToDashboard]);

  const handleLeapChange = useCallback(() => {
    navigateToDashboard();
    dispatch(connectCosmosChains('leap'));
  }, [dispatch, navigateToDashboard]);

  const handleCosmostationChange = useCallback(() => {
    navigateToDashboard();
    dispatch(connectCosmosChains('cosmostation'));
  }, [dispatch, navigateToDashboard]);

  useEffect(() => {
    if (!authUser?.sub) {
      return;
    }

    window.addEventListener('keplr_keystorechange', handleKeplrChange);
    window.addEventListener('leap_keystorechange', handleLeapChange);

    const cosmostationAccountChangeEvent = window.cosmostation?.cosmos.on(
      'accountChanged',
      handleCosmostationChange
    );

    return () => {
      window.removeEventListener('keplr_keystorechange', handleKeplrChange);
      window.removeEventListener('leap_keystorechange', handleLeapChange);

      if (cosmostationAccountChangeEvent) {
        window.cosmostation?.cosmos.off(cosmostationAccountChangeEvent);
      }
    };
  }, [
    handleKeplrChange,
    handleLeapChange,
    handleCosmostationChange,
    authUser?.sub,
  ]);
};
