import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { ethereumApiInstance } from '../api/instance';

interface Error {
  data?: any;
  status?: number;
  message?: string;
}

export const axiosBaseQuery =
  ({
    instance,
  }: {
    instance: AxiosInstance;
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
      timeout?: AxiosRequestConfig['timeout'];
    },
    unknown,
    Error
  > =>
  async ({ url, data, method, timeout, headers }, { signal }) => {
    try {
      const result = await instance({
        url,
        data,
        method,
        signal,
        headers,
        timeout,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          url,
          message: err.message,
          data: err.response?.data,
          status: err.response?.status,
        },
      };
    }
  };

export const ethereumApiQuery = axiosBaseQuery({
  instance: ethereumApiInstance,
});
