export interface CommonWalletDataResponse {
  id: number;
  address: string;
  isReadOnly?: boolean;
}

export interface BaseAmountResponse<Currency extends string = string> {
  value: string;
  currency: Currency;
}

export type AmountResponseInUsd = BaseAmountResponse<'usd'>;

export interface PaginationResponse {
  pagination: {
    totalCount: number;
  };
}

export type SortTableOrder = 'asc' | 'desc';

export interface SortRequest {
  sort: Record<string, SortTableOrder>;
}

export interface PaginationRequest {
  size?: number;
  page: string | number;
}

export const CosmosChainTypeCurrencyOptions = [
  'uscrt',
  'uatom',
  'ulava',
  'uosmo',
  'ukii',
] as const;
export type CosmosChainTypeCurrency =
  (typeof CosmosChainTypeCurrencyOptions)[number];

export type CosmosAmountResponse = BaseAmountResponse<CosmosChainTypeCurrency>;

export const CosmosChainTypeOptions = [
  'SECRET',
  'COSMOS',
  'LAVA',
  'OSMOSIS',
  'KII',
] as const;
export type CosmosChainType = (typeof CosmosChainTypeOptions)[number];

export type CosmosCurrencyLabel = 'KII' | 'ATOM' | 'SCRT' | 'LAVA' | 'OSMO';
