import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosValidatorsDelegationsApiConfig } from 'libs/cosmos-core/store/api';
import {
  UnstakeDetails,
  UnDelegationData,
  ReDelegationData,
  GetCosmosDelegationsWalletResponse,
} from 'libs/cosmos-core/store/api/validators-delegations/types';

export const cosmosDelegationsApi = createApi({
  tagTypes: cosmosValidatorsDelegationsApiConfig.tagTypes,
  baseQuery: cosmosValidatorsDelegationsApiConfig.baseQuery,
  reducerPath: cosmosValidatorsDelegationsApiConfig.reducerPath,
  endpoints: (build) => ({
    getCosmosDelegationsWallet: build.query<
      GetCosmosDelegationsWalletResponse,
      string
    >(
      cosmosValidatorsDelegationsApiConfig.endpoints
        .getCosmosValidatorsDelegations
    ),
  }),
});

export const { useGetCosmosDelegationsWalletQuery } = cosmosDelegationsApi;

export type {
  UnstakeDetails,
  UnDelegationData,
  ReDelegationData,
  GetCosmosDelegationsWalletResponse,
};
