import { TableViewType } from '../../types';
import { TableRowProps } from '../../../../components/table-row';

import * as Styles from './styles';

interface PlaceholderTableRowProps extends Omit<TableRowProps, 'values'> {
  index: number;
  columnsCount?: number;
  viewType?: TableViewType;
}

export const PlaceholderTableRow = ({
  index,
  columnsCount,
  viewType = 'default',
  ...props
}: PlaceholderTableRowProps) => {
  return (
    <Styles.TableRow
      {...props}
      $index={index}
      $viewType={viewType}
      values={[...new Array(columnsCount)].map((_, innerIndex) => (
        <Styles.PlaceholderItem key={innerIndex} />
      ))}
    />
  );
};
