import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/commons/utils/query';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';

import {
  SolanaDelegationsBalanceHistoryRequest,
  SolanaDelegationsBalanceHistoryResponse,
} from './types';

export const solanaDelegationsBalanceHistoryApi = createApi({
  baseQuery: solanaApiQuery,
  reducerPath: 'solanaDelegationsBalanceHistoryApi',
  endpoints: (build) => ({
    getSolanaBalanceHistory: build.query<
      SolanaDelegationsBalanceHistoryResponse,
      SolanaDelegationsBalanceHistoryRequest
    >({
      query: ({ balanceRange, walletAddress }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.MULTI_VALIDATORS_BALANCE_HISTORY.replace(
            '{walletAddress}',
            walletAddress
          ),
          { balanceRange }
        ),
      }),
    }),
  }),
});

export const { useGetSolanaBalanceHistoryQuery } =
  solanaDelegationsBalanceHistoryApi;

export type { SolanaDelegationsBalanceHistoryResponse };
