import { convertAndLabelCurrency } from 'libs/commons/utils/currencies';

import { ChainToBaseCurrency } from 'types/data';

import * as Styles from './styles';
import { TotalTokensTableCellProps } from './types';

export const TotalTokensTableCell = ({
  data,
  chainType,
}: TotalTokensTableCellProps) => (
  <Styles.Container>
    {typeof data === 'string'
      ? chainType &&
        convertAndLabelCurrency({
          value: data,
          currency: ChainToBaseCurrency[chainType],
        })
      : convertAndLabelCurrency(data)}
  </Styles.Container>
);
