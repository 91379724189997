import { ReactNode } from 'react';

import { AmountResponse } from 'libs/commons/types';

import { TransactionStatus, DelegableChainType } from 'types';

export type EntryTransactionStatus = 'error' | 'success' | 'progress';

export interface EntryTransactionInfo {
  amount: string;
  status?: EntryTransactionStatus;
}

export type DelegationProcessType =
  | 'optimization'
  | 'lavaDelegation'
  | 'defaultDelegation';

export const allSteps = [
  'preferences',
  'validators-selection',
  'optimization-info',
  'providers-selection',
  'optimization-current-validators',
  'optimization-confirmation',
] as const;

export const defaultDelegationStepsOrder: Step[] = [
  'preferences',
  'validators-selection',
  'optimization-info',
];

export const optimizationStepsOrder: Step[] = [
  'preferences',
  'optimization-current-validators',
  'optimization-confirmation',
];

export const lavaDelegationStepsOrder: Step[] = [
  'preferences',
  'validators-selection',
  'providers-selection',
  'optimization-info',
];

export type Step = (typeof allSteps)[number];

export type DelegationEntryType = 'providers' | 'validators';

export type LavaDelegationStatus =
  | 'delegationToProviders'
  | 'delegationToValidators';

type DelegationEntiesConfig<T> = {
  [key in DelegationEntryType]: T;
};

export interface DelegationProcessState {
  step: Step;
  retries: number;
  showLoader: boolean;
  retriesLimit: number;
  initialTokensAmount: string;
  type: DelegationProcessType;
  delegationInProgress: boolean;
  selectedPreferences: string[];
  chainType: DelegableChainType;
  fullEntriesListOpened: boolean;
  status: TransactionStatus | null;
  selectedWalletAddress: string | null;
  isSuccess: DelegationEntiesConfig<boolean>;
  lavaDelegationStatus?: LavaDelegationStatus;
  gasUsed: DelegationEntiesConfig<AmountResponse | null>;
  entries: DelegationEntiesConfig<Record<string, EntryTransactionInfo>>;
}

export const stepsByTypeOrder: Record<DelegationProcessType, Step[]> = {
  optimization: optimizationStepsOrder,
  lavaDelegation: lavaDelegationStepsOrder,
  defaultDelegation: defaultDelegationStepsOrder,
};

export interface DelegationPreferenceConfig {
  label: string;
  icon: ReactNode;
  selectedIcon: ReactNode;
}
