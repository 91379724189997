import styled from 'styled-components';

import { Button, TableRow as TableRowComponent } from 'libs/commons/components';

export const TableRow = styled(TableRowComponent)`
  padding: 8px 16px;
`;

export const AddValidatorButton = styled(Button)`
  padding: 12px 24px;
  width: 104px;
  align-self: flex-end;

  &::before {
    padding: 2px;
  }
`;
