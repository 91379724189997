import { createApi } from '@reduxjs/toolkit/query/react';

import { AmountResponse } from 'libs/commons/types';
import { cosmosApiQuery } from 'libs/cosmos-core/store/query';
import { cosmosWalletBalanceApiConfig } from 'libs/cosmos-core/store/api';

export const cosmosWalletBalanceApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosWalletBalanceApi',
  tagTypes: cosmosWalletBalanceApiConfig.tagTypes,
  endpoints: (build) => ({
    getCosmosWalletBalance: build.query<AmountResponse, string>(
      cosmosWalletBalanceApiConfig.endpoints.getCosmosWalletBalance
    ),
  }),
});

export const { useGetCosmosWalletBalanceQuery } = cosmosWalletBalanceApi;
