import { ReactNode, HTMLAttributes } from 'react';

import { ChainType } from '../../types';
import { useTableSort } from '../../hooks';
import { Table } from '../../components/table';
import { TableProps } from '../../components/table/types';
import { SortableHeader } from '../../components/table/sortable-header';

export interface SortableHeaderProps extends HTMLAttributes<HTMLButtonElement> {
  info?: string;
  label: ReactNode;
  accessor: string;
}

export interface TableWithSortingProps
  extends Omit<TableProps, 'headers'>,
    Omit<ReturnType<typeof useTableSort>, 'setSortState'> {
  chainType?: ChainType;
  headers: (ReactNode | SortableHeaderProps)[];
}

export const isSortableHeader = (
  props: ReactNode | SortableHeaderProps
): props is SortableHeaderProps =>
  Boolean(
    props &&
      typeof props === 'object' &&
      'accessor' in props &&
      'label' in props
  );

export const mapSortableHeaders = (
  headers: TableWithSortingProps['headers'],
  sortState: TableWithSortingProps['sortState'],
  handleSorting: TableWithSortingProps['handleSorting'],
  disabled?: boolean,
  chainType?: ChainType
) =>
  headers.map((header) => {
    if (isSortableHeader(header)) {
      return (
        <SortableHeader
          info={header.info}
          disabled={disabled}
          style={header.style}
          chainType={chainType}
          order={sortState[header.accessor]}
          onClick={() => {
            handleSorting(header.accessor);
          }}
        >
          {header.label}
        </SortableHeader>
      );
    }

    return header;
  });

export const SortableTable = ({
  headers,
  sortState,
  chainType,
  handleSorting,
  ...props
}: TableWithSortingProps) => {
  const resultHeaders = mapSortableHeaders(
    headers,
    sortState,
    handleSorting,
    false,
    chainType
  );

  return <Table {...props} headers={resultHeaders} />;
};
