import styled, { css } from 'styled-components';

import { subTitle } from 'libs/commons/styles';
import { gradientBorder } from 'libs/commons/styles/mixins';
import {
  Flex,
  Icon,
  Caption,
  EllipsisText,
  LightGrayOpacity,
  AccentGradientIcon,
} from 'libs/commons/components';

import { NumberCardProps } from './types';

export const Container = styled(Flex)<
  Pick<NumberCardProps, 'chainType'> & {
    $hasError: boolean;
  }
>`
  flex: 1;
  padding: 8px 16px;
  border-radius: 40px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  gap: 16px;
  justify-content: space-between;

  ${({ theme, $hasError, chainType }) => {
    const backgroundColors = theme.config.numberCard.background;

    const backgroundColor =
      chainType && chainType in backgroundColors
        ? backgroundColors[chainType as keyof typeof backgroundColors]
        : backgroundColors.default;

    return css`
      box-shadow: ${theme.config.numberCard.boxShadow};
      background: ${backgroundColor};
      border: ${theme.config.transparentBorder};

      ${theme.responsive.isMobile &&
      css`
        text-align: center;
      `}

      ${$hasError &&
      css`
        min-width: 0;
      `}
    
    ${chainType === 'LAVA' &&
      css`
        ${gradientBorder};

        &::before {
          background: ${theme.colors.lavaGradient.main};
        }
      `}
    `;
  }}
`;

export const Inner = styled(Flex)`
  gap: 8px;
  min-width: 0;
  align-items: center;
`;

export const IconContainer = styled(AccentGradientIcon)`
  width: 32px;
  height: 32px;
`;

export const NumberChange = styled(Caption)<{
  $numberChangeIncreased: boolean;
}>`
  display: flex;
  font-weight: 500;
  align-items: center;

  ${({ theme, $numberChangeIncreased }) => {
    const color = $numberChangeIncreased
      ? theme.colors.success
      : theme.colors.red.primary.main;

    return css`
      color: ${color};

      svg {
        ${$numberChangeIncreased &&
        css`
          transform: rotate(180deg);
        `}

        path {
          fill: ${color};
        }
      }
    `;
  }}
`;

export const Error = styled(EllipsisText)`
  color: ${({ theme }) => theme.colors.red.additional.main};
`;

export const CurrencyLabel = styled(LightGrayOpacity)`
  ${subTitle};
`;

export const Loader = styled(Icon.Loader)`
  margin: 0 auto;
  width: 24px;
  height: 24px;
`;
