import { VirtualizedList } from 'components';
import { useAnalyticsEventTracker } from 'hooks';

import { BaseTableProps } from './types';

export const BaseTable = <T,>({
  data,
  headers,
  isError,
  refetch,
  template,
  sortState,
  chainType,
  emptyTitle,
  isFetching,
  rowRenderer,
  handleSorting,
  sortingDisabled,
  placeholderRowsCount = 9,
}: BaseTableProps<T>) => {
  const eventTracker = useAnalyticsEventTracker(
    'Choose validators to delegate'
  );

  return (
    <VirtualizedList
      data={data}
      isError={isError}
      refetch={refetch}
      headers={headers}
      template={template}
      sortState={sortState}
      chainType={chainType}
      isFetching={isFetching}
      emptyTitle={emptyTitle}
      rowRenderer={rowRenderer}
      sortingDisabled={sortingDisabled}
      placeholderRowsCount={placeholderRowsCount}
      handleSorting={(accessor) => {
        eventTracker?.({
          label: accessor,
          action: `Sort by ${accessor}`,
        });

        handleSorting(accessor);
      }}
    />
  );
};
