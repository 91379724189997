import { URL } from '../../api/constants';
import { CommonValidatorsRequestParams } from '../../types';
import {
  getPageParams,
  getSortParams,
  addParamsToUrl,
} from '../../../core/utils/query';

export const tagTypes = ['cosmos-validators'];

export const endpoints = {
  getCosmosValidators: {
    providesTags: tagTypes,
    query: ({
      sort,
      page = 0,
      size = 1000,
      preferencesSort,
      ...params
    }: CommonValidatorsRequestParams) => {
      return {
        method: 'get',
        url: addParamsToUrl(URL.VALIDATORS, {
          size,
          ...params,
          ...getPageParams(page),
          ...getSortParams({
            ...sort,
            ...(preferencesSort ? JSON.parse(preferencesSort) : {}),
          }),
        }),
      };
    },
  },
};
