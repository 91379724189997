import { Fragment } from 'react';

import { Flex, LightGrayOpacity } from 'libs/commons/components';
import { convertAndLabelCurrency } from 'libs/commons/utils/currencies';

import { Icon, CosmosValidatorInfo } from 'components';
import { CosmosValidatorBalanceHistoryRedelegationData } from 'store/api/cosmos-validators/types';

import { TooltipRow } from '../row';
import { TooltipRowDivider } from '../styles';

export const Redelegations = ({
  data,
}: {
  data: CosmosValidatorBalanceHistoryRedelegationData[];
}) => {
  return (
    <>
      <TooltipRowDivider />

      {data.map((item, index) => (
        <Fragment key={index}>
          <TooltipRow
            value={convertAndLabelCurrency(item.redelegationAmount)}
            title={
              <>
                <Icon.BalanceHistoryChartRedelegation />
                <div>Redelegation</div>
              </>
            }
          />

          <Flex
            gap={16}
            minWidth={0}
            alignItems="center"
            justifyContent="space-between"
          >
            <LightGrayOpacity>From:</LightGrayOpacity>

            <CosmosValidatorInfo
              iconSize={16}
              address={item.validatorDstAddress}
            />
          </Flex>

          <Flex
            gap={16}
            minWidth={0}
            alignItems="center"
            justifyContent="space-between"
          >
            <LightGrayOpacity>To:</LightGrayOpacity>

            <CosmosValidatorInfo
              iconSize={16}
              address={item.validatorDstAddress}
            />
          </Flex>
        </Fragment>
      ))}
    </>
  );
};
