import { createSelector } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AmountResponse } from 'libs/commons/types';
import { showError } from 'libs/commons/utils/helpers';
import { determineCosmosChainFromAddress } from 'libs/cosmos-core/utils/helpers';

import { RootState } from 'store';
import { validateSolanaAddress } from 'utils/validation';
import { TransactionStatus, DelegableChainType } from 'types';
import { solanaValidatorsDelegationBatchSize } from 'hooks/solana/use-solana-tx';

import { delegationPreferences } from './config';
import { getTotalTokensAmount, distributeAmountAmongEntries } from './helpers';
import {
  stepsByTypeOrder,
  DelegationEntryType,
  LavaDelegationStatus,
  DelegationProcessType,
  DelegationProcessState,
  EntryTransactionStatus,
} from './types';

const initialState: DelegationProcessState = {
  retries: 0,
  status: null,
  retriesLimit: 10,
  showLoader: false,
  step: 'preferences',
  chainType: 'COSMOS',
  selectedPreferences: [],
  initialTokensAmount: '',
  type: 'defaultDelegation',
  delegationInProgress: false,
  selectedWalletAddress: null,
  fullEntriesListOpened: false,
  entries: {
    providers: {},
    validators: {},
  },
  gasUsed: {
    providers: null,
    validators: null,
  },
  isSuccess: {
    providers: false,
    validators: false,
  },
};

export const delegationProcessStateSlice = createSlice({
  initialState,
  name: 'delegationProcessState',
  reducers: {
    resetDelegationProcessState: () => initialState,
    closeLoader: (state) => {
      state.showLoader = false;
    },
    openFullEntriesList: (state) => {
      state.fullEntriesListOpened = true;
    },
    toggleFullEntriesList: (state) => {
      state.fullEntriesListOpened = !state.fullEntriesListOpened;
    },
    startDelegation: (state) => {
      state.showLoader = true;
      state.delegationInProgress = true;
    },
    setDelegationRetries: (state, action: PayloadAction<number>) => {
      state.retries = action.payload;
    },
    setDelegationRetriesLimit: (state, { payload }: PayloadAction<number>) => {
      state.retriesLimit = payload;
    },
    setDelegationStatus: (state, action: PayloadAction<TransactionStatus>) => {
      state.status = action.payload;
    },
    clearEntries: (state, action: PayloadAction<DelegationEntryType>) => {
      state.entries[action.payload] = {};
    },
    setInitialTokensAmount: (state, action: PayloadAction<string>) => {
      state.initialTokensAmount = action.payload;
    },
    enableToProviderDelegation: (state) => {
      state.type = 'lavaDelegation';
      state.step = 'providers-selection';
    },
    resetTransactionState: (state) => {
      state.status = null;
      state.retries = 0;
      state.delegationInProgress = false;
    },
    setSelectedDelegationPreference: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.selectedPreferences = action.payload;
    },
    setLavaDelegationStatus: (
      state,
      action: PayloadAction<LavaDelegationStatus>
    ) => {
      state.lavaDelegationStatus = action.payload;
    },
    setDelegationFlowType: (
      state,
      action: PayloadAction<DelegationProcessType>
    ) => {
      state.type = action.payload;
      state.step = stepsByTypeOrder[action.payload][0];
    },
    switchToValidatorDelegation: (state) => ({
      ...initialState,
      type: 'defaultDelegation',
      chainType: state.chainType,
      step: stepsByTypeOrder.defaultDelegation[0],
      selectedWalletAddress: state.selectedWalletAddress,
    }),
    clearEntriesAmountValues: (
      state,
      { payload }: PayloadAction<DelegationEntryType>
    ) => {
      state.entries[payload] = Object.fromEntries(
        Object.keys(state.entries[payload] ?? {}).map((key) => [
          key,
          { amount: '' },
        ])
      );
    },
    toggleDelegationPreference: (state, action: PayloadAction<string>) => {
      const index = state.selectedPreferences.indexOf(action.payload);
      if (index === -1) {
        state.selectedPreferences.push(action.payload);
      } else {
        state.selectedPreferences.splice(index, 1);
      }
    },
    handleEntriesDelegationSuccess: (
      state,
      action: PayloadAction<{
        gasUsed?: AmountResponse;
        entryType: DelegationEntryType;
      }>
    ) => {
      const { gasUsed, entryType } = action.payload;

      if (gasUsed) {
        state.gasUsed[entryType] = gasUsed;
      }

      state.isSuccess[entryType] = true;
    },
    setEntriesTransactionStatus: (
      state,
      action: PayloadAction<{
        addresses: string[];
        entryType: DelegationEntryType;
        status: EntryTransactionStatus;
      }>
    ) => {
      const { status, addresses, entryType } = action.payload;

      addresses.forEach((address) => {
        state.entries[entryType][address].status = status;
      });
    },
    setEntryAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        address: string;
        type: DelegationEntryType;
      }>
    ) => {
      const { type, amount, address } = action.payload;

      if (!state.entries[type][address]) {
        state.entries[type][address] = {
          amount,
        };
      } else {
        state.entries[type][address].amount = amount;
      }
    },
    removeEntry: (
      state,
      action: PayloadAction<{ address: string; entryType: DelegationEntryType }>
    ) => {
      const { address, entryType } = action.payload;

      const currentAmount = getTotalTokensAmount(entryType)(state);

      delete state.entries[entryType][address];

      state.entries[entryType] = distributeAmountAmongEntries(
        currentAmount,
        Object.keys(state.entries[entryType])
      );
    },
    setEntries: (
      state,
      action: PayloadAction<{
        addresses: string[];
        entryType: DelegationEntryType;
      }>
    ) => {
      const { addresses, entryType } = action.payload;

      state.entries[entryType] = Object.fromEntries(
        Object.entries(state.entries[entryType]).filter(([validatorAddress]) =>
          addresses.includes(validatorAddress)
        )
      );

      state.entries[entryType] = distributeAmountAmongEntries(
        state.initialTokensAmount,
        action.payload.addresses
      );
    },
    handleDelegateError: (state, action: PayloadAction<string>) => {
      showError(action.payload);
      state.showLoader = false;
      state.retries = 0;
      state.status = null;
      state.delegationInProgress = false;

      state.entries.providers = Object.fromEntries(
        Object.entries(state.entries.providers).map(([address, data]) => [
          address,
          { amount: data.amount },
        ])
      );
      state.entries.validators = Object.fromEntries(
        Object.entries(state.entries.validators).map(([address, data]) => [
          address,
          { amount: data.amount },
        ])
      );
    },
    previousDelegationStep: (state) => {
      if (
        state.type === 'optimization' &&
        stepsByTypeOrder[state.type].indexOf(state.step) === 1
      ) {
        state.type = 'defaultDelegation';
        state.step = stepsByTypeOrder[state.type][0];
        return;
      }

      const currentIndex = stepsByTypeOrder[state.type].indexOf(state.step);
      const isFirstStep = currentIndex === 0;

      if (isFirstStep) {
        return initialState;
      }

      const fromValidatorsSelection = state.step === 'validators-selection';

      if (fromValidatorsSelection) {
        state.entries.validators = {};
        state.selectedPreferences = Object.keys(
          delegationPreferences[state.chainType]
        );
      }

      state.step = stepsByTypeOrder[state.type][currentIndex - 1];
    },
    addEntry: (
      state,
      action: PayloadAction<{
        address: string;
        totalAmount?: string;
        entryType: DelegationEntryType;
      }>
    ) => {
      const { entries, initialTokensAmount } = state;
      const { address, entryType, totalAmount } = action.payload;

      if (entryType === 'providers') {
        if (totalAmount) {
          state.entries[entryType] = distributeAmountAmongEntries(totalAmount, [
            ...Object.keys(state.entries[entryType]),
            address,
          ]);
        }
      } else {
        const totalTokensAmount = getTotalTokensAmount(entryType)(state);

        const currentEntries = Object.keys(entries[entryType]);
        const noCurrentEntries = currentEntries.length === 0;

        state.entries[entryType] = distributeAmountAmongEntries(
          noCurrentEntries ? initialTokensAmount : totalTokensAmount,
          [...Object.keys(state.entries[entryType]), address]
        );
      }
    },
    setSelectedDelegationWallet: (
      state,
      action: PayloadAction<{
        walletAddress: string;
        initialValues?: Partial<DelegationProcessState>;
      }>
    ) => {
      const { walletAddress, initialValues } = action.payload;

      let chainType: DelegableChainType | undefined;

      if (validateSolanaAddress(walletAddress)) {
        chainType = 'SOLANA';
      } else {
        chainType = determineCosmosChainFromAddress(walletAddress);
      }

      if (!chainType) {
        showError('Chain type not found');
        return;
      }

      if (initialValues) {
        Object.entries(initialValues).forEach(([key, value]) => {
          (state[key as keyof DelegationProcessState] as typeof value) = value;
        });
      }

      state.chainType = chainType;
      state.selectedPreferences = Object.keys(delegationPreferences[chainType]);
      state.selectedWalletAddress = walletAddress;
      state.type =
        chainType === 'LAVA' ? 'lavaDelegation' : 'defaultDelegation';
    },
    nextDelegationStep: (state) => {
      const validatorsTokens = +getTotalTokensAmount('validators')(state);
      const providersTokens = +getTotalTokensAmount('providers')(state);

      if (state.step === 'preferences' && state.type !== 'optimization') {
        state.type =
          state.chainType === 'LAVA' ? 'lavaDelegation' : 'defaultDelegation';

        if (!state.initialTokensAmount) {
          showError('Please enter the amount of tokens to delegate');
          return;
        }

        if (state.selectedPreferences.length === 0) {
          showError('Please select at least one preference');
          return;
        }
      }

      if (state.step === 'validators-selection') {
        if (!validatorsTokens) {
          showError('Please enter the amount of tokens to delegate');
          return;
        }
      }

      if (state.step === 'providers-selection') {
        if (!validatorsTokens && !providersTokens) {
          showError('Please enter the amount of tokens to delegate');
          return;
        }

        if (validatorsTokens && providersTokens !== validatorsTokens) {
          showError('Providers amount and validators amount should be equal');
          return;
        }
      }

      const currentIndex = stepsByTypeOrder[state.type].indexOf(state.step);
      const nextStep = stepsByTypeOrder[state.type][currentIndex + 1];

      state.step = nextStep;
    },
  },
});

export const {
  actions: {
    addEntry,
    setEntries,
    closeLoader,
    removeEntry,
    clearEntries,
    setEntryAmount,
    startDelegation,
    nextDelegationStep,
    setDelegationStatus,
    handleDelegateError,
    openFullEntriesList,
    setDelegationRetries,
    setDelegationFlowType,
    resetTransactionState,
    toggleFullEntriesList,
    previousDelegationStep,
    setInitialTokensAmount,
    setLavaDelegationStatus,
    clearEntriesAmountValues,
    setDelegationRetriesLimit,
    enableToProviderDelegation,
    toggleDelegationPreference,
    switchToValidatorDelegation,
    resetDelegationProcessState,
    setSelectedDelegationWallet,
    setEntriesTransactionStatus,
    handleEntriesDelegationSuccess,
    setSelectedDelegationPreference,
  },
} = delegationProcessStateSlice;

export const selectDelegationProcessState = (store: RootState) =>
  store.delegationProcessState;

export const selectSelectedDelegationPreferences = createSelector(
  [selectDelegationProcessState],
  (store) => store.selectedPreferences
);

export const selectCurrentDelegationChainType = createSelector(
  [selectDelegationProcessState],
  (store) => store.chainType
);

export const selectDelegationFlowType = createSelector(
  [selectDelegationProcessState],
  (store) => store.type
);

export const selectTotalTokensAmount = (entryType: DelegationEntryType) =>
  createSelector(
    [selectDelegationProcessState],
    getTotalTokensAmount(entryType)
  );

export const selectDelegationAmount = (
  address: string,
  delegaitonEntryType: DelegationEntryType
) =>
  createSelector(
    [selectDelegationProcessState],
    (store) => store.entries[delegaitonEntryType][address]?.amount ?? ''
  );

export const selectCurrentDelegationStep = createSelector(
  [selectDelegationProcessState],
  (store) => store.step
);

export const selectProvidersEntries = createSelector(
  [selectDelegationProcessState],
  (store) => store.entries.providers
);

export const selectEntriesWithAmount = (entryType: DelegationEntryType) =>
  createSelector([selectDelegationProcessState], (store) =>
    Object.entries(store.entries[entryType]).filter(([, { amount }]) =>
      Boolean(+amount)
    )
  );

export const selectValidatorsWithAmount = selectEntriesWithAmount('validators');

export const selectProvidersWithAmount = selectEntriesWithAmount('providers');

export const selectShouldUseLavaSingleTransaction = (store: RootState) => {
  const totalValidatorsTokens = selectTotalTokensAmount('validators')(store);
  const totalProvidersTokens = selectTotalTokensAmount('providers')(store);
  const validators = selectValidatorsWithAmount(store);
  const providers = selectProvidersWithAmount(store);

  const singleDelegationToValidatorAndProvider =
    validators.length === 1 &&
    providers.length === 1 &&
    totalValidatorsTokens === totalProvidersTokens;

  return singleDelegationToValidatorAndProvider;
};

export const selectValidatorsTransactionActiveStep = createSelector(
  [selectValidatorsWithAmount],
  (validators) => {
    const firstValidatorInProgressIndex = validators.findIndex(
      ([, { status }]) => status === 'progress'
    );

    if (firstValidatorInProgressIndex !== -1) {
      return Math.floor(
        firstValidatorInProgressIndex / solanaValidatorsDelegationBatchSize
      );
    }
  }
);

export const selectValidatorsInProgress = createSelector(
  [selectValidatorsWithAmount],
  (store) => store.filter(([, { status }]) => status === 'progress')
);

export const selectDelegationStatus = createSelector(
  [selectDelegationProcessState],
  (store) => store.status
);

export const selectDelegationRetries = createSelector(
  [selectDelegationProcessState],
  (store) => store.retries
);

export const selectDelegationRetriesLimit = createSelector(
  [selectDelegationProcessState],
  (store) => store.retriesLimit
);

export const selectLavaDelegationStatus = createSelector(
  [selectDelegationProcessState],
  (store) => store.lavaDelegationStatus
);

export const selectShowLoader = createSelector(
  [selectDelegationProcessState],
  (store) => store.showLoader
);
