import { add, divide, format, subtract, bignumber } from 'mathjs';

import {
  DelegationEntryType,
  EntryTransactionInfo,
  DelegationProcessState,
} from 'store/slices/delegation-process-state/types';

export const getTotalTokensAmount =
  (entryType: DelegationEntryType) => (store: DelegationProcessState) => {
    const entries = store.entries[entryType];

    const totalAmount = Object.values(entries).reduce(
      (acc, { amount }) =>
        add(acc, bignumber(amount && !isNaN(Number(amount)) ? amount : '0')),
      bignumber(0)
    );

    return totalAmount.eq(0) ? '' : format(totalAmount, { notation: 'fixed' });
  };

export const distributeAmountAmongEntries = (
  totalAmount: string,
  addresses: string[]
): Record<string, EntryTransactionInfo> => {
  const entriesCount = bignumber(addresses.length || 1);
  const totalAmountBig = bignumber(totalAmount);

  if (totalAmountBig.eq(0)) {
    return Object.fromEntries(
      addresses.map((address) => [address, { amount: '0' }])
    );
  }

  const amountPerEntry = divide(totalAmountBig, entriesCount);
  let remainingAmount = totalAmountBig;

  return addresses.reduce(
    (acc, address, index) => {
      let amount;

      if (index === addresses.length - 1) {
        amount = remainingAmount;
      } else {
        amount = bignumber(format(amountPerEntry, { notation: 'fixed' }));
        remainingAmount = subtract(remainingAmount, amount);
      }

      acc[address] = {
        amount: format(amount, { notation: 'fixed' }),
      };
      return acc;
    },
    {} as Record<string, EntryTransactionInfo>
  );
};
