export const cosmosBaseToMainCurrency = (value: string) => {
  if (value === '0') return 0;

  const divided = +value / 1_000_000;

  return divided;
};

export const formatUSD = (
  value: string | number,
  options?: Intl.NumberFormatOptions
) => {
  const num = Number(value);

  if (num <= 0.01 && num > 0) {
    return `< $0.01`;
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    ...options,
  }).format(num);
};

export const convertTokensToUsd = ({
  price,
  value = 0,
}: {
  price?: string;
  value?: string | number;
}) => {
  const convertedValue = price ? +value * +price : value;

  return formatUSD(convertedValue);
};
