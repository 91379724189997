import { Link, NavLink } from 'react-router';
import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { Button } from '../button';
import { IconColorParam } from '../../components/types';
import { getAccentGradientText } from '../../styles/mixins';
import hoverTabIcon from '../../assets/sidebar-nav-link/hover.svg';
import activeTabIcon from '../../assets/sidebar-nav-link/active.svg';
import selectedTabIcon from '../../assets/sidebar-nav-link/selected.svg';
import { svgGradientDefinitionId } from '../svg-gradient-definitions/config';

export const Container = styled(Flex)<{ $opened: boolean }>`
  top: 0;
  left: 0;
  height: 100%;
  position: fixed;
  transition: width ease-in-out 0.2s;

  ${({ theme, $opened }) => css`
    width: ${$opened ? '224px' : '56px'};

    z-index: ${theme.zLayerOrder.sidebar};
    box-shadow: ${theme.config.sidebar.boxShadow};
    background: ${theme.config.sidebar.background};
  `}
`;

export const BlurBackground = styled.div`
  inset: 0;
  z-index: -10;
  position: absolute;
  backdrop-filter: blur(58px);
  -webkit-backdrop-filter: blur(58px);
`;

export const LogoLink = styled(Link)`
  overflow: hidden;
  padding: 28px 4px;
  text-align: center;
`;

const sidebarItem = css`
  gap: 8px;
  display: flex;
  align-items: center;
`;

export const TabText = styled.span`
  white-space: nowrap;
`;

export const Tab = styled(NavLink)<{
  $count?: number;
  $iconColorParam?: IconColorParam;
}>`
  ${sidebarItem};
  height: 56px;
  padding: 0 16px;
  font-weight: 600;
  position: relative;

  ${({ theme, $count, $iconColorParam = 'fill' }) => css`
    color: ${theme.config.text.primary.main};

    &:hover {
      svg {
        path {
          ${$iconColorParam === 'fill'
            ? 'fill'
            : 'stroke'}: url(#${svgGradientDefinitionId.DEFAULT});
        }
      }
    }

    &.active:not(:hover, :active) {
      svg {
        path {
          ${$iconColorParam === 'fill'
            ? 'fill'
            : 'stroke'}: url(#${svgGradientDefinitionId.DEFAULT});
        }
      }
    }

    ${$count &&
    css`
      &::before {
        content: '${$count}';
        position: absolute;
        font-family: Inter;
        font-size: 10px;
        line-height: 16px;
        width: 16px;
        height: 16px;
        background-color: ${theme.colors.red.additional.main};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        right: 15px;
        bottom: 15px;
      }
    `}
  `};

  &::after {
    right: 0;
    position: absolute;
  }

  &:hover {
    ${TabText} {
      ${getAccentGradientText()}
    }

    &::after {
      content: url(${hoverTabIcon});
    }
  }

  &:active {
    &::after {
      content: url(${activeTabIcon});
    }
  }

  &.active:not(:hover, :active) {
    ${TabText} {
      ${getAccentGradientText()}
    }

    &::after {
      content: url(${selectedTabIcon});
    }
  }
`;

export const OpenButton = styled(Button)<{ $opened: boolean }>`
  right: 0;
  z-index: 1;
  bottom: 24px;
  padding: 12px;
  position: absolute;
  transform: translateX(50%);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);

  ${({ $opened }) =>
    $opened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const ToggleTheme = styled(Button)`
  ${sidebarItem};
  padding: 0 8px;
  white-space: nowrap;
  justify-content: flex-start;

  svg {
    filter: drop-shadow(0px 4px 8px rgba(4, 19, 1, 0.16));
  }
`;

export const TabItemsContainer = styled(Flex)`
  gap: 24px;
  overflow: hidden;
  padding-bottom: 30px;
  flex-direction: column;
`;

export const ChildrenContainer = styled.div`
  padding: 0 8px;
`;
