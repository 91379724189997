import { Flex } from 'libs/commons/components';

import * as Styles from './styles';
import { OverlappingIconListProps } from './types';

export const OverlappingIconList = <T extends unknown>({
  data,
  renderIcon,
}: OverlappingIconListProps<T>) => (
  <Styles.IconsWrapper>
    {data.map((item, index) => (
      <Flex key={index}>{renderIcon(item)}</Flex>
    ))}
  </Styles.IconsWrapper>
);
