import styled, { css } from 'styled-components';

import { SortTableOrder } from 'libs/core/types';

import { Icon } from '../../icons';
import { ChainType } from '../../../types';
import { HeadersRow } from '../../table/headers-row';
import { getThemeChainBasedColor } from '../../../utils/helpers';
import { gradientBorder, getAccentGradientText } from '../../../styles/mixins';
import { svgGradientDefinitionId } from '../../../components/svg-gradient-definitions/config';

export const SortIcon = styled(Icon.TableSort)`
  ${({ theme }) => css`
    path {
      fill: ${theme.config.text.primary.main};
    }
  `}
`;

export const SortableHeaderButton = styled.button<{
  chainType?: ChainType;
  $order?: SortTableOrder;
}>`
  gap: 4px;
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 12px;

  &:disabled {
    opacity: 0.6;
  }

  ${({ theme, $order, chainType }) => {
    const chainColor = getThemeChainBasedColor({
      chainType,
      colors: theme.config.chainGradient,
    });

    const iconGradientId =
      chainType && chainType in svgGradientDefinitionId
        ? svgGradientDefinitionId[
            chainType as keyof typeof svgGradientDefinitionId
          ]
        : svgGradientDefinitionId.DEFAULT;

    return css`
      ${$order &&
      css`
        ${gradientBorder};

        &::before {
          padding: 2px;
          background: ${chainColor};
        }

        ${HeadersRow.Header} {
          ${getAccentGradientText(chainColor)}
        }

        ${SortIcon} {
          ${$order === 'desc' &&
          css`
            path:nth-child(1) {
              fill: url(#${iconGradientId});
            }
          `}

          ${$order === 'asc' &&
          css`
            path:nth-child(2) {
              fill: url(#${iconGradientId});
            }
          `}
        }

        &:hover:not(:disabled) {
          &::before {
            background: ${chainColor};
          }
        }

        &:active:not(:disabled) {
          &::before {
            background: ${chainColor};
          }

          ${HeadersRow.Header} {
            ${getAccentGradientText(chainColor)}
          }

          ${SortIcon} {
            ${$order === 'desc' &&
            css`
              path:nth-child(1) {
                fill: url(#${iconGradientId});
              }
            `}

            ${$order === 'asc' &&
            css`
              path:nth-child(2) {
                fill: url(#${iconGradientId});
              }
            `}
          }
        }
      `}

      ${!$order &&
      css`
        &:hover:not(:disabled) {
          ${SortIcon} {
            path:nth-child(1) {
              fill: url(#${iconGradientId});
            }
          }

          ${HeadersRow.Header} {
            ${getAccentGradientText(chainColor)}
          }
        }

        &:active:not(:disabled) {
          ${SortIcon} {
            path:nth-child(1) {
              fill: url(#${iconGradientId});
            }
          }

          ${HeadersRow.Header} {
            ${getAccentGradientText(chainColor)}
          }
        }
      `}
    `;
  }}
`;

export const InfoIcon = styled(Icon.Info)`
  path {
    fill: ${({ theme }) => theme.colors.white.primary.main};
    fill-opacity: 0.5;
  }
`;

export const InfoSortableButton = styled(SortableHeaderButton)<{
  $order?: SortTableOrder;
}>`
  ${({ $order, chainType }) => {
    const iconGradientId =
      chainType && chainType in svgGradientDefinitionId
        ? svgGradientDefinitionId[
            chainType as keyof typeof svgGradientDefinitionId
          ]
        : svgGradientDefinitionId.DEFAULT;

    return css`
      &:hover {
        ${InfoIcon} {
          path {
            fill: url(#${iconGradientId});
          }
        }
      }

      &:active {
        ${InfoIcon} {
          path {
            fill: url(#${iconGradientId});
          }
        }
      }

      ${$order &&
      css`
        ${InfoIcon} {
          path {
            fill: url(#${iconGradientId});
          }
        }
      `}
    `;
  }}
`;
