import { ReactNode } from 'react';

import { ChainType, AmountResponse } from 'libs/commons/types';
import { ChainTypeIcon, CurrencyToChainType } from 'libs/commons/types/data';
import {
  CosmosChainRpcUrls,
  CosmosWalletProviderConfig,
} from 'libs/cosmos-core/types/data';

import { Icon } from 'components/icons';

import {
  Preset,
  WalletProvider,
  WalkthroughStep,
  WalkthroughStepOptions,
} from '.';

export const PresetOptionsTitles: Record<Preset, string> = {
  '25': '25%',
  '50': '50%',
  '75': '75%',
  '100': 'Max',
};

interface WalletProviderConfigBaseProps {
  title: string;
  icon: ReactNode;
}

export const WalletProviderConfig: Record<
  WalletProvider,
  WalletProviderConfigBaseProps
> = {
  ...CosmosWalletProviderConfig,
  trezor: {
    title: 'Trezor',
    icon: <Icon.Trezor />,
  },
  walletConnect: {
    title: 'Wallet Connect',
    icon: <Icon.WalletConnect />,
  },
  phantom: {
    title: 'Phantom',
    icon: <Icon.Phantom width={24} height={24} />,
  },
  metamask: {
    title: 'Metamask',
    icon: <Icon.Metamask width={24} height={24} />,
  },
};

export const WalkthroughDataAttributeName = 'data-walkthrough-step' as const;

export const WalkthroughDataAttribute = Object.fromEntries(
  WalkthroughStepOptions.map((option) => [
    option,
    { [WalkthroughDataAttributeName]: option },
  ])
) as Record<
  WalkthroughStep,
  Record<typeof WalkthroughDataAttributeName, WalkthroughStep>
>;

export const ChainRpcUrls: Record<Exclude<ChainType, 'ETHEREUM'>, string> = {
  ...CosmosChainRpcUrls,
  SOLANA: import.meta.env.VITE_REACT_APP_SOLANA_RPC_URL,
};

export const BaseCurrencyIcon: Record<string, ReactNode> = Object.entries(
  CurrencyToChainType
).reduce<Record<string, ReactNode>>((result, [baseCurrency, mainCurrency]) => {
  result[baseCurrency] = ChainTypeIcon[mainCurrency];
  return result;
}, {});

export const ChainToBaseCurrency: Record<
  ChainType,
  AmountResponse['currency']
> = {
  KII: 'ukii',
  LAVA: 'ulava',
  ETHEREUM: 'wei',
  SECRET: 'uscrt',
  COSMOS: 'uatom',
  OSMOSIS: 'uosmo',
  SOLANA: 'lamport',
};
