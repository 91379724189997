import { useMemo } from 'react';

import { AmountResponse } from 'libs/commons/types';
import { formatNumber } from 'libs/commons/utils/helpers';
import { formatUSD } from 'libs/commons/utils/currencies';
import { ChainTypeIcon, CurrencyToChainType } from 'libs/commons/types/data';

import { Icon } from 'components';
import { useCurrencyConverter } from 'hooks';

export const useConvertedCurrencyInfo = (data: AmountResponse) => {
  const {
    isError,
    isFetching,
    data: convertedData,
  } = useCurrencyConverter(data);

  const chainType = CurrencyToChainType[data.currency];

  return useMemo(
    () => ({
      usdValue:
        isError || isFetching ? '-' : formatUSD(convertedData?.valueInUSD),
      tokens:
        isError || isFetching ? (
          <div>
            {formatNumber(data.value)} {data.currency}
          </div>
        ) : (
          <div>
            {convertedData.valueInMainCurrency} {convertedData.mainCurrency}
          </div>
        ),
      currencyInfo: convertedData?.mainCurrency ? (
        <>
          {ChainTypeIcon[chainType] || <Icon.Validator />}{' '}
          <span>{convertedData.mainCurrency}</span>
        </>
      ) : (
        <>
          <Icon.Validator /> <span>{data.currency}</span>
        </>
      ),
    }),
    [isError, isFetching, convertedData, chainType, data.currency, data.value]
  );
};
