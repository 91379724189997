import styled, { css } from 'styled-components';

import { TableViewType } from '../../types';
import { collapsedPlaceholderRowsCount } from '../../config';
import { TableRow as TableRowComponent } from '../../../../components/table-row';

export const TableRow = styled(TableRowComponent)<{
  $index: number;
  $viewType: TableViewType;
}>`
  ${({ theme, $index, $viewType }) => {
    if ($viewType === 'collapsed') {
      if ($index === 0 || $index === collapsedPlaceholderRowsCount - 1) {
        return css`
          opacity: 0.3;
        `;
      } else if ($index === 1 || $index === collapsedPlaceholderRowsCount - 2) {
        return css`
          opacity: 0.2;
          filter: blur(2.5px);
        `;
      } else {
        return css`
          opacity: 0.1;
          filter: blur(3.5px);
        `;
      }
    } else {
      if ($index === 0) {
        return css`
          opacity: 0.3;
          background: ${theme.config.table.placeholder.row.background};
        `;
      } else if ($index === 1) {
        return css`
          opacity: 0.2;
          filter: blur(2px);
          background: ${theme.config.table.placeholder.row.background};
        `;
      } else {
        return css`
          opacity: 0.1;
          filter: blur(5px);
          background: ${theme.config.table.placeholder.row.background};
        `;
      }
    }
  }}
`;

export const PlaceholderItem = styled.div`
  height: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.config.table.placeholder.item};
`;
