import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChainType } from 'libs/commons/types';
import {
  CosmosChainType,
  CosmosChainTypeOptions,
} from 'libs/cosmos-core/types';

import { RootState } from 'store';
import { CommonWalletDataResponse } from 'types';

const selectedWalletsLimit: Record<ChainType, number> = {
  SOLANA: 1,
  ETHEREUM: 3,
  ...CosmosChainTypeOptions.reduce(
    (acc, chainType) => ({
      ...acc,
      [chainType]: 1,
    }),
    {} as Record<CosmosChainType, number>
  ),
};

interface State {
  wallets: Record<string, CommonWalletDataResponse>;
  count: {
    [chainType in ChainType]: number;
  };
}

const initialState: State = {
  wallets: {},
  count: {
    SOLANA: 0,
    ETHEREUM: 0,
    ...CosmosChainTypeOptions.reduce(
      (acc, chainType) => ({
        ...acc,
        [chainType]: 0,
      }),
      {} as Record<CosmosChainType, number>
    ),
  },
};

export const mobileDeviceSelectedWalletsSlice = createSlice({
  initialState,
  name: 'mobileDeviceSelectedWallets',
  reducers: {
    resetMobileDeviceSelectedWallets: () => initialState,
    setMobileDeviceSelectedWallets: (
      state,
      { payload }: PayloadAction<CommonWalletDataResponse[]>
    ) => {
      payload.forEach((wallet) => {
        if (wallet.chainType) {
          const isUnderLimit =
            state.count[wallet.chainType] <
            selectedWalletsLimit[wallet.chainType];

          if (isUnderLimit) {
            state.wallets[wallet.id] = wallet;
            state.count[wallet.chainType]++;
          }
        }
      });
    },
    addOrRemoveMobileDeviceSelectedWallet: (
      state,
      { payload }: PayloadAction<CommonWalletDataResponse>
    ) => {
      if (payload.chainType) {
        if (state.wallets[payload.id]) {
          state.wallets = Object.fromEntries(
            Object.entries(state.wallets).filter(
              ([id]) => String(payload.id) !== id
            )
          );
          state.count[payload.chainType]--;
        } else {
          const isUnderLimit =
            state.count[payload.chainType] <
            selectedWalletsLimit[payload.chainType];

          if (isUnderLimit) {
            state.wallets[payload.id] = payload;
            state.count[payload.chainType]++;
          } else {
            const walletsEntries = Object.entries(state.wallets);
            const matchingIndex = walletsEntries.findIndex(
              ([, { chainType }]) => chainType === payload.chainType
            );
            walletsEntries.splice(matchingIndex, 1);
            state.wallets = Object.fromEntries(walletsEntries);

            state.wallets[payload.id] = payload;
          }
        }
      }
    },
  },
});

export const {
  actions: {
    setMobileDeviceSelectedWallets,
    resetMobileDeviceSelectedWallets,
    addOrRemoveMobileDeviceSelectedWallet,
  },
} = mobileDeviceSelectedWalletsSlice;

export const selectMobileDeviceSelectedWallets = (store: RootState) =>
  store.mobileDeviceSelectedWallets.wallets;
