import { AutoSizer, ListProps } from 'react-virtualized';

import { Flex, DataFetchWrapper } from 'libs/commons/components';
import { generateTemplate } from 'libs/commons/components/table/helpers';
import { mapSortableHeaders } from 'libs/commons/components/sortable-table';
import { PlaceholderTableRow } from 'libs/commons/components/table/table-data-fetch-wrapper/placeholder-table-row';

import * as Styles from './styles';
import { VirtualizedListProps } from './types';

export const VirtualizedList = <T extends any = any>({
  data,
  headers,
  refetch,
  isError,
  sortState,
  chainType,
  emptyTitle,
  isFetching,
  rowGap = 8,
  rowRenderer,
  handleSorting,
  rowHeight = 64,
  sortingDisabled,
  placeholderRowsCount = 9,
  template = generateTemplate(headers.length),
}: VirtualizedListProps<T>) => {
  const resultRowRenderer: ListProps['rowRenderer'] = ({
    key,
    index,
    style,
  }) => {
    const currentItem = data?.[index];

    if (!currentItem) {
      return (
        <PlaceholderTableRow
          key={key}
          style={style}
          index={index}
          columnsCount={headers.length}
        />
      );
    }

    return (
      <Styles.TableRowWrapper key={key} gap={rowGap} style={style}>
        {rowRenderer(currentItem)}
      </Styles.TableRowWrapper>
    );
  };

  return (
    <Flex column flex={1}>
      <Styles.HeadersRow
        template={template}
        headers={mapSortableHeaders(
          headers,
          sortState,
          handleSorting,
          sortingDisabled,
          chainType
        )}
      />

      <Flex flex={1}>
        <DataFetchWrapper
          isError={isError}
          refetch={refetch}
          isFetching={isFetching}
          emptyTitle={emptyTitle}
          hasData={Boolean(data && data.length > 0)}
          placeholderElement={Array.from({
            length: placeholderRowsCount,
          }).map((_, index) => (
            <PlaceholderTableRow
              key={index}
              index={index}
              columnsCount={headers.length}
            />
          ))}
        >
          <AutoSizer>
            {({ width, height }) => (
              <Styles.VirtualizedList
                width={width}
                height={height}
                rowCount={data?.length ?? 0}
                rowHeight={rowHeight + rowGap}
                rowRenderer={resultRowRenderer}
              />
            )}
          </AutoSizer>
        </DataFetchWrapper>
      </Flex>
    </Flex>
  );
};
