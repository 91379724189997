import { useCallback } from 'react';

import { CosmosChainType } from 'libs/cosmos-core/types';
import { findValidatorByAddress } from 'libs/cosmos-core/utils/helpers';

import { useGetCosmosValidatorsQuery } from 'store/api/cosmos-validators';

export const useGetCosmosValidatorData = (
  chainType: CosmosChainType | undefined
) => {
  const { data: validators } = useGetCosmosValidatorsQuery(
    {
      chainType: chainType!,
    },
    { skip: !chainType }
  );

  return useCallback(
    (address: string) => findValidatorByAddress(address, validators?.data),
    [validators?.data]
  );
};
