import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ChainType } from 'libs/commons/types';
import { Flex, Caption, Semibold } from 'libs/commons/components/styles';

const line = css`
  position: absolute;
  content: '';
  height: 1px;
`;

export const BlockDataTitle = styled(Caption)`
  color: ${({ theme }) => theme.colors.grey.primary.main};
`;

const dataContainer = css<
  HTMLAttributes<HTMLDivElement> & { $chainType?: ChainType }
>`
  flex: 1;
  display: flex;
  position: relative;
  margin-bottom: 33px;
  padding-bottom: 16px;
  align-items: flex-end;

  ${({ theme, children, $chainType }) => css`
    &::before {
      background: ${children && $chainType
        ? theme.config.chainTypeWithLine.line[$chainType]
        : theme.config.chainTypeWithLine.line.placeholder};
    }
  `}

  &::before {
    ${line};
    top: 94px;
    width: 100%;
  }
`;

export const DataTextContainer = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  text-align: center;
  max-width: 60px;
`;

export const LeftDataContainer = styled.div<{ $chainType?: ChainType }>`
  ${dataContainer};
  justify-content: flex-end;

  ${DataTextContainer} {
    margin-right: 16px;
  }
`;

export const RightDataContainer = styled.div<{ $chainType?: ChainType }>`
  ${dataContainer}

  &::before {
    transform: rotate(180deg);
  }

  ${DataTextContainer} {
    margin-left: 16px;
  }
`;

export const BlockItemContainer = styled(Flex)`
  min-width: 360px;
  position: relative;
`;

export const ChainIconContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 48px;
  top: 71px;
  right: -20px;
  overflow: visible;

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const EpochContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

export const BlockIconContainer = styled.div`
  position: relative;
  width: 120px;
  height: 128px;
  line-height: 10px;
  overflow: visible;

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const BlocksContainer = styled(Flex)`
  overflow-x: hidden;
  overflow-y: visible;
  width: 100%;
`;

export const BlocksInner = styled(Flex)`
  margin: 0 auto 8px;
  position: relative;
  height: 138px; // used to hide vertical scroll bar
`;

export const Error = styled(Semibold)`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.red.primary.main};
`;
