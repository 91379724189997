import { URL } from '../../api/constants';

export const tagTypes = ['cosmos-wallets'];

export const endpoints = {
  getCosmosWallets: {
    providesTags: tagTypes,
    query: () => ({
      method: 'get',
      url: URL.WALLETS,
    }),
  },
  saveCosmosWallet: {
    invalidatesTags: tagTypes,
    query: (address: string) => ({
      method: 'post',
      url: URL.WALLETS,
      data: { address },
    }),
  },
};
