import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { gradientBorder } from '../../styles/mixins';
import { BaseStyledTableRow } from '../table-row/styles';
import { Pagination as PaginationComponent } from '../pagination';

import { TableViewType } from './types';

export const Container = styled(Flex)`
  height: 100%;
  flex-direction: column;
  gap: 16px;
`;

export const ItemsContainer = styled(Flex)<{
  $hasChildren: boolean;
  $viewType: TableViewType;
}>`
  flex-direction: column;
  height: inherit;

  ${({ theme, $viewType }) => css`
    ${$viewType === 'collapsed' &&
    css`
      padding: 24px;
      border-radius: 36px;
      background: ${theme.config.tableRow.background};
      box-shadow: ${theme.config.table.collapse.boxShadow};
      backdrop-filter: ${theme.config.tableRow.backdropFilter};
      -webkit-backdrop-filter: ${theme.config.tableRow.backdropFilter};
    `}
  `}
`;

export const ChildrenWrapper = styled(Flex)<{
  $viewType: TableViewType;
}>`
  flex: 1;
  overflow: auto;
  position: relative;

  ${({ $viewType }) => css`
    ${$viewType === 'tree' &&
    css`
      padding-left: 24px;
    `}
  `}
`;

export const ChildrenContainer = styled(Flex)<{
  $itemsGap: number;
  $maxHeight?: number;
  $hasChildren: boolean;
  $viewType: TableViewType;
}>`
  flex: 1;
  position: relative;
  flex-direction: column;
  min-width: 0;

  ${({ theme, $viewType, $itemsGap, $maxHeight, $hasChildren }) => css`
    gap: ${$itemsGap}px;

    ${$maxHeight &&
    css`
      max-height: ${$maxHeight}px;
    `}

    ${$viewType === 'collapsed' &&
    css`
      ${BaseStyledTableRow} {
        padding: unset;
        box-shadow: unset;
        background: unset;
        backdrop-filter: unset;
        -webkit-backdrop-filter: unset;

        &:not(:last-child) {
          position: relative;
          padding-bottom: 16px;

          &::after {
            bottom: 0;
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            border-radius: 2px;
            background: ${theme.config.table.collapse.divider};
          }
        }
      }
    `}

    ${$hasChildren &&
    css`
      ${$viewType !== 'collapsed' &&
      css`
        padding-right: 10px;
      `}

      ${$viewType === 'default' &&
      css`
        padding-left: 10px;
      `}
    `}
  `}
`;

export const TreeLine = styled.div<{ $height?: number }>`
  ${({ $height }) =>
    $height &&
    css`
      ${gradientBorder};
      left: 0;
      width: 48px;
      position: absolute;
      height: ${$height}px;

      &::before {
        padding: 0 0 1px 1px;
        border-radius: 0 0 100px 100px;
      }
    `}
`;

export const TreeBranch = styled.span<{ $position: number }>`
  width: 100%;
  height: 1px;
  opacity: 0.4;
  position: absolute;

  ${({ theme, $position }) => css`
    top: ${$position}px;
    background: ${theme.colors.accent.primary.main};
  `}
`;

export const Pagination = styled(PaginationComponent)`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.primary.active};
`;
