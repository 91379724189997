import styled from 'styled-components';

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  :not(:first-child) {
    margin-left: -12px;
  }
`;
