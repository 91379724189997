import {
  CosmosChainType,
  CosmosWalletProviderOptions,
} from 'libs/cosmos-core/types';
import {
  Currency,
  ChainType,
  CommonWalletDataResponse as BaseCommonWalletDataResponse,
} from 'libs/commons/types';

export const PresetOptions = ['25', '50', '75', '100'] as const;
export type Preset = (typeof PresetOptions)[number];

export enum MetamaskChainId {
  ETHEREUM = '0x1',
}

export type RewardStatusType =
  | 'PROPOSAL'
  | 'ATTESTATION'
  | 'PRIORITY_FEE'
  | 'SYNC_COMMITTEE';

export interface CurrencyRequest {
  quoteCurrency: Currency;
}

export enum Web3WalletId {
  Metamask = 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  Trust = '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  Exodus = 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
  LedgerLive = '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
}

export const WalletProviderOptions = [
  ...CosmosWalletProviderOptions,
  'trezor',
  'phantom',
  'metamask',
  'walletConnect',
] as const;

export type WalletProvider = (typeof WalletProviderOptions)[number];

export const WalkthroughStepOptions = [
  'header',
  'portfolio',
  'rewardActivity',
  'rewardLog',
] as const;
export type WalkthroughStep = (typeof WalkthroughStepOptions)[number];

export interface CosmosRestUrlAccount {
  address: string;
  sequence: string;
  account_number: string;
  pub_key: {
    key: string;
    '@type': string;
  };
}

export interface CommonWalletDataResponse extends BaseCommonWalletDataResponse {
  chainType?: ChainType;
}

export type TransactionStatus =
  | 'signing'
  | 'success'
  | 'simulating-gas'
  | 'connecting-to-chain';

export interface LimitRequest {
  limit?: number;
}

export type DelegableChainType = 'SOLANA' | CosmosChainType;

export type ValidatorStatus =
  | 'EXITED'
  | 'PENDING'
  | 'SLASHED'
  | 'DEPOSITED'
  | 'ACTIVE_ONLINE'
  | 'ACTIVE_OFFLINE'
  | 'EXITING_ONLINE'
  | 'EXITING_OFFLINE'
  | 'SLASHING_ONLINE'
  | 'SLASHING_OFFLINE'
  | 'DEPOSITED_INVALID';
