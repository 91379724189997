import styled from 'styled-components';

import { caption } from 'libs/commons/styles';
import { LightGrayOpacity } from 'libs/commons/components';

export const AppVersionLabel = styled(LightGrayOpacity)`
  position: fixed;
  right: 10px;
  bottom: 5px;
  ${caption};
`;
