import * as Sentry from '@sentry/react';
import { Component, PropsWithChildren } from 'react';

import { EllipsisText } from '../ellipsis-text';
import { handleError } from '../../utils/error';
import { Button } from '../../components/button';
import { BaseAuthPage } from '../../components/auth-page';
import { clearCacheAndReload } from '../../utils/helpers';
import { getErrorMessage } from '../../../core/utils/error';

import * as Styles from './styles';

export class ErrorBoundary extends Component<
  PropsWithChildren,
  { error: string | null; dynamicImportError?: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    const errorMessage = getErrorMessage(error);

    if (
      errorMessage.startsWith('Failed to fetch dynamically imported module')
    ) {
      return {
        error: errorMessage,
        dynamicImportError: true,
      };
    }

    return { error: errorMessage };
  }

  render() {
    if (this.state.error) {
      return (
        <BaseAuthPage
          title={
            this.state.dynamicImportError
              ? 'New version of the app is available'
              : 'Something went wrong'
          }
        >
          <Styles.ErrorMessageWrapper>
            {this.state.error && !this.state.dynamicImportError && (
              <EllipsisText>{this.state.error}</EllipsisText>
            )}
            <Button
              text={
                this.state.dynamicImportError
                  ? 'Get new version'
                  : 'Clear cache'
              }
              onClick={async () => {
                try {
                  this.setState({ error: null });

                  clearCacheAndReload();
                } catch (error) {
                  handleError(error);
                }
              }}
            />
          </Styles.ErrorMessageWrapper>
        </BaseAuthPage>
      );
    }

    return this.props.children;
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }
}
