import { SortRequest, PaginationRequest } from '../types';

import { filterEmptyFields } from './object';

export const addParamsToUrl = (
  url: string,
  params?: Record<string, any>
): string => {
  if (!params) return url;
  const urlParams = new URLSearchParams();
  Object.entries(filterEmptyFields(params)).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => urlParams.append(key, item));
    } else {
      urlParams.append(key, value);
    }
  });
  return `${url}?${urlParams.toString()}`;
};

export const getSortParams = (sort?: SortRequest['sort']) => {
  if (!sort) return {};

  return {
    sort: Object.entries(sort).map(
      ([accessor, order]) => `${accessor},${order}`
    ),
  };
};

export const getPageParams = (page?: PaginationRequest['page']) => {
  if (!page)
    return {
      page: 0,
    };

  if (+page > 0) {
    return { page: +page - 1 };
  }

  return { page: +page };
};
