import { Navigate } from 'react-router';
import { PropsWithChildren, Suspense as ReactSuspense } from 'react';

import { Loader } from '../loader';

interface ProtectedRouteProps {
  allowed: boolean;
  redirect: string;
}

export const ProtectedRoute = ({
  allowed,
  redirect,
  children,
}: PropsWithChildren<ProtectedRouteProps>) => {
  if (!allowed) {
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
};

export const Suspense = ({ children }: PropsWithChildren) => (
  <ReactSuspense fallback={<Loader isLoading />}>{children}</ReactSuspense>
);
