import styled, { css } from 'styled-components';

import { getAccentGradientText } from '../../styles/mixins';
import { getThemeChainBasedColor } from '../../utils/helpers';

import { GradientHeaderProps } from '.';

export const Header = styled.h3``;

export const Text = styled.span<Pick<GradientHeaderProps, 'chainType'>>`
  ${({ theme, chainType }) => {
    const gradientColors = theme.config.chainGradient;

    const gradientColor = getThemeChainBasedColor({
      chainType,
      colors: gradientColors,
    });

    return css`
      ${getAccentGradientText(gradientColor)};

      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      text-transform: uppercase;
    `;
  }};
`;
