import { generateWalletSignInTokens } from 'libs/cosmos-core/utils/wallet-sign-in';

import { saveAuthTypeToLocalStorage } from 'store/slices/auth/helpers';

export const generateAndSaveWalletSignInTokens = async (params: {
  walletAddress: string;
  publicKey: string | null;
  onMessageSign: (messageToSign: string) => Promise<string | undefined>;
}) => {
  const { publicKey, walletAddress, onMessageSign } = params;

  if (walletAddress) {
    const authUserSub = await generateWalletSignInTokens({
      publicKey,
      onMessageSign,
      walletAddress,
    });

    saveAuthTypeToLocalStorage('wallet');

    return authUserSub;
  }
};
