import { Icon } from 'components/icons';

import { getDefUrl } from '../helpers';
import { IncomeChartDataItem } from '../types';

import { EventsIconsScatterShapeProps } from './types';

const DropShadowRect = ({
  cy,
  cx,
  defId,
}: {
  cy: number;
  cx: number;
  defId: string;
}) => (
  <rect
    y={cy}
    width={4}
    x={cx - 2}
    height="100%"
    fillOpacity="0.18"
    fill={getDefUrl(defId)}
  />
);

export const EventsIconsScatterShape = ({
  x,
  y,
  width,
  cx = 0,
  cy = 0,
  ...props
}: EventsIconsScatterShapeProps) => {
  const payload = props.payload as IncomeChartDataItem;

  const hasCompoundEvent = payload.compound && +payload.compound.value > 0;
  const hasRedelegationEvent =
    payload.redelegations && payload.redelegations.length > 0;
  const hasWithdrawalEvent = payload.withdrawal && +payload.withdrawal > 0;

  const iconSize = 24;

  const events = [];

  if (hasCompoundEvent) {
    events.push({
      dropShadow: 'compound-drop-shadow',
      icon: Icon.BalanceHistoryChartCompound,
    });
  }

  if (hasRedelegationEvent) {
    events.push({
      dropShadow: 'redelegation-drop-shadow',
      icon: Icon.BalanceHistoryChartRedelegation,
    });
  }

  return (
    <>
      {events.map(({ dropShadow, icon: EventIcon }, index) => (
        <EventIcon
          key={dropShadow}
          width={iconSize}
          height={iconSize}
          x={cx - iconSize / 2}
          y={cy - iconSize - index * iconSize}
        />
      ))}

      {events.length > 0 && (
        <DropShadowRect cy={cy} cx={cx} defId={events[0].dropShadow} />
      )}

      {hasWithdrawalEvent && (
        <>
          <circle r={4} cx={cx} cy={cy} fill="#ECCD2B" />
          {!hasCompoundEvent && !hasRedelegationEvent && (
            <DropShadowRect cy={cy} cx={cx} defId="withdrawal-drop-shadow" />
          )}
        </>
      )}
    </>
  );
};
