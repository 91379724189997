import {
  OfflineSigner,
  OfflineDirectSigner,
} from '@cosmjs/proto-signing/build/signer';

import {
  SortRequest,
  PaginationRequest,
  PaginationResponse,
} from '../../core/types';

export const CosmosWalletProviderOptions = [
  'leap',
  'keplr',
  'cosmostation',
] as const;
export type CosmosWalletProvider = (typeof CosmosWalletProviderOptions)[number];

export interface CommonValidatorsRequestParams
  extends Partial<PaginationRequest & SortRequest> {
  name?: string;
  preferencesSort?: string;
}

export interface LavaProviderRewardChainDetailsResponse {
  logoUrl: string;
  chainName: string;
  baseCurrency: string;
}

export interface BaseAmountResponse<Currency extends string = string> {
  value: string;
  currency: Currency;
}

export interface LavaProviderRewardDetailsResponse {
  rewardDetails: {
    chainDetails: LavaProviderRewardChainDetailsResponse;
    reward: {
      amount: BaseAmountResponse;
      amountInUsd: BaseAmountResponse<'usd'>;
    };
  }[];
}

export interface LavaProviderData extends LavaProviderRewardDetailsResponse {
  name: string;
  address: string;
  vaultAddress: string;
  providerImageUrl: string;
  supportedChains: string[];
  commissionInPercentage: number;
  estimatedAnnualPercentageRate: number;
  totalDelegation: BaseAmountResponse<'ulava'>;
}

export interface CommonValidatorDataResponse {
  name: string;
  status?: string;
  address: string;
  imageUrl: string;
  statusReason?: string;
  validatorCommissionRate: number;
}

export interface CosmosValidatorData extends CommonValidatorDataResponse {
  votingPower: string;
  scoringRate: string;
  totalStakedTokens: string;
  networkAnnualPercentageRate: number;
  validatorAnnualPercentageRate: number;
}

export interface GetCosmosValidatorsResponse extends PaginationResponse {
  data: CosmosValidatorData[];
}

export interface Request {
  method: string;
  params?: object;
}

export interface Cosmos {
  off: (event: Event) => void;
  request: (request: Request) => Promise<any>;
  on: (type: string, listener: EventListenerOrEventListenerObject) => Event;
}

export enum ChainId {
  LAVA = 'lava-mainnet-1',
  COSMOS = 'cosmoshub-4',
  SECRET = 'secret-4',
  OSMOSIS = 'osmosis-1',
  KII = 'kiichain3',
}

export interface PubKey {
  readonly type: string;
  readonly value: string;
}

export interface StdSignature {
  readonly pub_key: PubKey;
  readonly signature: string;
}

export interface CosmosWindowProvider {
  enable(chainIds: string | string[]): Promise<void>;
  ethereum?: {
    isLeap?: boolean;
    isKeplr?: boolean;
  };
  getOfflineSignerAuto: (
    chainId: string
  ) => Promise<OfflineSigner | OfflineDirectSigner>;
  signArbitrary(
    chainId: string,
    signer: string,
    data: string | Uint8Array
  ): Promise<StdSignature>;
}

export interface Cosmostation {
  cosmos: Cosmos;
  providers: {
    keplr: CosmosWindowProvider;
  };
}

export interface CosmosTransactionMessage {
  value: any;
  typeUrl: string;
}

export enum ChainAverageGasPrice {
  COSMOS = 0.03,
  SECRET = 0.25,
  LAVA = 0.05,
  OSMOSIS = 0.025,
  KII = 0.3,
}

export enum ChainDenom {
  COSMOS = 'uatom',
  SECRET = 'uscrt',
  LAVA = 'ulava',
  OSMOSIS = 'uosmo',
  KII = 'ukii',
}

export * from '../../core/types';
