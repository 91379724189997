import * as Styles from './styles';
import { PageProps } from './types';

export const Page = ({
  style,
  hasLogo,
  children,
  hasImage,
  className,
  showBackground = true,
  childrenContainerStyle,
}: PageProps) => {
  return (
    <Styles.Component style={style} className={className}>
      {showBackground && <Styles.Background hasImage={hasImage} />}

      <Styles.Content>
        <Styles.ChildrenContainer style={childrenContainerStyle}>
          {hasLogo ? (
            <>
              <Styles.Logo />

              <div>{children}</div>
            </>
          ) : (
            children
          )}
        </Styles.ChildrenContainer>
      </Styles.Content>
    </Styles.Component>
  );
};
