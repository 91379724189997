import styled from 'styled-components';

import { Button } from 'libs/commons/components';

export const AddButton = styled(Button)`
  padding: 12px 24px;
  width: 104px;
  margin-left: auto;

  &::before {
    padding: 2px;
  }
`;
