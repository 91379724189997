import { determineCosmosChainFromAddress } from 'libs/cosmos-core/utils/helpers';

import { useGetCosmosValidatorData } from 'hooks';
import { ValidatorInfo } from 'components/validator-info';
import { CosmosValidatorImage } from 'components/cosmos-validator-image';

import { CosmosValidatorInfoProps } from './types';

export const CosmosValidatorInfo = ({
  address,
  iconSize,
  ...props
}: CosmosValidatorInfoProps) => {
  const getValidatorData = useGetCosmosValidatorData(
    determineCosmosChainFromAddress(address)
  );

  const validatorData = getValidatorData(address);

  return (
    <ValidatorInfo
      name={validatorData?.name || address}
      icon={
        <CosmosValidatorImage
          rounded
          width={iconSize}
          height={iconSize}
          address={address}
        />
      }
      {...props}
      {...validatorData}
    />
  );
};
