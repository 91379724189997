import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'libs/cosmos-core/store/query';
import { cosmosWalletsApiConfig } from 'libs/cosmos-core/store/api';
import { determineCosmosChainFromAddress } from 'libs/cosmos-core/utils/helpers';

import { URL } from 'api/constants';

import { CosmosWallet, GetCosmosWalletsResponse } from './types';

export const cosmosWalletsApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosWalletsApi',
  tagTypes: cosmosWalletsApiConfig.tagTypes,
  endpoints: (build) => ({
    saveCosmosWallet: build.mutation<void, string>(
      cosmosWalletsApiConfig.endpoints.saveCosmosWallet
    ),
    deleteCosmosCustomWallet: build.mutation<void, number>({
      invalidatesTags: cosmosWalletsApiConfig.tagTypes,
      query: (walletId) => ({
        method: 'delete',
        url: URL.READONLY_WALLET.replace('{walletId}', String(walletId)),
      }),
    }),
    saveCustomCosmosWallet: build.mutation<void, string>({
      invalidatesTags: cosmosWalletsApiConfig.tagTypes,
      query: (address) => ({
        method: 'post',
        url: URL.READONLY_WALLETS,
        data: {
          address,
        },
      }),
    }),
    getCosmosWallets: build.query<CosmosWallet[], void>({
      ...cosmosWalletsApiConfig.endpoints.getCosmosWallets,
      transformResponse: (response: GetCosmosWalletsResponse) =>
        response?.map((item) => ({
          ...item,
          wallet: {
            ...item.wallet,
            chainType: determineCosmosChainFromAddress(item.wallet.address),
          },
        })),
    }),
  }),
});

export const {
  useGetCosmosWalletsQuery,
  useSaveCosmosWalletMutation,
  useSaveCustomCosmosWalletMutation,
  useDeleteCosmosCustomWalletMutation,
} = cosmosWalletsApi;

export const useCosmosReadonlyWallets = () =>
  useGetCosmosWalletsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter(({ wallet }) => wallet.isReadOnly),
    }),
  });

export type { CosmosWallet };
