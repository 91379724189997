import * as math from 'mathjs';
import { Coin, StdFee } from 'cosmos-js-telescope';
import { coin as _coin, SigningStargateClient } from '@cosmjs/stargate';

import {
  ChainDenom,
  CosmosChainType,
  CosmosWalletProvider,
  ChainAverageGasPrice,
  CosmosTransactionMessage,
} from '../types';

export const uint8ArrayToBase64 = (uint8Array: Uint8Array): string => {
  return btoa(String.fromCharCode(...uint8Array));
};

export const getCosmosWindowProvider = (provider: CosmosWalletProvider) => {
  if (provider === 'cosmostation') {
    return window?.cosmostation?.providers?.keplr;
  }

  if (provider === 'keplr' && window?.keplr?.ethereum?.isKeplr) {
    return window?.keplr;
  }

  if (provider === 'leap' && window?.leap?.ethereum?.isLeap) {
    return window?.leap;
  }

  return null;
};

export const getCosmosWalletAddress = async ({
  chainId,
  providerName,
}: {
  chainId: string;
  providerName: CosmosWalletProvider;
}) => {
  const windowProvider = getCosmosWindowProvider(providerName);

  await windowProvider?.enable(chainId);
  const signerProvider = await windowProvider?.getOfflineSignerAuto(chainId);
  const accounts = await signerProvider?.getAccounts();
  const currentAccount = accounts?.[0];
  const walletAddress = currentAccount?.address ?? null;

  const publicKey = currentAccount?.pubkey;
  const publicKeyBase64 = publicKey ? uint8ArrayToBase64(publicKey) : null;

  return {
    walletAddress,
    publicKeyBase64,
  };
};

export const findValidatorByAddress = <TData extends { address: string }>(
  address: string,
  data?: TData[]
) => data?.find((item) => item.address === address);

export const determineCosmosChainFromAddress = (
  address: string
): CosmosChainType | undefined => {
  if (address.startsWith('secret')) return 'SECRET';

  if (address.startsWith('cosmos')) return 'COSMOS';

  if (address.startsWith('lava')) return 'LAVA';

  if (address.startsWith('osmo')) return 'OSMOSIS';

  if (address.startsWith('kii')) return 'KII';
};

export const cosmosMainToBaseCurrency = (unit: number | string) =>
  math
    .floor(
      math.bignumber(
        math.multiply(math.bignumber(unit), math.bignumber(1e6)).toString()
      )
    )
    .toNumber();

export const cosmosMainCurrencyToCoin = (
  amountInMainCurrency: string,
  address: string
): Coin => {
  const chain = determineCosmosChainFromAddress(address);

  if (!chain) {
    throw new Error('Chain type not found');
  }

  return {
    denom: ChainDenom[chain],
    amount: cosmosMainToBaseCurrency(amountInMainCurrency).toString(),
  };
};

export const coin = (amount: math.BigNumber, denom: string) =>
  _coin(math.format(math.floor(amount), { notation: 'fixed' }), denom);

export const formatGasFeeObject = (
  gasLimit: number,
  chainType: CosmosChainType
): StdFee => {
  const gasPriceAmount = ChainAverageGasPrice[chainType];

  const amount = math.ceil(
    math.bignumber(
      math.multiply(
        math.bignumber(gasPriceAmount.toString()).toNumber(),
        math.bignumber(gasLimit.toString()).toNumber()
      )
    )
  );

  return {
    gas: gasLimit.toString(),
    amount: [coin(amount, ChainDenom[chainType])],
  };
};

export const simulateTx = async ({
  msgs,
  address,
  signingClient,
}: {
  address: string;
  msgs: CosmosTransactionMessage[];
  signingClient: SigningStargateClient;
}): Promise<StdFee> => {
  const chainType = determineCosmosChainFromAddress(address);

  if (!chainType) {
    throw new Error('Chain type not found');
  }

  const fee = await signingClient.simulate(address, msgs, '');

  const buffer = 1.5;

  const gasLimit = Math.ceil(fee * buffer);

  return formatGasFeeObject(gasLimit, chainType);
};

export const calculateSuggestedDelegationAmount = (balance: number) => {
  const balanceBigNum = math.bignumber(balance);
  const feePercentage = math.bignumber(0.01);
  const feeAmount = math.multiply(balanceBigNum, feePercentage);
  const maxPossibleAmount = math.subtract(balanceBigNum, feeAmount);

  return math.format(maxPossibleAmount, { notation: 'fixed' });
};
