import styled, { css } from 'styled-components';
import { useState, DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import loaderIcon from 'libs/core/assets/icons/loader.svg';
import validatorIcon from 'libs/cosmos-core/assets/validator.svg';

const Image = styled.img<{ $rounded: boolean; $isLoaded: boolean }>`
  ${({ $rounded, $isLoaded }) => css`
    ${$rounded &&
    css`
      border-radius: 50%;
    `}

    ${!$isLoaded &&
    css`
      background-image: url(${loaderIcon});
      background-size: cover;
    `}
  `}
`;

export interface BaseValidatorImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  width?: number;
  height?: number;
  rounded?: boolean;
  placeholderSrc?: string;
}

export const BaseValidatorImage = ({
  src,
  width = 24,
  height = 24,
  rounded = false,
  placeholderSrc = validatorIcon,
  ...props
}: BaseValidatorImageProps) => {
  const [isLoaded, setLoaded] = useState(false);

  const resultSrc = src || placeholderSrc;

  return (
    <Image
      {...props}
      width={width}
      loading="lazy"
      src={resultSrc}
      height={height}
      $rounded={rounded}
      $isLoaded={isLoaded}
      onLoad={() => setLoaded(true)}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = placeholderSrc;
      }}
    />
  );
};
