import styled from 'styled-components';

import { DrawerContainer } from 'libs/commons/components/drawer-container';

export const DrawerInner = styled(DrawerContainer.Inner)`
  background: ${({ theme }) => theme.config.delegateSection.background.main};
`;

export const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;
