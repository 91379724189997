import { createApi } from '@reduxjs/toolkit/query/react';

import { ethereumApiQuery } from 'libs/commons/store/query';

import { URL } from 'api/constants';

import {
  EthereumWallet,
  EthereumValidator,
  EthereumWalletData,
  EthWalletsGetResponse,
  SaveCustomWalletResponse,
} from './types';

const tagTypes = ['wallets'];

export const walletsApi = createApi({
  tagTypes,
  reducerPath: 'walletsApi',
  baseQuery: ethereumApiQuery,
  endpoints: (build) => ({
    save: build.mutation<void, EthereumWalletData>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ETHEREUM_WALLETS,
      }),
    }),
    deleteEthereumCustomWallet: build.mutation<void, number>({
      invalidatesTags: tagTypes,
      query: (walletId) => ({
        method: 'delete',
        url: URL.READONLY_WALLET.replace('{walletId}', String(walletId)),
      }),
    }),
    saveEthereumCustomWallet: build.mutation<
      SaveCustomWalletResponse,
      EthereumWalletData
    >({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.READONLY_WALLETS,
      }),
    }),
    get: build.query<EthWalletsGetResponse, void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.ETHEREUM_WALLETS,
      }),
      transformResponse: (response: EthWalletsGetResponse) =>
        response.map((item) => ({
          ...item,
          wallet: {
            ...item.wallet,
            chainType: 'ETHEREUM',
          },
        })),
    }),
  }),
});

export const {
  useGetQuery,
  useSaveMutation,
  useSaveEthereumCustomWalletMutation,
  useDeleteEthereumCustomWalletMutation,
} = walletsApi;

export const useEthReadonlyWallets = () =>
  useGetQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.filter(({ wallet }) => wallet.isReadOnly),
    }),
  });

export type {
  EthereumWallet,
  EthereumValidator,
  EthereumWalletData,
  EthWalletsGetResponse,
};
