import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { TabsProps } from '../../components/tabs/types';
import { getThemeChainBasedColor } from '../../utils/helpers';

export const TabsList = styled.ul`
  gap: 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.config.table.tabs.border};
  overflow: auto;
`;

export const Tab = styled.li<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 12px 0 16px 0;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const Underline = styled(motion.div)<Pick<TabsProps, 'chainType'>>`
  left: 0;
  right: 0;
  height: 4px;
  bottom: 0;
  position: absolute;
  border-radius: 24px;
  background: ${({ theme, chainType }) =>
    getThemeChainBasedColor({
      chainType,
      colors: theme.config.chainGradient,
    })};
`;
