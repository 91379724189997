import styled, { css } from 'styled-components';

import { Flex } from '../../styles';
import { BaseStyledTableRow } from '../styles';
import { handleTableRowTemplate } from '../../../styles/mixins';

export const TableRowContainer = styled(BaseStyledTableRow)<{
  $gap: number;
  $padding: string;
  $template: string;
}>`
  display: grid;
  align-items: center;

  ${handleTableRowTemplate};

  ${({ $gap, $padding }) => {
    return css`
      grid-gap: ${$gap}px;
      padding: ${$padding};
    `;
  }}
`;

export const ItemContainer = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
