import * as Sentry from '@sentry/react';
import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  createMigrate,
  persistReducer,
} from 'redux-persist';

import { rtkQueryErrorHandler } from 'libs/commons/store/store';

import { authSlice } from './slices/auth';
import { reportsApi } from './api/reports';
import { walletsApi } from './api/wallets';
import { feedbackApi } from './api/feedback';
import { userGrantsApi } from './api/user-grants';
import { chainsDataApi } from './api/chains-data';
import { compoundingApi } from './api/compounding';
import { lavaRestakeApi } from './api/lava-restake';
import { lavaRewardsApi } from './api/lava-rewards';
import { notificationsApi } from './api/notifications';
import { cosmosWalletsApi } from './api/cosmos-wallets';
import { solanaWalletsApi } from './api/solana/wallets';
import { exchangeRatesApi } from './api/exchange-rates';
import { trezorSlice, trezorReducer } from './slices/trezor';
import { walletsSummariseApi } from './api/wallets-summarise';
import { cosmosValidatorsApi } from './api/cosmos-validators';
import { solanaValidatorsApi } from './api/solana/validators';
import { lavaRestakeSpecsApi } from './api/lava-restake-specs';
import { cosmosDelegationsApi } from './api/cosmos-delegations';
import { compoundingConfigApi } from './api/compounding-config';
import { solanaDelegationsApi } from './api/solana/delegations';
import { ethereumValidatorsApi } from './api/ethereum-validators';
import { metamaskSlice, metamaskReducer } from './slices/metamask';
import { redelegateDialogSlice } from './slices/redelegate-dialog';
import { solanaWalletBalanceApi } from './api/solana/wallet-balance';
import { cosmosWalletBalanceApi } from './api/cosmos-wallet-balance';
import { cosmosMultiValidators } from './api/cosmos-multi-validators';
import { cosmosMultiDelegationsApi } from './api/cosmos-multi-delegations';
import { walkthroughSlice, walkthroughReducer } from './slices/walkthrough';
import { lavaContributionTypeSlice } from './slices/lava-contribution-type';
import { ethereumMultiValidatorsApi } from './api/ethereum-multi-validators';
import { cosmosValidatorsHistoryApi } from './api/cosmos-validators-history';
import { maxValidatorsExceededSlice } from './slices/max-validators-exceeded';
import { connectedCosmosChainsSlice } from './slices/connected-cosmos-chains';
import { lavaRewardsCalculatorSlice } from './slices/lava-rewards-calculator';
import { delegationProcessStateSlice } from './slices/delegation-process-state';
import { optimizeSuggestionChainsSlice } from './slices/optimize-suggestion-chains';
import {
  rewardsDrawerSlice,
  rewardsDrawerReducer,
} from './slices/rewards-drawer';
import { mobileDeviceSelectedWalletsSlice } from './slices/mobile-device-selected-wallets';
import { alreadyLinkedWalletAddressesSlice } from './slices/already-linked-wallets-addresses';
import { solanaDelegationsBalanceHistoryApi } from './api/solana/delegations-balance-history';
import { solanaStakeAccountBalanceHistoryApi } from './api/solana/stake-account-balance-history';
import {
  transactionLoaderSlice,
  transactionLoaderReducer,
} from './slices/transaction-loader';

const migrations = {
  2: () => {
    localStorage.clear();
    return {} as any;
  },
};

const persistConfig = {
  storage,
  version: 2,
  key: 'root',
  migrate: createMigrate(migrations),
  whitelist: [
    'auth',
    trezorSlice.name,
    metamaskSlice.name,
    connectedCosmosChainsSlice.name,
    mobileDeviceSelectedWalletsSlice.name,
  ],
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const appReducer = combineReducers({
  [trezorSlice.name]: trezorReducer,
  [authSlice.name]: authSlice.reducer,
  [metamaskSlice.name]: metamaskReducer,
  [walkthroughSlice.name]: walkthroughReducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [walletsApi.reducerPath]: walletsApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [rewardsDrawerSlice.name]: rewardsDrawerReducer,
  [chainsDataApi.reducerPath]: chainsDataApi.reducer,
  [userGrantsApi.reducerPath]: userGrantsApi.reducer,
  [lavaRewardsApi.reducerPath]: lavaRewardsApi.reducer,
  [compoundingApi.reducerPath]: compoundingApi.reducer,
  [lavaRestakeApi.reducerPath]: lavaRestakeApi.reducer,
  [transactionLoaderSlice.name]: transactionLoaderReducer,
  [solanaWalletsApi.reducerPath]: solanaWalletsApi.reducer,
  [exchangeRatesApi.reducerPath]: exchangeRatesApi.reducer,
  [cosmosWalletsApi.reducerPath]: cosmosWalletsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [redelegateDialogSlice.name]: redelegateDialogSlice.reducer,
  [solanaValidatorsApi.reducerPath]: solanaValidatorsApi.reducer,
  [lavaRestakeSpecsApi.reducerPath]: lavaRestakeSpecsApi.reducer,
  [walletsSummariseApi.reducerPath]: walletsSummariseApi.reducer,
  [cosmosValidatorsApi.reducerPath]: cosmosValidatorsApi.reducer,
  [solanaDelegationsApi.reducerPath]: solanaDelegationsApi.reducer,
  [cosmosDelegationsApi.reducerPath]: cosmosDelegationsApi.reducer,
  [compoundingConfigApi.reducerPath]: compoundingConfigApi.reducer,
  [cosmosMultiValidators.reducerPath]: cosmosMultiValidators.reducer,
  [ethereumValidatorsApi.reducerPath]: ethereumValidatorsApi.reducer,
  [lavaContributionTypeSlice.name]: lavaContributionTypeSlice.reducer,
  [solanaWalletBalanceApi.reducerPath]: solanaWalletBalanceApi.reducer,
  [cosmosWalletBalanceApi.reducerPath]: cosmosWalletBalanceApi.reducer,
  [maxValidatorsExceededSlice.name]: maxValidatorsExceededSlice.reducer,
  [lavaRewardsCalculatorSlice.name]: lavaRewardsCalculatorSlice.reducer,
  [connectedCosmosChainsSlice.name]: connectedCosmosChainsSlice.reducer,
  [delegationProcessStateSlice.name]: delegationProcessStateSlice.reducer,
  [cosmosMultiDelegationsApi.reducerPath]: cosmosMultiDelegationsApi.reducer,
  [optimizeSuggestionChainsSlice.name]: optimizeSuggestionChainsSlice.reducer,
  [cosmosValidatorsHistoryApi.reducerPath]: cosmosValidatorsHistoryApi.reducer,
  [ethereumMultiValidatorsApi.reducerPath]: ethereumMultiValidatorsApi.reducer,
  [mobileDeviceSelectedWalletsSlice.name]:
    mobileDeviceSelectedWalletsSlice.reducer,
  [alreadyLinkedWalletAddressesSlice.name]:
    alreadyLinkedWalletAddressesSlice.reducer,
  [solanaDelegationsBalanceHistoryApi.reducerPath]:
    solanaDelegationsBalanceHistoryApi.reducer,
  [solanaStakeAccountBalanceHistoryApi.reducerPath]:
    solanaStakeAccountBalanceHistoryApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearAuth') return appReducer(undefined, action);
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  /*TODO*/
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      rtkQueryErrorHandler({
        // Ignore chainsDataApi error because some currencies are not supported and don't need to show error
        ignore: [chainsDataApi.reducerPath],
      }),
      reportsApi.middleware,
      walletsApi.middleware,
      feedbackApi.middleware,
      userGrantsApi.middleware,
      chainsDataApi.middleware,
      compoundingApi.middleware,
      lavaRestakeApi.middleware,
      lavaRewardsApi.middleware,
      cosmosWalletsApi.middleware,
      exchangeRatesApi.middleware,
      notificationsApi.middleware,
      walletsSummariseApi.middleware,
      solanaWalletsApi.middleware,
      solanaValidatorsApi.middleware,
      solanaWalletBalanceApi.middleware,
      lavaRestakeSpecsApi.middleware,
      solanaDelegationsApi.middleware,
      compoundingConfigApi.middleware,
      cosmosValidatorsApi.middleware,
      cosmosDelegationsApi.middleware,
      cosmosMultiValidators.middleware,
      ethereumValidatorsApi.middleware,
      cosmosWalletBalanceApi.middleware,
      cosmosMultiDelegationsApi.middleware,
      ethereumMultiValidatorsApi.middleware,
      cosmosValidatorsHistoryApi.middleware,
      solanaDelegationsBalanceHistoryApi.middleware,
      solanaStakeAccountBalanceHistoryApi.middleware
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
