import styled from 'styled-components';

import { Flex } from 'libs/commons/components/styles';

export const RewardIconContainer = styled(Flex)`
  border-radius: 40px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  background: #6298ea;
  justify-content: center;
  box-shadow:
    0 0 12px 0 rgba(54, 54, 54, 0.7),
    0 0 5px 0 #363636;

  svg {
    filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8))
      drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.8));
    width: 16px;

    height: 16px;
  }
`;
