import { CommonRoutes } from 'libs/commons/config/common-routes';

const Auth = {
  Login: '/email',
  Verification2FA: '/2fa',
  Registration: '/registration',
  ForgotPassword: '/forgot-password',
  ChangePassword: '/change-password',
  EmailConfirmed: '/email-confirmed',
  EmailVerification: '/email-verification',
  TermsAndConditions: '/terms-and-conditions',
  Verification2FAConfirmLogin: '/2fa-confirm-login',
  Verification2FAConfirmSignUp: '/2fa-confirm-sign-up',
};

const Dashboard = {
  Home: '/dashboard',
  Asset: {
    Home: 'asset',
    Rewards: 'rewards',
  },
  Provider: {
    Home: 'provider',
    Rewards: 'rewards',
  },
  AllValidators: {
    Home: 'all-validators',
    ProvidersRewards: 'providers-rewards',
    ValidatorsRewards: 'validators-rewards',
  },
};

export const Routes = {
  ...CommonRoutes,
  Auth,
  Dashboard,
  Home: '/',
  Wallets: '/wallets',
  Account: '/account',
  NotFound: '/not-found',
  WalletSignIn: '/wallet',
  LavaWalletSignIn: '/lava',
  AccountTermsAndConditions: '/account/terms-and-conditions',
};
