import { toast, Bounce, ToastContent } from 'react-toastify';

import { ChainType } from '../types';

export const showError = <T extends ToastContent = ToastContent>(
  ...params: Parameters<typeof toast.error<T>>
) => toast.error<T>(...params);

export const showSuccess = <T extends ToastContent = ToastContent>(
  ...params: Parameters<typeof toast.success<T>>
) => toast.success<T>(...params);

export const showMessage = (message: string) =>
  toast(message, {
    autoClose: 600,
    draggable: false,
    transition: Bounce,
    closeButton: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });

export const addAlphaToColor = (
  color: `${'rgb' | 'rgba'}${string}`,
  alpha: number
) => {
  if (color.startsWith('rgba')) {
    return color.replace(
      /rgba\((\d+\s*,\s*\d+\s*,\s*\d+)\s*,\s*[\d.]+\)/,
      `rgba($1, ${alpha})`
    );
  } else if (color.startsWith('rgb')) {
    return color.replace(
      /rgb\((\d+\s*,\s*\d+\s*,\s*\d+)\)/,
      `rgba($1, ${alpha})`
    );
  } else {
    return color;
  }
};

export const isFilterApplied = (value: unknown): boolean => {
  if (value instanceof Set) {
    return value.size > 0;
  } else if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  } else if (typeof value === 'number') {
    return value !== 0;
  } else if (typeof value === 'object' && value) {
    return Object.values(value).some(Boolean);
  }

  return Boolean(value);
};

export const areSomeFiltersApplied = <T extends Record<string, any>>(
  filters: T
): boolean => Object.values(filters).some(isFilterApplied);

export const clearCacheAndReload = () => {
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });

  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const getThemeChainBasedColor = <T>({
  colors,
  chainType,
}: {
  chainType?: ChainType;
  colors: Record<string, T>;
}) => {
  return chainType && chainType in colors ? colors[chainType] : colors.default;
};

export const percentage = (
  partialValue: string | number,
  totalValue: string | number
) => {
  return (100 * Number(partialValue)) / Number(totalValue);
};

export * from '../../core/utils/helpers';
