export class SolanaTransactionError extends Error {
  signatureStatus?: unknown;

  retries?: number;

  constructor(message: string, signatureStatus?: unknown, retries?: number) {
    super(message);
    this.signatureStatus = signatureStatus;
    this.retries = retries;
    Object.setPrototypeOf(this, SolanaTransactionError.prototype);
  }
}
