import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/commons/utils/query';
import { ethereumApiQuery } from 'libs/commons/store/query';

import { URL } from 'api/constants';

import {
  ReportEvent,
  ReportsRequest,
  ReportsResponse,
  ReportDataFieldResponse,
  ReportDataFieldExchangedValueResponse,
} from './types';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: ethereumApiQuery,
  endpoints: (build) => ({
    getCSVReport: build.query<string, ReportsRequest>({
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.REPORTS_CSV, params),
      }),
    }),
    getReports: build.query<ReportsResponse[], ReportsRequest>({
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.REPORTS, params),
      }),
    }),
  }),
});

export const { useGetReportsQuery, useLazyGetCSVReportQuery } = reportsApi;

export type {
  ReportEvent,
  ReportsRequest,
  ReportsResponse,
  ReportDataFieldResponse,
  ReportDataFieldExchangedValueResponse,
};
