import { axiosBaseQuery } from '../../core/store/query';
import { cosmosApiInstance, compoundingApiInstance } from '../api/instance';

export const cosmosApiQuery = axiosBaseQuery({
  instance: cosmosApiInstance,
});

export const compoundingApiQuery = axiosBaseQuery({
  instance: compoundingApiInstance,
});
