import styled from 'styled-components';

import { Select } from 'libs/commons/components';
import { DropdownIndicatorArrow } from 'libs/commons/components/select/styles';

export const StyledSelect = styled(Select)`
  .select__menu-list {
    &::before {
      padding: 2px;
      border-radius: inherit;
    }
  }

  ${DropdownIndicatorArrow} {
    width: 16px;
    height: 16px;
  }
` as typeof Select;
