import { URL } from '../../api/constants';
import { cosmosApiQuery } from '../query';
import { CommonValidatorsRequestParams } from '../../types';
import { getSortParams, addParamsToUrl } from '../../../core/utils/query';

export const lavaRestakeSpecsApiConfig = {
  baseQuery: cosmosApiQuery,
  reducerPath: 'lavaRestakeSpecsApi',
  endpoints: {
    getLavaRestakeProviders: {
      query: (params: CommonValidatorsRequestParams) => ({
        method: 'get',
        url: addParamsToUrl(URL.LAVA_RESTAKE_PROVIDERS, {
          ...params,
          ...getSortParams(params?.sort),
          size: params?.size || 1000,
        }),
      }),
    },
  },
};
