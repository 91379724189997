import { createApi } from '@reduxjs/toolkit/query/react';

import {
  GrantData,
  StakingGrantee,
  userOptimizationGrantsApiConfig,
} from 'libs/cosmos-core/store/api/user-optimization-grants';

export const userGrantsTagTypes = ['user-grants'];

export const userGrantsApi = createApi({
  tagTypes: userOptimizationGrantsApiConfig.tagTypes,
  baseQuery: userOptimizationGrantsApiConfig.baseQuery,
  reducerPath: userOptimizationGrantsApiConfig.reducerPath,
  endpoints: (build) => ({
    list: build.query<GrantData[], string>(
      userOptimizationGrantsApiConfig.endpoints.getUserOptimizationGrants
    ),
  }),
});

export const { useListQuery } = userGrantsApi;

export type { GrantData, StakingGrantee };
