import { isDesktop } from '../../../hooks';
import { DataFetchWrapper } from '../../../components/data-fetch-wrapper';

import { TableDataFetchWrapperProps } from './types';
import { PlaceholderTableRow } from './placeholder-table-row';

export const TableDataFetchWrapper = ({
  isError,
  refetch,
  children,
  viewType,
  emptyTitle,
  isFetching,
  columnsCount,
  fetchingIcon,
  rowsCount = 3,
  hasData = false,
  fetchingMessage,
}: TableDataFetchWrapperProps) => {
  const desktop = isDesktop();

  return (
    <DataFetchWrapper
      isError={isError}
      refetch={refetch}
      hasData={hasData}
      isFetching={isFetching}
      emptyTitle={emptyTitle}
      fetchingIcon={fetchingIcon}
      fetchingMessage={fetchingMessage}
      placeholderElement={
        desktop &&
        Array.from({
          length: rowsCount,
        }).map((_, index) => (
          <PlaceholderTableRow
            key={index}
            index={index}
            viewType={viewType}
            columnsCount={columnsCount}
          />
        ))
      }
    >
      {children}
    </DataFetchWrapper>
  );
};
