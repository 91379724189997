import styled from 'styled-components';

export const Highlighted = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;

export const List = styled.ul`
  list-style-type: initial;
  padding-left: 20px;

  li::marker {
    color: ${({ theme }) => theme.colors.green};
  }
`;
