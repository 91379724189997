import { createApi } from '@reduxjs/toolkit/query/react';

import { ethereumApiQuery } from 'libs/commons/store/query';

import { URL } from 'api/constants';

import { SendFeedbackRequest } from './types';

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: ethereumApiQuery,
  endpoints: (build) => ({
    sendFeedback: build.mutation<void, SendFeedbackRequest>({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.FEEDBACKS,
      }),
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbackApi;

export type { SendFeedbackRequest };
