import styled, { css } from 'styled-components';

import arrowIcon from 'libs/core/assets/icons/chevron.svg';
import { gradientBorder } from 'libs/commons/styles/mixins';
import selectBg from 'libs/commons/assets/images/dropdown-background.svg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .react-datepicker {
      border: none;
      background: transparent;

      &__navigation {
        width: 24px;
        height: 24px;
        top: 16px;

        &--previous {
          left: 16px;

          .react-datepicker__navigation-icon {
            transform: rotate(180deg);
          }
        }

        &--next {
          right: 16px;
        }

        &-icon {
          width: inherit;
          background-image: url(${arrowIcon});
          top: 0;
          right: 0;

          &::before {
            display: none;
          }
        }
      }

      &__day-names {
        margin-bottom: 0;
        margin-top: 8px;
      }

      &__day-name {
        margin: 0;
        padding: 8px 5px;
        width: 40px;
        height: 40px;
      }

      &__month-container {
        ${gradientBorder};

        border-radius: 28px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(40px);
        -webkit-backdrop-filter: blur(40px);
        background: ${theme.config.input.background.active};
      }

      &__header {
        border: none;
        position: relative;
        padding: 16px 16px 8px 16px;
        background: transparent;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: ${theme.colors.accent.primary.main};
        }
      }

      &__month {
        margin: 0;
        padding: 8px 16px;
        background-size: auto;
        background-position: 62px -25px;
        background-image: url(${selectBg});
      }

      &__current-month {
        color: ${theme.config.text.primary.main};
      }

      &__day {
        color: ${theme.config.text.primary.main};
        font-weight: 600;
        border-radius: 8px;
        margin: 0;
        padding: 8px 0;
        width: 40px;
        height: 40px;

        &--keyboard-selected {
          background: ${theme.config.datePicker.day.hover};
        }

        &:hover {
          background: ${theme.config.datePicker.day.hover};
        }

        &:active {
          background: ${theme.config.datePicker.day.active};
        }

        &--today {
          background: ${theme.config.datePicker.day.hover};
        }

        &--selected {
          background: ${theme.colors.accent.primary.main};

          &:hover {
            background: ${theme.colors.accent.primary.main};
          }
        }

        &--in-range,
        &--in-selecting-range {
          background: ${theme.colors.accent.primary.main};
          color: ${theme.config.text.primary.main};

          &:hover {
            background: ${theme.colors.accent.primary.main};
            color: ${theme.config.text.primary.main};
          }
        }

        &--disabled {
          color: ${theme.colors.grey.primary.active};
        }
      }

      &__day-name {
        color: ${theme.colors.grey.primary.main};
      }
    }
  `}
`;
