import { createApi } from '@reduxjs/toolkit/query/react';

import { lavaRewardsCalculatorApiConfig } from 'libs/cosmos-core/store/api';
import {
  LavaRewardsCalculateRequest,
  LavaRewardsCalculateResponse,
} from 'libs/cosmos-core/store/api/lava-rewards-calculator/types';

export const lavaRewardsApi = createApi({
  baseQuery: lavaRewardsCalculatorApiConfig.baseQuery,
  reducerPath: lavaRewardsCalculatorApiConfig.reducerPath,
  endpoints: (build) => ({
    lavaCalculateRewards: build.query<
      LavaRewardsCalculateResponse,
      LavaRewardsCalculateRequest
    >(lavaRewardsCalculatorApiConfig.endpoints.calculate),
  }),
});

export const { useLavaCalculateRewardsQuery } = lavaRewardsApi;

export type { LavaRewardsCalculateRequest, LavaRewardsCalculateResponse };
