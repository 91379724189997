import { Icon } from 'components';
import { TransactionStatus } from 'types';

import { TransactionLoaderProps } from '../types';
import { transactionLoaderMotionProps } from '../config';

import * as Styles from './styles';

const title: Record<TransactionStatus, string> = {
  success: 'Success',
  'simulating-gas': 'Simulating Gas',
  signing: 'Signing and Broadcasting',
  'connecting-to-chain': 'Connecting to Chain',
};

export type TransactionLoaderLoadingProps = Omit<
  TransactionLoaderProps,
  'isLoading'
>;

export const Loading = ({
  status,
  onClose,
  retries,
  children,
  retriesLimit,
  titleSlot = <Icon.Keplr width={80} height={80} />,
  ...props
}: TransactionLoaderLoadingProps) => {
  return (
    <Styles.LoadingContainer {...transactionLoaderMotionProps}>
      <Styles.LeftBackgroundEllipsis />
      <Styles.RightBackgroundEllipsis />

      {onClose && (
        <Styles.CloseButton
          styleType="text"
          onClick={onClose}
          icon={<Icon.Close />}
        />
      )}

      <Styles.HeadingContainer>
        <Styles.HeadingBackgroundGradient />
        <Styles.Heading>
          {titleSlot}
          {status && <h2>{title[status]}</h2>}
          {retriesLimit && retries && retries > 0 ? (
            <h3>
              Retries {retries} / {retriesLimit}
            </h3>
          ) : null}
        </Styles.Heading>
      </Styles.HeadingContainer>

      <Styles.LottieAnimation {...props} />
    </Styles.LoadingContainer>
  );
};
