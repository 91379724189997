import Lottie, { LottieComponentProps } from 'lottie-react';

import loaderAnimation from '../assets/animation/loader.json';

export const LottieLoader = (
  props: Omit<LottieComponentProps, 'animationData' | 'initialSegment'>
) => (
  <Lottie
    initialSegment={[0, 140]}
    animationData={loaderAnimation}
    {...props}
  />
);
