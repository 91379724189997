import { URL } from '../../../api/constants';
import { ethereumApiQuery } from '../../query';
import { addParamsToUrl } from '../../../utils/query';

export const exchangeRatesApiConfig = {
  baseQuery: ethereumApiQuery,
  reducerPath: 'exchangeRatesApi',
  endpoints: {
    getExchangeRates: {
      query: (quoteCurrency: string) => ({
        method: 'get',
        url: addParamsToUrl(URL.EXCHANGE_RATES, {
          quoteCurrency,
        }),
      }),
    },
  },
};

export * from './types';
