import styled, { css } from 'styled-components';

import { Icon } from 'libs/commons/components';

import { ValidatorStatus } from 'types';

export const Container = styled.div<{
  $size: number;
  $withLabel: boolean;
  $withHover: boolean;
  $status: ValidatorStatus;
}>`
  gap: 8px;
  align-items: center;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  
  ${({ theme, $size, $status, $withLabel, $withHover }) => {
    const config = theme.config.validatorStatus[$status];

    const isOfflineStatus = $status.includes('OFFLINE');

    return css`
      color: ${config.container.text};
      box-shadow: ${config.container.main.boxShadow};
      background: ${config.container.main.background};

      ${$withHover &&
      css`
        &:hover {
          background: ${config.container.hover.background};
          box-shadow: ${config.container.hover.boxShadow};
        }
      `}

      svg {
        width: ${$size}px;
        height: ${$size}px;
      }

      padding: ${$withLabel ? '4px 8px' : '4px'};

      ${isOfflineStatus &&
      css`
        font-weight: 800;
        text-transform: uppercase;
      `}

      svg {
        filter: ${config.icon.shadow};

        path {
          fill: ${config.icon.color};
        }
      }
    `;
  }}}
`;

export const RSSIcon = styled(Icon.RSS)`
  path:nth-child(1) {
    fill: url(#gradient1);
  }

  path:nth-child(2) {
    fill: url(#gradient2);
  }

  path:nth-child(3) {
    fill: url(#gradient3);
  }
`;

export const ExitedIcon = styled(Icon.Exit)`
  path:nth-child(1) {
    fill: url(#gradient1);
  }

  path:nth-child(2) {
    fill: url(#gradient2);
  }

  path:nth-child(3) {
    fill: url(#gradient3);
  }
`;

export const PendingIcon = styled(Icon.Clock)`
  path:first-child {
    fill: url(#gradient1);
  }

  path:nth-child(2) {
    fill: url(#gradient2);
  }
`;

export const SlashedIcon = styled(Icon.RefreshSeparated)`
  path:first-child {
    stroke: url(#gradient);
  }
`;

export const ExitingOnlineIcon = styled(Icon.ArrowCircle)`
  path:first-child {
    fill: url(#gradient1);
  }

  path:nth-child(2) {
    fill: url(#gradient2);
  }

  path:nth-child(3) {
    fill: url(#gradient3);
  }
`;

export const SlashingOnlineIcon = styled(Icon.Refresh)`
  path:first-child {
    stroke: url(#gradient);
  }
`;
