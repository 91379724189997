import styled from 'styled-components';

import { Flex } from 'libs/commons/components';

import { DelegableChainType } from 'types';

import { iconBgImage } from './config';

export const Container = styled(Flex)<{
  chainType: DelegableChainType;
}>`
  align-items: center;
  justify-content: center;
  background-image: ${({ chainType }) => `url(${iconBgImage[chainType]})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const IconContainer = styled.div`
  svg {
    width: 56px;
    height: 56px;
  }
`;
