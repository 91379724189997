import { formatNumber } from 'libs/commons/utils/helpers';
import {
  gweiToEther,
  addCurrencyLabel,
  convertAndLabelCurrency,
} from 'libs/commons/utils/currencies';
import {
  ChainType,
  AmountResponse,
  AmountResponseWithUsd,
  EthereumChainCurrency,
  CosmosChainTypeCurrency,
  CosmosChainTypeCurrencyOptions,
  EthereumChainTypeCurrencyOptions,
} from 'libs/commons/types';

import { ChainToBaseCurrency } from 'types/data';
import { TransactionActivityProps } from 'components/transaction-activity';

export const addBtcLabel = (value: string | number) => `${value} BTC`;

export const formatEthBalance = (rawBalance?: string | bigint) =>
  addCurrencyLabel(
    rawBalance ? formatNumber(parseInt(rawBalance.toString()) / 1e18) : '0',
    'ETHEREUM'
  );

export const convertCosmosMicroUnitToUnit = (value: string) => {
  if (value === '0' || !value) return '0';

  const divided = +value / 1_000_000;

  return divided;
};

export const ethTransactionActivityFormat: Partial<TransactionActivityProps> = {
  tooltipRewardValueFormatter: (gwei) =>
    addCurrencyLabel(formatNumber(gweiToEther(gwei)), 'ETHEREUM'),
  rewardYAxisProps: {
    tickFormatter: (gwei) =>
      addCurrencyLabel(formatNumber(gweiToEther(gwei)), 'ETHEREUM'),
  },
};

export const transactionActivityCurrencyFormat =
  (chainType: ChainType) => (value: string) =>
    convertAndLabelCurrency({
      value,
      currency: ChainToBaseCurrency[chainType],
    });

export const transactionActivityFormat = (
  chainType: ChainType
): Partial<TransactionActivityProps> => ({
  tooltipRewardValueFormatter: transactionActivityCurrencyFormat(chainType),
  rewardYAxisProps: {
    tickFormatter: transactionActivityCurrencyFormat(chainType),
  },
});

export const groupAmountsByCurrencies = ({
  data,
  mainCurrency,
}: {
  data: AmountResponseWithUsd[];
  mainCurrency: AmountResponse['currency'];
}) => {
  const result: AmountResponse[] = [];

  const mainCurrencyData = data.filter(
    (item) => item.amount.currency === mainCurrency
  );

  if (mainCurrencyData.length) {
    result.push(
      mainCurrencyData.reduce(
        (res, item) => {
          res.value = String(+res.value + +item.amount.value);
          return res;
        },
        { value: '0', currency: mainCurrency }
      )
    );
  }

  const filteredData = data.filter(
    (item) => item.amount.currency !== mainCurrency
  );

  for (const item of filteredData) {
    const index = result.findIndex((i) => i.currency === item.amount.currency);

    if (index === -1) {
      result.push({ ...item.amount });
    } else {
      result[index].value = String(+result[index].value + +item.amount.value);
    }
  }

  return result;
};

export const isMainCurrency = (currency: string) =>
  CosmosChainTypeCurrencyOptions.includes(
    currency as CosmosChainTypeCurrency
  ) ||
  EthereumChainTypeCurrencyOptions.includes(
    currency as EthereumChainCurrency
  ) ||
  currency === 'lamport';

export * from 'libs/commons/utils/currencies';
