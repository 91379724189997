import { createApi } from '@reduxjs/toolkit/query/react';

import { addParamsToUrl } from 'libs/commons/utils/query';
import { ethereumApiQuery } from 'libs/commons/store/query';

import { URL } from 'api/constants';

import {
  AssetResponse,
  IncomeHistory,
  IncomeResponse,
  DepositResponse,
  IncomeHistoryRequest,
  IncomeHistoryDetails,
  BalanceHistoryRequest,
  IncomeHistoryResponse,
  BalanceHistoryResponse,
} from './types';

const insertValidatorId = (url: string, value: string) =>
  url.replace('{validatorId}', value);

export const ethereumValidatorsApi = createApi({
  baseQuery: ethereumApiQuery,
  reducerPath: 'ethereumValidatorsApi',
  endpoints: (build) => ({
    getAsset: build.query<AssetResponse, string>({
      query: (validatorId) => ({
        method: 'get',
        url: insertValidatorId(URL.ETHEREUM_VALIDATORS_ASSET, validatorId),
      }),
    }),
    getIncome: build.query<IncomeResponse, string>({
      query: (validatorId) => ({
        method: 'get',
        url: insertValidatorId(URL.ETHEREUM_VALIDATORS_INCOME, validatorId),
      }),
    }),
    getDeposit: build.query<DepositResponse, string>({
      query: (validatorId) => ({
        method: 'get',
        url: insertValidatorId(URL.ETHEREUM_VALIDATORS_DEPOSIT, validatorId),
      }),
    }),
    getIncomeHistory: build.query<IncomeHistoryResponse, IncomeHistoryRequest>({
      query: ({ size, page, validatorId }) => ({
        method: 'get',
        url: addParamsToUrl(
          insertValidatorId(
            URL.ETHEREUM_VALIDATORS_INCOME_HISTORY,
            validatorId
          ),
          { size, page }
        ),
      }),
    }),
    getBalanceHistory: build.query<
      BalanceHistoryResponse,
      BalanceHistoryRequest
    >({
      query: ({ validatorId, balanceRange }) => ({
        method: 'get',
        url: addParamsToUrl(
          insertValidatorId(
            URL.ETHEREUM_VALIDATORS_BALANCE_HISTORY,
            validatorId
          ),
          { balanceRange }
        ),
      }),
    }),
  }),
});

export const {
  useGetAssetQuery,
  useGetIncomeQuery,
  useGetDepositQuery,
  useGetIncomeHistoryQuery,
  useGetBalanceHistoryQuery,
} = ethereumValidatorsApi;

export type {
  AssetResponse,
  IncomeHistory,
  IncomeResponse,
  DepositResponse,
  IncomeHistoryRequest,
  IncomeHistoryDetails,
  BalanceHistoryRequest,
  IncomeHistoryResponse,
  BalanceHistoryResponse,
};
