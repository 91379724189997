import { AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

import { axiosBaseQuery } from 'libs/core/store/query';

import { notificationsApiInstance } from '../api/instance';

export interface ResponseFuncProps {
  url: string;
  data: AxiosRequestConfig['data'];
}

export interface MockHandler {
  url: string;
  method: string;
  timeout?: number;
  responseData?: any;
  responseFunc?: (value: ResponseFuncProps) => any;
}

export const mockBaseQuery =
  ({
    handlers,
  }: {
    handlers: MockHandler[];
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    Error
  > =>
  ({ url, data, method }) =>
    new Promise((resolve, reject) => {
      const handler = handlers.find(
        (hand) => hand.url === url && hand.method === method
      );

      if (!handler) {
        reject({
          error: {
            status: 404,
            message: 'No handler',
          },
        });
      }

      const { responseData, responseFunc } = handler!;

      setTimeout(() => {
        resolve({
          data: responseFunc ? responseFunc({ url, data }) : responseData,
        });
      }, handler!.timeout ?? 1500);
    });

export const notificationsApiQuery = axiosBaseQuery({
  instance: notificationsApiInstance,
});

export * from '../../core/store/query';
