import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';

import { SolanaDelegationData, SolanaDelegationsResponse } from './types';

export const solanaDelegationsTagTypes = ['solana-delegations'];

export const solanaDelegationsApi = createApi({
  baseQuery: solanaApiQuery,
  tagTypes: solanaDelegationsTagTypes,
  reducerPath: 'solanaDelegationsApi',
  endpoints: (build) => ({
    getSolanaDelegations: build.query<SolanaDelegationsResponse, string>({
      providesTags: solanaDelegationsTagTypes,
      query: (walletAddress) => ({
        method: 'get',
        url: URL.DELEGATIONS.replace('{walletAddress}', walletAddress),
      }),
    }),
  }),
});

export const { useGetSolanaDelegationsQuery } = solanaDelegationsApi;

export type {
  SolanaDelegationsResponse,
  SolanaDelegationData as SolanaDelegationData,
};
