import { ButtonConfig } from '../types';
import { ChainType } from '../../../types';

import { LIGHT_COLORS as Colors } from './colors';

const transparentBorder = '1px solid #ffffff40';
const boxShadow = '0 8px 16px 0 rgba(4, 19, 1, 0.32)';

const text = Object.freeze({
  secondary: {
    main: 'inherit',
    hover: 'inherit',
    active: 'inherit',
  },
  primary: {
    main: Colors.black,
    hover: Colors.grey.primary.hover,
    active: Colors.grey.primary.active,
  },
});

const button = Object.freeze<ButtonConfig>({
  text: {
    color: {
      main: text.primary.main,
      hover: text.primary.hover,
      active: text.primary.active,
    },
  },
  gradient: {
    color: {
      main: Colors.accent.primary.main,
      hover: Colors.accent.primary.hover,
      active: Colors.accent.primary.active,
    },
  },
  additionalRed: {
    background: {
      hover: Colors.red.primary.hover,
      main: Colors.red.additional.main,
      active: Colors.red.primary.active,
    },
  },
  red: {
    color: Colors.white.primary.main,
    background: {
      main: Colors.red.primary.main,
      hover: Colors.red.primary.hover,
      active: Colors.red.primary.active,
    },
  },
  textRed: {
    icon: Colors.red.additional.main,
    color: {
      main: Colors.red.additional.main,
      hover: Colors.red.additional.main,
      active: Colors.red.additional.main,
    },
  },
  primary: {
    color: Colors.white.primary.main,
    background: {
      main: Colors.accent.primary.main,
      hover: Colors.accent.primary.hover,
      active: Colors.accent.primary.active,
    },
  },
  backgroundLavaGradient: {
    color: Colors.white.primary.main,
    background: {
      main: Colors.lavaGradient.secondary,
      hover: Colors.lavaGradient.secondary,
      active: Colors.lavaGradient.secondary,
    },
  },
  secondaryRed: {
    icon: Colors.red.primary.main,
    color: Colors.red.primary.main,
    border: `1px solid ${Colors.red.primary.main}`,
    background: {
      main: 'rgba(241, 100, 100, 0.20)',
      hover: 'rgba(241, 100, 100, 0.30)',
      active: 'rgba(241, 100, 100, 0.50)',
    },
  },
  blue: {
    color: {
      main: Colors.blue.primary.main,
      hover: Colors.blue.primary.main,
      active: Colors.blue.primary.main,
    },
    background: {
      main: Colors.blue.secondary.main,
      hover: Colors.blue.secondary.main,
      active: Colors.blue.secondary.main,
    },
  },
  secondary: {
    color: {
      main: Colors.accent.primary.main,
      hover: Colors.white.primary.main,
      active: Colors.white.primary.main,
    },
    background: {
      main: Colors.accent.secondary.main,
      hover: Colors.accent.secondary.hover,
      active: Colors.accent.secondary.active,
    },
  },
  primaryDark: {
    color: {
      main: Colors.accent.primary.main,
      hover: Colors.accent.primary.main,
      active: Colors.accent.primary.main,
    },
    background: {
      main: 'linear-gradient(226deg, rgba(236, 205, 43, 0.16) 20.01%, rgba(3, 151, 80, 0.16) 54.9%, rgba(24, 124, 198, 0.16) 86.45%)',
      hover:
        'linear-gradient(226deg, rgba(236, 205, 43, 0.30) 20.01%, rgba(3, 151, 80, 0.30) 54.9%, rgba(24, 124, 198, 0.30) 86.45%)',
      active:
        'linear-gradient(226deg, rgba(236, 205, 43, 0.40) 20.01%, rgba(3, 151, 80, 0.40) 54.9%, rgba(24, 124, 198, 0.40) 86.45%)',
    },
  },
});

const scrollbar = Object.freeze({
  thumb: Colors.lightGrey.primary.main,
});

const input = Object.freeze({
  backdropFilter: 'blur(15.5px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  placeholder: {
    secondary: '#a8ada7',
    primary: Colors.grey.primary.main,
  },
  boxShadow: {
    main: '0px 8px 16px 0px rgba(4, 19, 1, 0.08) ',
    hover: '0px 12px 24px 0px rgba(4, 19, 1, 0.08)',
  },
  background: {
    main: 'linear-gradient(257deg, rgba(254, 202, 68, 0.25)-0.48%, rgba(141, 201, 42, 0.25)41.54%, rgba(42, 201, 125, 0.25)85.47%, rgba(9, 174, 197, 0.25)133.2%, rgba(9, 120, 197, 0.25)177.81%)',
    hover:
      'linear-gradient(257deg, rgba(254, 202, 68, 0.10)-0.48%, rgba(141, 201, 42, 0.10)41.54%, rgba(42, 201, 125, 0.10)85.47%, rgba(9, 174, 197, 0.10)133.2%, rgba(9, 120, 197, 0.10)177.81%)',
    active:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.48) 0%, rgba(4, 19, 1, 0.48) 100%), linear-gradient(221deg, rgba(254, 202, 68, 0.10) 0%, rgba(141, 201, 42, 0.10) 23.57%, rgba(42, 201, 125, 0.10) 48.21%, rgba(9, 174, 197, 0.10) 74.98%, rgba(9, 120, 197, 0.10) 100%)',
  },
});

const toggle = Object.freeze({
  circle: Colors.grey.primary.main,
  background: {
    main: Colors.lightGrey.primary.main,
    hover: Colors.lightGrey.primary.hover,
  },
});

const checkbox = Object.freeze({
  main: Colors.black,
  mark: Colors.white.primary.main,
  hover: Colors.grey.primary.hover,
  active: Colors.grey.primary.active,
});

const presets = Object.freeze({
  divider: Colors.lightGrey.primary.main,
  hover: {
    background: input.background.hover,
  },
  active: {
    background: input.background.active,
  },
  selected: {
    color: Colors.white.primary.main,
    background: {
      KII: Colors.kiiGradient.main,
      SOLANA: Colors.solanaGradient.main,
      default: Colors.accent.primary.main,
      LAVA: Colors.lavaGradient.secondary,
      OSMOSIS: Colors.osmosisGradient.main,
    },
  },
});

const transparentBackground =
  'linear-gradient(218deg, rgba(236, 205, 43, 0.04) 0%, rgba(3, 151, 80, 0.04) 52.51%, rgba(24, 124, 198, 0.04) 100%)' as string;

const card = {
  background: {
    main: 'linear-gradient(218deg, #FEFCF6 0%, #F5FAF7 52.51%, #F5F9FC 100%)',
    hover:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.10) 0%, rgba(4, 19, 1, 0.10) 100%), linear-gradient(218deg, #FEFCF6 0%, #F5FAF7 52.51%, #F5F9FC 100%)',
    active:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.15) 0%, rgba(4, 19, 1, 0.15) 100%), linear-gradient(218deg, #FEFCF6 0%, #F5FAF7 52.51%, #F5F9FC 100%)',
  },
};

const modal = Object.freeze({
  background: Colors.modalBackground + '7F',
});

const sidebar = Object.freeze({
  boxShadow: '0px 16px 24px 0px rgba(7, 9, 5, 0.05)' as string,
  background:
    'linear-gradient(0deg, rgba(251, 251, 251, 0.66) 0%, rgba(251, 251, 251, 0.66) 100%), linear-gradient(268deg, rgba(254, 202, 68, 0.10) 0%, rgba(141, 201, 42, 0.10) 23.57%, rgba(42, 201, 125, 0.10) 48.21%, rgba(9, 174, 197, 0.10) 74.98%, rgba(9, 120, 197, 0.10) 100%)' as string,
});

const badge = Object.freeze({
  yellow: {
    text: Colors.black,
    background: Colors.reward,
  },
  success: {
    text: Colors.black,
    background: Colors.success,
  },
  translucent: {
    text: Colors.black,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  blue: {
    text: text.primary.main,
    background: 'rgba(107, 164, 232, 0.36)',
  },
  violet: {
    text: text.primary.main,
    background: 'rgba(169, 107, 232, 0.36)',
  },
  red: {
    text: Colors.white.primary.main,
    background: Colors.red.additional.main,
  },
});

const tableRow = Object.freeze({
  backdropFilter: 'blur(6.5px)' as string,
  boxShadow: '0px 10px 20px 0px rgba(82, 102, 53, 0.12)' as string,
  background: {
    default: {
      main: card.background.main,
      hover: input.background.hover,
      active: input.background.active,
    },
    LAVA: {
      main: Colors.lavaGradient.tertiary,
      hover: Colors.lavaGradient.tertiary,
      active: Colors.lavaGradient.tertiary,
    },
    KII: {
      main: 'linear-gradient(141deg, rgba(104, 120, 207, 0.10) 7.45%, rgba(48, 48, 131, 0.10) 87.06%)',
      hover:
        'linear-gradient(141deg, rgba(104, 120, 207, 0.20) 7.45%, rgba(48, 48, 131, 0.20) 87.06%)',
      active:
        'linear-gradient(141deg, rgba(104, 120, 207, 0.30) 7.45%, rgba(48, 48, 131, 0.30) 87.06%)',
    },
    OSMOSIS: {
      main: 'linear-gradient(141deg, rgba(48, 48, 131, 0.10) 7.45%, rgba(190, 73, 155, 0.10) 87.06%)',
      hover:
        'linear-gradient(141deg, rgba(48, 48, 131, 0.20) 7.45%, rgba(190, 73, 155, 0.20) 87.06%)',
      active:
        'linear-gradient(141deg, rgba(48, 48, 131, 0.30) 7.45%, rgba(190, 73, 155, 0.30) 87.06%)',
    },
    SOLANA: {
      main: 'linear-gradient(250deg, rgba(0, 255, 163, 0.10) -47.16%, rgba(220, 31, 255, 0.10) 140.55%)',
      hover:
        'linear-gradient(250deg, rgba(0, 255, 163, 0.10) -47.16%, rgba(220, 31, 255, 0.10) 140.55%)',
      active:
        'linear-gradient(250deg, rgba(0, 255, 163, 0.10) -47.16%, rgba(220, 31, 255, 0.10) 140.55%)',
    },
  },
});

const numberCard = Object.freeze({
  boxShadow: '0 16px 32px 0 rgba(4, 19, 1, 0.10)' as string,
  background: {
    default: card.background.main,
    KII: tableRow.background.KII.hover,
    LAVA: Colors.lavaGradient.tertiary,
    OSMOSIS: tableRow.background.OSMOSIS.hover,
    SOLANA:
      'linear-gradient(250deg, rgba(0, 255, 163, 0.10) -47.16%, rgba(220, 31, 255, 0.10) 140.55%)',
  },
});

const popup = Object.freeze({
  overlay: {
    background: 'rgba(4, 19, 1, 0.8)',
  },
  background: {
    modal: Colors.white.primary.main,
    lava: 'linear-gradient(207deg, rgba(215, 0, 31, 0.10) -1.17%, rgba(255, 214, 0, 0.10) 105.2%)',
    main: 'linear-gradient(0deg, rgba(251, 251, 251, 0.06) 0%, rgba(251, 251, 251, 0.06) 100%), linear-gradient(259deg, rgba(141, 201, 42, 0.1) -12.32%, rgba(42, 201, 125, 0.1) 34.58%, rgba(9, 120, 197, 0.1) 98.15%)',
  },
});

const table = Object.freeze({
  tabs: {
    border: 'rgba(0, 0, 0, 0.05)',
  },
  collapse: {
    boxShadow: '0px 16px 32px 0px rgba(4, 19, 1, 0.10)',
    divider:
      'linear-gradient(225deg, rgba(126, 134, 123, 0.00) 0%, rgba(126, 134, 123, 0.20) 51.34%, rgba(126, 134, 123, 0.00) 100%)',
  },
  placeholder: {
    item: Colors.grey.primary.main,
    row: {
      background:
        'linear-gradient(218deg, rgba(236, 205, 43, 0.04) 0%, rgba(3, 151, 80, 0.04) 52.51%, rgba(24, 124, 198, 0.04) 100%)',
    },
  },
});

const walletCard = Object.freeze({
  selected: {
    background: {
      main: card.background.main,
      hover: card.background.hover,
      active: card.background.active,
    },
  },
  default: {
    background: {
      main: card.background.main,
      hover: card.background.hover,
      active: card.background.active,
    },
    boxShadow: {
      main: boxShadow,
      hover: '0 20px 40px 0 rgba(4, 19, 1, 0.4)',
      active: '0 4px 8px 0 rgba(4, 19, 1, 0.32)',
    },
  },
});

export const chainColor: Record<ChainType, string> = Object.freeze({
  KII: '#303083',
  COSMOS: '#E4E4E4',
  SOLANA: '#E459E6',
  SECRET: '#7E7E7E',
  OSMOSIS: '#9B54D2',
  ETHEREUM: '#627EEA',
  LAVA: Colors.orange.primary,
});

const chainTypeWithLine = Object.freeze({
  title: {
    text: Colors.accent.primary.main,
    line: 'linear-gradient(270deg, rgba(253, 221, 56, 0) 0%, rgba(253, 221, 56, 0.4) 13.82%, rgba(7, 172, 93, 0.4) 56.14%, rgba(35, 134, 206, 0.4) 79.61%, rgba(35, 134, 206, 0) 97.97%)',
  },
  line: {
    SECRET: 'linear-gradient(270deg, #C2C2C2 20%, rgba(194, 194, 194, 0) 100%)',
    COSMOS: 'linear-gradient(270deg, #C2C2C2 20%, rgba(194, 194, 194, 0) 100%)',
    placeholder:
      'linear-gradient(270deg, #C2C2C2 20%, rgba(194, 194, 194, 0) 100%)',
    LAVA: 'linear-gradient(270deg, rgba(255, 230, 10, 0) 0%, #FFE60A 20%, #FFE60A 80%, rgba(255, 230, 10, 0) 100%)',
    SOLANA:
      'linear-gradient(270deg, rgba(155, 84, 210, 0) 0%, #9B54D2 20%, #2DFFF4 80%, rgba(45, 255, 244, 0) 100%)',
    KII: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, ${chainColor.KII} 20%, ${chainColor.KII} 80%, rgba(255, 255, 255, 0) 100%)`,
    OSMOSIS: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, ${chainColor.OSMOSIS} 20%, ${chainColor.OSMOSIS} 80%, rgba(255, 255, 255, 0) 100%)`,
    ETHEREUM:
      'linear-gradient(270deg, rgba(98, 126, 234, 0) 0%, #627eea 20.31%, #728efb 80.23%, rgba(69, 100, 218, 0) 100%, rgba(69, 100, 218, 0) 100%)',
  },
});

const select = Object.freeze({
  hover: card.background.hover,
  selected: card.background.active,
});

const pieChart = Object.freeze({
  valuePlaceholder: Colors.accent.primary.main,
  colors: {
    default: ['#627EEA', '#8759E6', '#E4E4E4', '#00B373'],
    KII: ['#303083', '#6343B0', '#5974C1', '#6DB2E7', '#6ED9DB'],
    SOLANA: ['#38C6BB', '#53ABC6', '#6896CF', '#817CD9', '#C23AF4'],
    OSMOSIS: ['#2F1C72', '#453386', '#602F92', '#A84597', '#DC77CB'],
    LAVA: [Colors.orange.primary, '#FFE50A', '#95001A', '#6D0044', '#D7001F'],
  },
});

const emptyPlaceholder = Object.freeze({
  title: Colors.grey.primary.main,
});

const chart = Object.freeze({
  grid: Colors.grey.primary.active,
  tickLabel: Colors.grey.primary.main,
  bar: {
    default: '#E4E939',
    compound: '#07F43B',
  },
});

const validatorStatus = Object.freeze({
  ACTIVE_OFFLINE: {
    icon: {
      shadow: 'none',
      color: Colors.red.additional.main,
    },
    container: {
      text: Colors.red.additional.main,
      main: {
        background: Colors.black,
        boxShadow: `0px 0px 12px 0px rgba(241, 100, 100, 0.71), 0px 0px 5px 0px ${Colors.red.primary.main}`,
      },
      hover: {
        background: Colors.black,
        boxShadow: `0px 0px 4px 0px rgba(241, 100, 100, 0.71), 0px 0px 2px 0px ${Colors.red.primary.main}`,
      },
    },
  },
  EXITING_OFFLINE: {
    icon: {
      shadow: 'none',
      color: Colors.red.additional.main,
    },
    container: {
      text: Colors.red.additional.main,
      main: {
        background: Colors.black,
        boxShadow: `0px 0px 12px 0px rgba(241, 100, 100, 0.71), 0px 0px 5px 0px ${Colors.red.primary.main}`,
      },
      hover: {
        background: Colors.black,
        boxShadow: `0px 0px 4px 0px rgba(241, 100, 100, 0.71), 0px 0px 2px 0px ${Colors.red.primary.main}`,
      },
    },
  },
  SLASHING_OFFLINE: {
    icon: {
      shadow: 'none',
      color: Colors.red.additional.main,
    },
    container: {
      text: Colors.red.additional.main,
      main: {
        background: Colors.black,
        boxShadow: `0px 0px 12px 0px rgba(241, 100, 100, 0.71), 0px 0px 5px 0px ${Colors.red.primary.main}`,
      },
      hover: {
        background: Colors.black,
        boxShadow: `0px 0px 4px 0px rgba(241, 100, 100, 0.71), 0px 0px 2px 0px ${Colors.red.primary.main}`,
      },
    },
  },
  EXITED: {
    icon: {
      color: 'inherit',
      shadow: 'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#454444',
        boxShadow:
          '0px 0px 4px 0px rgba(69, 68, 68, 0.70), 0px 0px 2px 0px #454444',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(69, 68, 68, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #454444',
      },
    },
  },
  SLASHED: {
    icon: {
      color: 'inherit',
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#D01919',
        boxShadow:
          '0px 0px 4px 0px rgba(208, 25, 25, 0.70), 0px 0px 2px 0px #D01919',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(208, 25, 25, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #D01919',
      },
    },
  },
  PENDING: {
    icon: {
      color: 'inherit',
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#B1B251',
        boxShadow:
          '0px 0px 4px 0px rgba(177, 178, 81, 0.70), 0px 0px 2px 0px #B1B251',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(177, 178, 81, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #B1B251',
      },
    },
  },
  ACTIVE_ONLINE: {
    icon: {
      color: 'inherit',
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#2CB93A',
        boxShadow:
          '0px 0px 4px 0px rgba(44, 185, 58, 0.70), 0px 0px 2px 0px #2CB93A',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(44, 185, 58, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #2CB93A',
      },
    },
  },
  EXITING_ONLINE: {
    icon: {
      color: 'inherit',
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#EF6161',
        boxShadow:
          '0px 0px 4px 0px rgba(239, 97, 97, 0.70), 0px 0px 2px 0px #EF6161',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(239, 97, 97, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #EF6161',
      },
    },
  },
  SLASHING_ONLINE: {
    icon: {
      color: 'inherit',
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#D03D3D',
        boxShadow:
          '0px 0px 4px 0px rgba(208, 61, 61, 0.70), 0px 0px 2px 0px #D03D3D',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(208, 61, 61, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #D03D3D',
      },
    },
  },
  DEPOSITED: {
    icon: {
      color: Colors.white.primary.main,
      shadow:
        'drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.80)) drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80))',
    },
    container: {
      text: Colors.white.primary.main,
      main: {
        background: '#1AA2C0',
        boxShadow:
          '0px 0px 4px 0px rgba(26, 162, 192, 0.70), 0px 0px 2px 0px #1AA2C0',
      },
      hover: {
        boxShadow: '0px 0px 4px 0px rgba(26, 162, 192, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #1AA2C0',
      },
    },
  },
  DEPOSITED_INVALID: {
    icon: {
      color: Colors.red.additional.main,
      shadow:
        'drop-shadow(0px 0px 2px rgba(241, 100, 100, 0.80)) drop-shadow(0px 0px 4px rgba(241, 100, 100, 0.80))',
    },
    container: {
      text: Colors.red.additional.main,
      main: {
        background: '#363636',
        boxShadow:
          '0px 0px 12px 0px rgba(54, 54, 54, 0.70), 0px 0px 5px 0px #363636',
      },
      hover: {
        boxShadow: '0px 0px 12px 0px rgba(54, 54, 54, 0.70)',
        background:
          'linear-gradient(0deg, rgba(6, 20, 3, 0.16) 0%, rgba(6, 20, 3, 0.16) 100%), #363636',
      },
    },
  },
});

export const drawer = Object.freeze({
  background: '#F2FAF7' as string,
  overlay: 'rgba(4, 19, 1, 0.8)' as string,
});

export const walletsSidebar = Object.freeze({
  tokenBalanceTitle: text.primary.main,
  background: {
    delegate: '#F2FAF7' as string,
  },
  walletCard: {
    background:
      'linear-gradient(226deg, #FEFCF6 20.01%, #F5FAF7 54.9%, #F5F9FC 86.45%)' as string,
  },
});

export const delegateSection = Object.freeze({
  confirmValidators: {
    tableRow: {
      background:
        'linear-gradient(226deg, rgba(236, 205, 43, 0.04) 20.01%, rgba(3, 151, 80, 0.04) 54.9%, rgba(24, 124, 198, 0.04) 86.45%)',
    },
  },
  chooseValidator: {
    activeTableRow: {
      background:
        'linear-gradient(0deg, rgba(251, 251, 251, 0.10) 0%, rgba(251, 251, 251, 0.10) 100%), linear-gradient(257deg, rgba(254, 202, 68, 0.10) -0.48%, rgba(141, 201, 42, 0.10) 41.54%, rgba(42, 201, 125, 0.10) 85.47%, rgba(9, 174, 197, 0.10) 133.2%, rgba(9, 120, 197, 0.10) 177.81%)',
    },
  },
  background: {
    main: 'rgba(16, 23, 17, 0.6)',
    fullValidatorsTable: 'rgba(16, 23, 17, 1)',
    toggleContainer:
      'linear-gradient(226deg, rgba(236, 205, 43, 0.40) 20.01%, rgba(3, 151, 80, 0.40) 54.9%, rgba(24, 124, 198, 0.40) 86.45%)',
    validatorCard: {
      SOLANA: tableRow.background.SOLANA.main,
      OSMOSIS: tableRow.background.OSMOSIS.main,
      LAVA: 'linear-gradient(268deg, rgba(215, 0, 31, 0.10) -2%, rgba(255, 214, 0, 0.10) 100.58%)',
      default:
        'linear-gradient(257deg, rgba(254, 202, 68, 0.05) -0.48%, rgba(141, 201, 42, 0.05) 41.54%, rgba(42, 201, 125, 0.05) 85.47%, rgba(9, 174, 197, 0.05) 133.2%, rgba(9, 120, 197, 0.05) 177.81%)',
    },
    summary: {
      LAVA: Colors.lavaGradient.tertiary,
      SOLANA: tableRow.background.SOLANA.main,
      OSMOSIS: tableRow.background.OSMOSIS.active,
      default:
        'linear-gradient(0deg, rgba(251, 251, 251, 0.04) 0%, rgba(251, 251, 251, 0.04) 100%), linear-gradient(257deg, rgba(254, 202, 68, 0.1) -0.48%, rgba(141, 201, 42, 0.1) 41.54%, rgba(42, 201, 125, 0.1) 85.47%, rgba(9, 174, 197, 0.1) 133.2%, rgba(9, 120, 197, 0.1) 177.81%)',
    },
  },
});

export const delegationPieChart = Object.freeze({
  totalCommission: '#E84142',
  totalRewardRate: Colors.green,
});

export const compoundingInterest = Object.freeze({
  background: Colors.darkAccentGradient,
  boxShadow:
    '0 2px 4px 0 rgba(4, 19, 1, 0.24), 0 0 24px 0 rgba(226, 203, 46, 0.18)',
});

export const alertMessage = Object.freeze({
  background: 'rgba(241, 100, 100, 0.1)',
});

export const datePicker = Object.freeze({
  day: {
    hover: 'rgba(251, 251, 251, 0.15)',
    active: 'rgba(251, 251, 251, 0.25)',
  },
});

export const divider =
  'linear-gradient(270deg, rgba(230, 231, 230, 0.00) 0%, rgba(230, 231, 230, 0.20) 51.34%, rgba(230, 231, 230, 0.00) 100%)';

export const notificationCenter = Object.freeze({
  severityBadge: {
    background: {
      LOW: Colors.success,
      MEDIUM: 'rgba(232, 167, 107, 1)',
      HIGH: Colors.red.additional.main,
    },
  },
});

export const chainGradient = Object.freeze({
  SOLANA: Colors.solanaGradient.main,
  LAVA: Colors.lavaGradient.secondary,
  default: Colors.accent.primary.main,
  OSMOSIS: Colors.osmosisGradient.main,
});

export const mainEllipseBg = Object.freeze({
  KII: Colors.kiiGradient.main,
  OSMOSIS:
    'linear-gradient(141deg, rgba(48, 48, 131, 0.30) 7.45%, rgba(190, 73, 155, 0.30) 87.06%)',
  LAVA: 'linear-gradient(241.98deg,rgba(215, 0, 31, 0.3) -61.23%, rgba(255, 214, 0, 0.3) 137.19%)' as string,
  SOLANA:
    'linear-gradient(250deg, rgba(0, 255, 163, 0.30) -88.46%, rgba(220, 31, 255, 0.30) 99.25%)' as string,
  default:
    'linear-gradient(238deg, rgba(254, 202, 68, 0.2) -14.75%, rgba(141, 201, 42, 0.2) 11.26%, rgba(42, 201, 125, 0.2) 38.45%, rgba(9, 174, 197, 0.2) 67.98%, rgba(9, 120, 197, 0.2) 95.59%)' as string,
});

export const providerConnectionStatus = Object.freeze({
  connected: {
    background: '#2CB93A',
    shadow: '0px 0px 2px 0px #2CB93A, 0px 0px 4px 0px rgba(44, 185, 58, 0.70)',
  },
  disconnected: {
    background: '#454444',
    shadow: '0px 0px 2px 0px #454444, 0px 0px 4px 0px rgba(69, 68, 68, 0.70)',
  },
  'not-installed': {
    background: '#5C7181',
    shadow: '0px 0px 2px 0px #5C7181, 0px 0px 4px 0px rgba(92, 113, 129, 0.70)',
  },
});

export const solanaStakeStatus = {
  ACTIVE: {
    borderColor: '#449D4D',
    background: 'rgba(68, 157, 77, 0.30)',
  },
  ACTIVATING: {
    borderColor: '#CA9B22',
    background: 'rgba(202, 155, 34, 0.30)',
  },
  DEACTIVATING: {
    borderColor: '#1AA2C0',
    background: 'rgba(26, 162, 192, 0.30)',
  },
  INACTIVE: {
    borderColor: Colors.defaultWhite,
    background: 'rgba(255, 255, 255, 0.30)',
  },
};

const LIGHT_CONFIG = Object.freeze({
  text,
  input,
  badge,
  modal,
  chart,
  table,
  popup,
  select,
  button,
  toggle,
  drawer,
  divider,
  presets,
  sidebar,
  checkbox,
  tableRow,
  pieChart,
  scrollbar,
  boxShadow,
  datePicker,
  walletCard,
  numberCard,
  chainColor,
  alertMessage,
  chainGradient,
  mainEllipseBg,
  walletsSidebar,
  validatorStatus,
  delegateSection,
  emptyPlaceholder,
  chainTypeWithLine,
  transparentBorder,
  solanaStakeStatus,
  notificationCenter,
  delegationPieChart,
  compoundingInterest,
  transparentBackground,
  providerConnectionStatus,
  background: Colors.background,
});

export { LIGHT_CONFIG };
