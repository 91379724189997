import * as Sentry from '@sentry/react';
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { ChainId, CosmosWalletProvider } from 'libs/cosmos-core/types';
import { handleError, getErrorMessage } from 'libs/commons/utils/error';
import { getCosmosWalletAddress } from 'libs/cosmos-core/utils/helpers';

import { RootState } from 'store/store';
import { cosmosWalletsApi } from 'store/api/cosmos-wallets';
import { ENABLED_COSMOS_NETWORK_CHAIN_TYPES } from 'config/constants';

export interface ConnectedCosmosChainState {
  address: string | null;
}

export type ConnectedCosmosChainsState = {
  providerName: CosmosWalletProvider | null;
  chains: {
    [key in ChainId]: ConnectedCosmosChainState;
  };
};

export const connectedCosmosChainsInitialState: ConnectedCosmosChainsState = {
  providerName: null,
  chains: Object.values(ChainId).reduce<ConnectedCosmosChainsState['chains']>(
    (result, chainId) => {
      result[chainId] = {
        address: null,
      };

      return result;
    },
    {} as ConnectedCosmosChainsState['chains']
  ),
};

export const connectCosmosChains = createAsyncThunk(
  'connectedCosmosChains/connectCosmosChain',
  async (providerName: CosmosWalletProvider, { dispatch, rejectWithValue }) => {
    try {
      const chains: Partial<ConnectedCosmosChainsState['chains']> = {
        ...connectedCosmosChainsInitialState.chains,
      };
      const savedCosmosWalletsPromise = dispatch(
        cosmosWalletsApi.endpoints.getCosmosWallets.initiate()
      );
      const savedCosmosWallets = await savedCosmosWalletsPromise.unwrap();

      if (savedCosmosWallets) {
        for (const chain of ENABLED_COSMOS_NETWORK_CHAIN_TYPES) {
          try {
            const chainId = ChainId[chain];
            const { walletAddress } = await getCosmosWalletAddress({
              chainId,
              providerName,
            });

            if (!walletAddress) {
              Sentry.captureMessage(
                `Failed to fetch wallet address for chain: ${chain}`,
                {
                  level: 'warning',
                  extra: { chain, providerName },
                }
              );
              continue;
            }

            const savedWalletsIncludesAddress = savedCosmosWallets?.some(
              ({ wallet }) => wallet.address === walletAddress
            );

            if (!savedWalletsIncludesAddress) {
              const saveCosmosWalletPromise = dispatch(
                cosmosWalletsApi.endpoints.saveCosmosWallet.initiate(
                  walletAddress
                )
              );

              await saveCosmosWalletPromise;
            }

            chains[chainId] = {
              address: walletAddress,
            };
          } catch (error) {
            if (chain !== 'KII') {
              handleError(error);
            }

            continue;
          }
        }
      }

      return {
        chains,
        providerName,
      } as ConnectedCosmosChainsState;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

export const connectedCosmosChainsSlice = createSlice({
  name: 'connectedCosmosChains',
  initialState: connectedCosmosChainsInitialState,
  reducers: {
    disconnectAllCosmosChains: () => connectedCosmosChainsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      connectCosmosChains.rejected,
      () => connectedCosmosChainsInitialState
    );
    builder.addCase(
      connectCosmosChains.fulfilled,
      (_, action) => action.payload
    );
  },
});

export const { disconnectAllCosmosChains } = connectedCosmosChainsSlice.actions;

const selectConnectedCosmosChainsState = (state: RootState) =>
  state.connectedCosmosChains;

export const selectConnectedCosmosChains = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => ({
    ...state,
    chains: ENABLED_COSMOS_NETWORK_CHAIN_TYPES.reduce(
      (result, chainType) => {
        const chainId = ChainId[chainType];

        const chainState = state.chains[chainId];

        if (chainState) {
          result[chainId] = chainState;
        }

        return result;
      },
      {} as ConnectedCosmosChainsState['chains']
    ),
  })
);

export const selectConnectedCosmosProviderName = createSelector(
  [selectConnectedCosmosChainsState],
  (state) => state.providerName
);
