import { createApi } from '@reduxjs/toolkit/query/react';

import { optimizationApiConfig } from 'libs/cosmos-core/store/api';
import { OptimizationConfigResponse } from 'libs/cosmos-core/store/api/optimization-config';

export const compoundingConfigApi = createApi({
  baseQuery: optimizationApiConfig.baseQuery,
  reducerPath: optimizationApiConfig.reducerPath,
  endpoints: (build) => ({
    getCompoundingConfig: build.query<OptimizationConfigResponse, void>(
      optimizationApiConfig.endpoints.getCompoundingConfig
    ),
  }),
});

export const { useGetCompoundingConfigQuery } = compoundingConfigApi;
