import { DelegableChainType } from 'types';
import kiiBgStars from 'assets/deposit-confirmation/kii-bg-stars.svg';
import lavaBgStars from 'assets/deposit-confirmation/lava-bg-stars.svg';
import cosmosBgStars from 'assets/deposit-confirmation/cosmos-bg-stars.svg';
import solanaBgStars from 'assets/deposit-confirmation/solana-bg-stars.svg';
import osmosisBgStars from 'assets/deposit-confirmation/osmosis-bg-stars.svg';

export const iconBgImage: Record<DelegableChainType, string> = {
  KII: kiiBgStars,
  LAVA: lavaBgStars,
  SECRET: cosmosBgStars,
  COSMOS: cosmosBgStars,
  SOLANA: solanaBgStars,
  OSMOSIS: osmosisBgStars,
};
