import { ReactNode } from 'react';

import { isMobile } from 'libs/commons/hooks';
import { Flex, Semibold } from 'libs/commons/components';

export const TooltipRow = ({
  value,
  title,
}: {
  title: ReactNode;
  value?: ReactNode;
}) => {
  const mobile = isMobile();

  return (
    <Flex gap={mobile ? 4 : 16} justifyContent="space-between">
      <Flex gap={4} minWidth={0} alignItems="center">
        {title}
      </Flex>

      <Flex flexShrink={0}>
        {typeof value === 'string' ? <Semibold>{value}</Semibold> : value}
      </Flex>
    </Flex>
  );
};
