import styled, { css } from 'styled-components';

import { Table } from 'libs/commons/components';
import { SortableTable } from 'libs/commons/components/sortable-table';
import {
  ChildrenWrapper,
  ChildrenContainer,
} from 'libs/commons/components/table/styles';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const validatorsTableCss = css`
  flex: 1;
  min-width: 0;
  width: 100%;

  ${ChildrenWrapper} {
    overflow: initial;
  }

  ${ChildrenContainer} {
    min-width: initial;
  }
`;

export const BaseValidatorTable = styled(Table)`
  ${validatorsTableCss};
`;

export const BaseValidatorsSortableTable = styled(SortableTable)`
  ${validatorsTableCss};
`;
