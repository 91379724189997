import { ReactNode } from 'react';

import { Icon } from '../components';

import { CosmosChainType } from '.';

export const CosmosChainTypeIcon: Record<CosmosChainType, ReactNode> = {
  KII: <Icon.Kii />,
  LAVA: <Icon.Lava />,
  SECRET: <Icon.Secret />,
  COSMOS: <Icon.Cosmos />,
  OSMOSIS: <Icon.Osmosis width={24} height={24} />,
};
