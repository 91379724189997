import { Semibold } from 'libs/commons/components';
import { formatNumber, addPercentageLabel } from 'libs/commons/utils/helpers';
import { TableWithSortingProps } from 'libs/commons/components/sortable-table';
import { composeGridTemplateColumns } from 'libs/commons/components/table/helpers';

import { CosmosValidatorData } from 'store/api/cosmos-validators';
import { CosmosValidatorInfo, TotalTokensTableCell } from 'components';
import { VirtualizedListProps } from 'components/virtualized-list/types';

import { BaseTable } from './base';
import { CosmosValidatorsTableProps } from './types';

const validatorsListTemplate = composeGridTemplateColumns([
  { fr: 2 },
  { fr: 1, repeat: 3 },
  { fr: 2 },
  { fr: 1, repeat: 2 },
]);

const headers: TableWithSortingProps['headers'] = [
  {
    accessor: 'name',
    label: 'Validator',
  },
  {
    label: 'Validator APR',
    accessor: 'validatorAnnualPercentageRate',
  },
  {
    label: 'Network APR',
    accessor: 'networkAnnualPercentageRate',
  },
  {
    label: 'Validator Commission',
    accessor: 'validatorCommissionRate',
  },
  {
    accessor: 'totalStakedTokens',
    style: { marginLeft: 'auto' },
    label: 'Total Delegated Tokens',
  },
  {
    label: 'Voting Power',
    accessor: 'votingPower',
    info: 'Represents the influence a participant has in the network decision-making process, determined by the amount of cryptocurrency they have staked with validators',
  },
  {
    label: 'Scoring rate',
    accessor: 'scoringRate',
    info: 'Custom indicator that combines factors such as APR, commission rates, and overall voting power to assess their reliability and performance within the network',
  },
];

export const CosmosValidatorsTable = ({
  chainType,
  renderTableRow,
  additionalHeaders,
  additionalTemplate,
  showNotRecommendedValidatorInfo,
  ...props
}: CosmosValidatorsTableProps) => {
  const resultHeaders = [...headers, ...(additionalHeaders ?? [])];
  const resultTemplate = additionalTemplate
    ? `${validatorsListTemplate} ${additionalTemplate}`
    : validatorsListTemplate;

  const rowRenderer: VirtualizedListProps<CosmosValidatorData>['rowRenderer'] =
    (currentItemData) => {
      const baseRowProps = {
        template: resultTemplate,
        values: [
          <CosmosValidatorInfo
            address={currentItemData.address}
            showNotRecommended={showNotRecommendedValidatorInfo}
          />,
          <Semibold>
            {addPercentageLabel(currentItemData.validatorAnnualPercentageRate)}
          </Semibold>,
          addPercentageLabel(currentItemData.networkAnnualPercentageRate),
          addPercentageLabel(currentItemData.validatorCommissionRate),
          <TotalTokensTableCell
            chainType={chainType}
            data={currentItemData.totalStakedTokens}
          />,
          addPercentageLabel(currentItemData.votingPower),
          formatNumber(currentItemData.scoringRate),
        ],
      };

      return renderTableRow({ baseRowProps, data: currentItemData });
    };

  return (
    <BaseTable
      chainType={chainType}
      headers={resultHeaders}
      rowRenderer={rowRenderer}
      template={resultTemplate}
      {...props}
    />
  );
};
