const walletSignInBaseUrl = '/api-wallet-sign-in';

export const URL = {
  WALLETS: 'wallets',
  VALIDATORS: 'validators',
  COMPOUNDING_CONFIG: 'config',
  USER_GRANTS: '/users/{userUUID}/grants',
  DELEGATIONS: 'delegations/{walletAddress}',
  LAVA_REWARDS_CALCULATE: '/lava/rewards/calculate',
  LAVA_RESTAKE_PROVIDERS: '/lava/restake/providers',
  WALLET_BALANCE: 'balances/wallets/{walletAddress}',
  REFRESH_TOKEN: `${walletSignInBaseUrl}/auth/refresh`,
  VERIFY_CHALLENGE: `${walletSignInBaseUrl}/auth/challenges/verify`,
  GENERATE_SIGNATURE_MESSAGE: `${walletSignInBaseUrl}/auth/challenges/generate`,
};
