import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { LavaRewardsCalculatorState } from './types';

const initialState: LavaRewardsCalculatorState = {
  opened: false,
  tokenFieldError: undefined,
  amount: {
    usd: '',
    tokens: '',
  },
  recommendedListOpened: {
    providers: true,
    validators: true,
  },
};

export const lavaRewardsCalculatorSlice = createSlice({
  initialState,
  name: 'lavaRewardsCalculator',
  reducers: {
    closeFullList: (state) => {
      state.fullListType = null;
    },
    closeLavaRewardsCalculator: (state) => {
      state.opened = false;
    },
    openProvidersList: (state) => {
      state.fullListType = 'providers';
    },
    openValidatorList: (state) => {
      state.fullListType = 'validators';
    },
    setTokenFieldError: (state, { payload }) => {
      state.tokenFieldError = payload;
    },
    setLavaRewardsCalculatorUsdAmount: (state, { payload }) => {
      state.amount.usd = payload;
    },
    setLavaRewardsCalculatorTokensAmount: (state, { payload }) => {
      state.amount.tokens = payload;
    },
    setLavaRewardsCalculatorProviderAddress: (state, { payload }) => {
      state.providerAddress = payload;
    },
    setLavaRewardsCalculatorValidatorAddress: (state, { payload }) => {
      state.validatorAddress = payload;
    },
    toggleLavaRewardsCalculator: (state) => {
      if (state.opened) {
        return initialState;
      }

      state.opened = !state.opened;
    },
    switchLavaRewardsAmount: (state) => {
      const { usd, tokens } = state.amount;

      state.amount = {
        tokens: usd,
        usd: tokens,
      };
    },
    openRecommendedList: (
      state,
      { payload }: PayloadAction<'providers' | 'validators'>
    ) => {
      state.recommendedListOpened[payload] = true;
    },
    closeRecommendedList: (
      state,
      { payload }: PayloadAction<'providers' | 'validators'>
    ) => {
      state.recommendedListOpened[payload] = false;
    },
    toggleRecommendedList: (
      state,
      { payload }: PayloadAction<'providers' | 'validators'>
    ) => {
      state.recommendedListOpened[payload] =
        !state.recommendedListOpened[payload];
    },
  },
});

export const {
  actions: {
    closeFullList,
    openProvidersList,
    openValidatorList,
    setTokenFieldError,
    openRecommendedList,
    closeRecommendedList,
    toggleRecommendedList,
    switchLavaRewardsAmount,
    closeLavaRewardsCalculator,
    toggleLavaRewardsCalculator,
    setLavaRewardsCalculatorUsdAmount,
    setLavaRewardsCalculatorTokensAmount,
    setLavaRewardsCalculatorProviderAddress,
    setLavaRewardsCalculatorValidatorAddress,
  },
} = lavaRewardsCalculatorSlice;

export const selectLavaRewardsCalculator = (store: RootState) =>
  store.lavaRewardsCalculator;
