import { createApi } from '@reduxjs/toolkit/query/react';

import { Currency } from 'libs/commons/types';
import { exchangeRatesApiConfig } from 'libs/core/store/api';

import { ExchangeRateResponse, ExchangeRatesResponse } from './types';

export const exchangeRatesApi = createApi({
  baseQuery: exchangeRatesApiConfig.baseQuery,
  reducerPath: exchangeRatesApiConfig.reducerPath,
  endpoints: (build) => ({
    getExchangeRates: build.query<ExchangeRatesResponse, Currency>(
      exchangeRatesApiConfig.endpoints.getExchangeRates
    ),
  }),
});

export const { useGetExchangeRatesQuery } = exchangeRatesApi;

export type { ExchangeRateResponse, ExchangeRatesResponse };
