import { createApi } from '@reduxjs/toolkit/query/react';

import { AmountResponse } from 'libs/commons/types';

import { URL } from 'api/constants';
import { solanaApiQuery } from 'store/query';

export const solanaWalletBalanceTagTypes = ['solana-wallet-balance'];

export const solanaWalletBalanceApi = createApi({
  baseQuery: solanaApiQuery,
  reducerPath: 'solanaWalletBalanceApi',
  tagTypes: solanaWalletBalanceTagTypes,
  endpoints: (build) => ({
    getSolanaWalletBalance: build.query<AmountResponse, string>({
      providesTags: solanaWalletBalanceTagTypes,
      query: (walletAddress) => ({
        method: 'get',
        url: URL.WALLET_BALANCE.replace('{walletAddress}', walletAddress),
      }),
    }),
  }),
});

export const { useGetSolanaWalletBalanceQuery } = solanaWalletBalanceApi;
