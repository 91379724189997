import { URL } from '../../api/constants';
import { CosmosChainType } from '../../types';
import { compoundingApiQuery } from '../query';

export interface OptimizationConfigResponse {
  signerWalletAddresses: Record<CosmosChainType, string>;
  serviceFeeTargetWalletAddresses: Record<CosmosChainType, string>;
}

export const optimizationApiConfig = {
  baseQuery: compoundingApiQuery,
  reducerPath: 'optimizationConfigApi',
  endpoints: {
    getCompoundingConfig: {
      query: () => ({
        method: 'get',
        url: URL.COMPOUNDING_CONFIG,
      }),
    },
  },
};
