import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  matchRoutes,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
  Routes as ReactRouterRoutes,
} from 'react-router';

export let Routes = ReactRouterRoutes;

export const initSentry = () => {
  const sentryDsn = import.meta.env.VITE_REACT_APP_SENTRY_DSN;
  const sentryReleaseNumber = import.meta.env
    .VITE_REACT_APP_SENTRY_RELEASE_NUMBER;
  const sentryEnv = import.meta.env.VITE_REACT_APP_SENTRY_ENV;

  if (sentryDsn && sentryReleaseNumber && sentryEnv) {
    Sentry.init({
      dsn: sentryDsn,
      sendDefaultPii: true,
      tracesSampleRate: 1.0,
      environment: sentryEnv,
      profilesSampleRate: 1.0,
      release: sentryReleaseNumber,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          matchRoutes,
          useNavigationType,
          createRoutesFromChildren,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });

    Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes);
  }
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
