import { cosmosApiQuery } from '../../query';
import { URL } from '../../../api/constants';

export const cosmosValidatorsDelegationsTagTypes = [
  'cosmos-validators-delegations',
];

export const cosmosValidatorsDelegationsApiConfig = {
  baseQuery: cosmosApiQuery,
  reducerPath: 'cosmosValidatorsDelegationsApi',
  tagTypes: cosmosValidatorsDelegationsTagTypes,
  endpoints: {
    getCosmosValidatorsDelegations: {
      providesTags: cosmosValidatorsDelegationsTagTypes,
      query: (walletAddress: string) => ({
        method: 'get',
        url: URL.DELEGATIONS.replace('{walletAddress}', walletAddress),
      }),
    },
  },
};
