import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'libs/cosmos-core/store/query';
import { getSortParams, addParamsToUrl } from 'libs/commons/utils/query';

import { URL } from 'api/constants';

import {
  CosmosMultiDelegationsGetRequest,
  CosmosMultiDelegationsGetResponse,
} from './types';

export const cosmosMultiDelegationsTagTypes = ['cosmosMultiDelegations'];

export const cosmosMultiDelegationsApi = createApi({
  baseQuery: cosmosApiQuery,
  tagTypes: cosmosMultiDelegationsTagTypes,
  reducerPath: 'cosmosMultiDelegationsApi',
  endpoints: (build) => ({
    getCosmosMultiDelegations: build.query<
      CosmosMultiDelegationsGetResponse,
      CosmosMultiDelegationsGetRequest
    >({
      providesTags: cosmosMultiDelegationsTagTypes,
      query: ({ sort, walletAddress }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.COSMOS_MULTI_DELEGATIONS.replace(
            '{walletAddress}',
            walletAddress
          ),
          getSortParams(sort)
        ),
      }),
    }),
  }),
});

export const { useGetCosmosMultiDelegationsQuery } = cosmosMultiDelegationsApi;

export type { CosmosMultiDelegationsGetResponse };
