import { ethers } from 'ethers';

import { formatNumber } from 'libs/core/utils/helpers';
import { cosmosBaseToMainCurrency } from 'libs/core/utils/currencies';

import { CurrencyToChainType, ChainTypeToCurrencyLabel } from '../types/data';
import {
  ChainType,
  AmountResponse,
  CosmosChainTypeCurrency,
  CosmosChainTypeCurrencyOptions,
} from '../types';

import { lamportsToSol } from './solana';

export const addBtcLabel = (value: string | number) => `${value} BTC`;

export const addCurrencyLabel = (
  value: string | number,
  chainType?: ChainType
) => {
  return `${value} ${chainType ? ChainTypeToCurrencyLabel[chainType] : 'Unknown'}`;
};

export const gweiToEther = (gwei: string) => {
  if (gwei === '0') return 0;

  const gweiInWei = ethers.parseUnits(gwei, 9);

  return +ethers.formatEther(gweiInWei);
};

export const weiToEther = (wei: string) => {
  if (wei === '0') return 0;

  const eth = ethers.formatEther(wei);

  return +eth;
};

export const baseToMainCurrency = ({
  value,
  currency,
}: AmountResponse): number => {
  if (
    CosmosChainTypeCurrencyOptions.includes(currency as CosmosChainTypeCurrency)
  ) {
    return cosmosBaseToMainCurrency(value);
  }

  switch (currency) {
    case 'gwei':
      return gweiToEther(value);
    case 'wei':
      return weiToEther(value);
    case 'lamport':
      return lamportsToSol(value);
  }

  return +value;
};

export const convertAndLabelCurrency = ({
  value,
  currency,
}: AmountResponse) => {
  const valueInMainCurrency = baseToMainCurrency({ value, currency });
  return addCurrencyLabel(
    formatNumber(valueInMainCurrency),
    CurrencyToChainType[currency]
  );
};

export * from '../../core/utils/currencies';
