export const formatNumber = (
  num: string | number | undefined,
  options?: Intl.NumberFormatOptions
): string => {
  const numberValue = Number(num);

  if (isNaN(numberValue) || numberValue === 0) return '0.00';
  if (numberValue < 0.01) return '< 0.01';

  return numberValue.toLocaleString('en-US', {
    roundingMode: 'floor',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  } as Intl.NumberFormatOptions);
};

export const addPercentageLabel = (
  value: string | number,
  numberFormatOptions?: Intl.NumberFormatOptions,
  format: boolean = true
) => (format ? `${formatNumber(value, numberFormatOptions)}%` : `${value}%`);
