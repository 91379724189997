import { URL } from '../../api/constants';

export const tagTypes = ['cosmos-wallet-balance'];

export const endpoints = {
  getCosmosWalletBalance: {
    providesTags: tagTypes,
    query: (walletAddress: string) => ({
      method: 'get',
      url: URL.WALLET_BALANCE.replace('{walletAddress}', walletAddress),
    }),
  },
};
