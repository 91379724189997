import styled, { css } from 'styled-components';

import { Flex } from '../styles';
import { Input } from '../input';
import { Button } from '../button';
import { Icon } from '../../components';

export const Container = styled(Flex)<{ $interactionDisabled?: boolean }>`
  gap: 12px;

  ${({ $interactionDisabled }) =>
    $interactionDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const PaginationButton = styled(Button)`
  width: 32px;
  padding: 4px;
  height: 32px;
  font-weight: 400;
  border-radius: 8px;
`;

export const PrevArrow = styled(Icon.Chevron)`
  transform: rotate(180deg);
`;

export const GoToPageInput = styled(Input)`
  height: 100%;
  width: 64px;
`;

export const DotsPaginationItem = styled.li`
  padding: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
