import { useMemo } from 'react';

import { AmountResponse } from 'libs/commons/types';
import { mapQuery } from 'libs/commons/utils/query';
import { formatNumber } from 'libs/commons/utils/helpers';

import { useGetCurrencyDataQuery } from 'store/api/chains-data';

export const useCurrencyConverter = (data: AmountResponse | undefined) => {
  const { data: currencyData, ...query } = useGetCurrencyDataQuery(
    data?.currency!,
    {
      skip: !data?.currency,
    }
  );

  return useMemo(() => {
    const valueInBaseCurrency = parseFloat(data?.value ?? '0');
    const baseToMainCurrencyRate = parseFloat(
      currencyData?.baseToMainCurrencyRate ?? '0'
    );
    const mainCurrencyToUsdRate = parseFloat(
      currencyData?.usdExchangeRate ?? '0'
    );

    const convertedToMainCurrency =
      valueInBaseCurrency * baseToMainCurrencyRate;

    const valueInUSD = convertedToMainCurrency * mainCurrencyToUsdRate;

    return {
      ...mapQuery(query),
      data: {
        ...currencyData,
        valueInUSD,
        valueInMainCurrency: formatNumber(convertedToMainCurrency),
      },
    };
  }, [data?.value, currencyData, query]);
};
