import { determineCosmosChainFromAddress } from 'libs/cosmos-core/utils/helpers';

import { useGetCosmosValidatorData } from 'hooks';

import {
  BaseValidatorImage,
  BaseValidatorImageProps,
} from './base-validator-image';

export const CosmosValidatorImage = ({
  address,
  ...props
}: {
  address?: string;
} & BaseValidatorImageProps) => {
  const chainType = address
    ? determineCosmosChainFromAddress(address)
    : undefined;

  const getValidatorData = useGetCosmosValidatorData(chainType);

  const src = address && getValidatorData(address)?.imageUrl;

  return <BaseValidatorImage {...props} src={src} />;
};
