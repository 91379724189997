import styled from 'styled-components';

import { gradientBorder } from 'libs/commons/styles/mixins';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  margin-left: auto;
`;

export const ValueContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;

  z-index: 0;
  padding: 0 16px;
  gap: 8px;

  border-radius: 40px;
  background: ${({ theme }) => theme.config.tableRow.background.default.main};
  box-shadow:
    0px 0px 24px 0px rgba(226, 203, 46, 0.18),
    0px 2px 4px 0px rgba(4, 19, 1, 0.24);

  ${gradientBorder};

  &::before {
    padding: 2px;
  }
`;
