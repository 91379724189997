import { Flex, EllipsisText } from 'libs/commons/components';

import { Icon } from 'components';
import { ValidatorNotRecommendedPopup } from 'components/validator-not-recommended-popup';

import { ValidatorInfoProps } from './types';

export const ValidatorInfo = ({
  name,
  status,
  statusReason,
  icon = <Icon.Validator />,
  showNotRecommended = true,
  ...props
}: ValidatorInfoProps) => {
  return (
    <Flex gap={8} minWidth={0} alignItems="center" {...props}>
      {icon}

      {name && <EllipsisText>{name}</EllipsisText>}

      {showNotRecommended && (
        <ValidatorNotRecommendedPopup status={status} reason={statusReason} />
      )}
    </Flex>
  );
};
