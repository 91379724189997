import { styled } from 'styled-components';

import { gradientBorder } from 'libs/commons/styles/mixins';
import {
  Icon as CommonsIcon,
  Popup as PopupComponent,
} from 'libs/commons/components';

export const ChevronIcon = styled(CommonsIcon.Chevron)<{ $isOpen: boolean }>`
  transition: transform 0.3s;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const IconWrapper = styled.div`
  background: #fff;
  border-radius: 32px;
  border: 2px solid #111915;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
`;

export const Popup = styled(PopupComponent)`
  &-content {
    ${gradientBorder};

    background: ${({ theme }) => theme.colors.black};

    &::before {
      padding: 2px;
    }
  }
`;

export const PopupRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  overflow: auto;
  max-height: 300px;
`;
