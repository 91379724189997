/* eslint-disable perfectionist/sort-objects */

import {
  Icon as CoreIcon,
  AccentGradientIcon,
  ThemeDependentIcon,
} from 'libs/commons/components';

import { Icon } from 'components';
import { DelegableChainType } from 'types';

import { DelegationPreferenceConfig } from './types';

const baseDelegationPreferences = (
  chainType: DelegableChainType
): Record<string, DelegationPreferenceConfig> => ({
  validatorAnnualPercentageRate: {
    label: 'Maximum APR',
    selectedIcon: (
      <AccentGradientIcon chainType={chainType}>
        <CoreIcon.Interest />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon>
        <CoreIcon.Interest />
      </ThemeDependentIcon>
    ),
  },
  validatorCommissionRate: {
    label: 'Minimal commission',
    selectedIcon: (
      <AccentGradientIcon chainType={chainType} $iconColorParam="stroke">
        <Icon.MinimalCommission />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon $iconColorParam="stroke">
        <Icon.MinimalCommission />
      </ThemeDependentIcon>
    ),
  },
  totalStakedTokens: {
    label: 'Trusted validator',
    selectedIcon: (
      <AccentGradientIcon chainType={chainType} $iconColorParam="stroke">
        <Icon.TrustCommission />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon $iconColorParam="stroke">
        <Icon.TrustCommission />
      </ThemeDependentIcon>
    ),
  },
  votingPower: {
    label: 'Decentralized governance contribution',
    selectedIcon: (
      <AccentGradientIcon chainType={chainType}>
        <CoreIcon.Settings />
      </AccentGradientIcon>
    ),
    icon: (
      <ThemeDependentIcon>
        <CoreIcon.Settings />
      </ThemeDependentIcon>
    ),
  },
});

const solanaDelegationPreferences: Record<string, DelegationPreferenceConfig> =
  {
    annualPercentageYield: {
      label: 'Maximum APY',
      selectedIcon: (
        <AccentGradientIcon chainType="SOLANA">
          <CoreIcon.Interest />
        </AccentGradientIcon>
      ),
      icon: (
        <ThemeDependentIcon>
          <CoreIcon.Interest />
        </ThemeDependentIcon>
      ),
    },
    totalStakedTokens: baseDelegationPreferences('SOLANA').totalStakedTokens,
    validatorCommissionRate:
      baseDelegationPreferences('SOLANA').validatorCommissionRate,
  };

export const delegationPreferences: Record<
  DelegableChainType,
  ReturnType<typeof baseDelegationPreferences>
> = {
  COSMOS: baseDelegationPreferences('COSMOS'),
  SOLANA: solanaDelegationPreferences,
  LAVA: baseDelegationPreferences('LAVA'),
  SECRET: baseDelegationPreferences('SECRET'),
  OSMOSIS: baseDelegationPreferences('OSMOSIS'),
  KII: baseDelegationPreferences('KII'),
};
