import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Icon } from '../../icons';
import { BaseStyledTableRow } from '../styles';

export const TableRowContainer = styled(BaseStyledTableRow)`
  min-width: 0;
  padding: 12px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const ValuesContainer = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 8px;

  > * {
    flex-basis: 48%;
    min-width: 0;
  }
`;

export const ToggleCardIcon = styled(Icon.Plus)<{ $opened: boolean }>`
  ${({ $opened }) => css`
    path:nth-child(2) {
      opacity: 1;
      transition: opacity 300ms;
    }

    ${$opened &&
    css`
      > path:nth-child(2) {
        opacity: 0;
      }
    `}
  `}
`;
