import dayjs from 'dayjs';
import { DefaultTheme } from 'styled-components';

import { COMPOUND_ENABLED } from 'config/constants';

import { IncomeChartProps, IncomeChartDataItem } from './types';

const ensureNonNegative = (value: number) => Math.max(value, 0);

const getScaledAPRAndAPY = (apr: number = 0, apy: number = 0) => {
  const factor = 2;
  const difference = Math.abs(apr - apy);
  const scaledDifference = difference * factor;
  const midpoint = (apr + apy) / 2;

  const halfScaledDifference = scaledDifference / 2;

  if (apr > apy) {
    return {
      scaledAPY: ensureNonNegative(midpoint - halfScaledDifference),
      scaledAPR: ensureNonNegative(midpoint + halfScaledDifference),
    };
  } else {
    return {
      scaledAPY: ensureNonNegative(midpoint + halfScaledDifference),
      scaledAPR: ensureNonNegative(midpoint - halfScaledDifference),
    };
  }
};

export const formatData = (
  data: IncomeChartDataItem[] | undefined,
  hasStakingInfo: boolean,
  theme: DefaultTheme,
  selectedRange: IncomeChartProps['selectedRange']
) => {
  if (!data || !data.length || !hasStakingInfo) return [];

  const minimumDataCount = 10;
  const dataCount = data.length;

  const hasInsufficientData = dataCount <= minimumDataCount;

  const items = [];
  const placeholderItems = [];

  if (hasInsufficientData) {
    const firstDataTime = dayjs(data[0].time);
    for (let i = 1; i <= minimumDataCount - dataCount; i++) {
      const previousDay = firstDataTime.subtract(i, 'day');
      const previousDayTimeInMs = previousDay.valueOf();

      placeholderItems.push({
        time: previousDayTimeInMs,
      });
    }
  }

  if (selectedRange === 'LAST_THIRTY_DAYS') {
    for (let i = 1; i < data.length; i++) {
      const prevItem = data[i - 1];
      const currentItem = data[i];

      const prevItemReward = prevItem ? +prevItem.reward : 0;
      const currentItemReward = +currentItem.reward;
      const prevItemNewExecutionRewards = prevItem?.newExecutionRewards
        ? +prevItem.newExecutionRewards
        : 0;

      const barBottomValue = prevItemReward + prevItemNewExecutionRewards;
      const barTopValue =
        +currentItem.reward + +(currentItem.newExecutionRewards || 0);

      const rewardsSum = [String(barBottomValue), String(barTopValue)];

      let rewardBarValues;

      if (prevItemReward === currentItemReward) {
        const lastEntryWithDifferentReward = data.find(
          (item, index) =>
            item.reward !== currentItem.reward &&
            data[index + 1]?.reward === currentItem.reward
        );

        const bottomValue = lastEntryWithDifferentReward
          ? lastEntryWithDifferentReward.reward
          : 0;
        const topValue = currentItemReward;

        rewardBarValues = [bottomValue, topValue];
      } else {
        rewardBarValues = [prevItemReward, currentItemReward];
      }

      const formattedCurrentItemData = {
        ...currentItem,
        ...getScaledAPRAndAPY(currentItem.apr, currentItem.apy),
        rewardsSum,
        reward: currentItem.reward,
        rewardBarValue: rewardBarValues,
      };

      if (
        currentItem.compound &&
        +currentItem.compound > 0 &&
        COMPOUND_ENABLED
      ) {
        items.push({
          ...formattedCurrentItemData,
          fill: theme.config.chart.bar.compound,
        });
      } else if (currentItem.withdrawal && +currentItem.withdrawal > 0) {
        items.push({
          ...formattedCurrentItemData,
          fill: theme.colors.accentGradient.yellow,
        });
      } else {
        items.push({
          ...formattedCurrentItemData,
          fill: theme.config.chart.bar.default,
        });
      }
    }
  } else {
    const areaMultiplier = 0.7;

    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];

      const formattedCurrentItemData = {
        ...currentItem,
        ...getScaledAPRAndAPY(currentItem.apr, currentItem.apy),
        reward: +currentItem.reward,
        rewardAreaData: [
          +currentItem.reward * areaMultiplier,
          +currentItem.reward,
        ],
      };

      items.push(formattedCurrentItemData);
    }
  }

  return [...[...placeholderItems].reverse(), ...items];
};

export const getDefUrl = (defId: string) => `url(#${defId})`;
